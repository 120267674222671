import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { parse } from 'papaparse';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CustomTypography from '../../../../components/CustomTypography';
import ThemeButton from '../../../../components/ThemeButton';
import { get } from 'lodash';
import EditableTable from '../../../../components/DataGrid/EditableTable';
import ActionModal from '../../../../components/ActionModal';
import AddCalendarItem from './AddCalendarItem';
import { required, validateField } from '../../../../utils/Validator';
import {
  generateUniqueId,
  getDatesInRange,
  getWeeklyDetails,
  lowercaseKeys,
  parseSlashDate,
  validateInput,
} from '../../../../utils/helper';
import { getCalendars } from '../../../../redux/slice/model';
import CustomDialogBox from '../../../../components/CustomDialogBox';
import plus from '../../../../assets/images/common/plus.svg';
import UploadFiles from '../../../../components/UploadFiles';
import calenderTemplate from '../../../../assets/templates/calender-template.csv';
import refresh from '../../../../assets/images/common/refresh.svg';
import { useNotification } from '../../../../utils/NotificationProvider';

const Calendar = ({
  formData,
  loading,
  setFormData,
  modelId,
  completedSteps,
  modelData,
}) => {
  const [calendarData, setCalendarData] = useState([]);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const budgetType = get(modelData, 'budgetType');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isUploadModal, setUploadModal] = useState(false);
  const [newItem, setNewItem] = useState({});
  const [errors, setErrors] = useState({});
  const [prevData, setPrevData] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const budgetStartDate = get(modelData, 'budgetStartDate');
  const isStepCompleted = get(completedSteps, 'calendar', false);
  const showNotification = useNotification();
  const budgetEndDate = get(modelData, 'budgetEndDate');
  const startDay = get(modelData, 'weekStartDay');
  const weekDetails = getWeeklyDetails(
    new Date(budgetStartDate.slice(0, 10)),
    budgetEndDate,
    startDay
  );
  const dailyDetails = getDatesInRange(new Date(budgetStartDate.slice(0, 10)), budgetEndDate);
  const count = budgetType === 'weekly' ? weekDetails.length : dailyDetails.length;

  useEffect(() => {
    setCalendarData([]);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      calendar: {
        data: calendarData,
        prevData: prevData,
      },
    });
  }, [calendarData, prevData]);

  const validationRules = [
    required('Input is required'),
    validateInput('Input is required'),
  ];

  const duplicateItemRule = () => (value) => {
    const calendarItems = calendarData.map((item) => item.name);
    if (
      calendarItems.some((item) => value?.toLowerCase() === item?.toLowerCase())
    ) {
      return 'Item with same name already exists';
    }
  };

  const booleanType = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];

  const onActionHandle = (index, row, type) => {
    if (type === 'delete') {
      setRowId(row.id);
      setIsAlert(true);
    }
  };

  const columns = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'WEEK NUMBER',
      sortable: true,
      searchFilter: true,
      valueType: 'array',
      filterType: 'checkbox',
      type: 'inputOnClick',
      width: '130px',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: '',
      sortable: true,
      searchFilter: true,
      valueType: 'array',
      filterType: 'checkbox',
      width: '10%',
    },
    {
      id: 'button',
      numeric: true,
      disablePadding: false,
      label: '',
      sortable: false,
      type: 'buttons',
      moreOptions: {
        buttonClick: onActionHandle,
        buttons: [{ title: 'Delete' }],
      },
      width: '5%',
    },
  ];

  const addBudgetColumns = () => {
      let extraColumns = [];
      if(budgetType === 'weekly') {
        weekDetails.forEach((item, i)=>{
          extraColumns.push({
            id: `wd-${i+1}`,
            label: `${i+1}`,
            numeric: true,
            disablePadding: false,
            sortable: true,
            width: '94px',
            type: 'inputOnClick',
            validInputType: 'number',
            end: moment(parseSlashDate(item.endDate)).format('D MMM, YYYY'),
            start: moment(parseSlashDate(item.startDate)).format('D-'),
            options: booleanType
          });
        })
      } else{
        dailyDetails.forEach((item, i)=>{
          extraColumns.push({
            id: `wd-${i+1}`,
            label: `${i+1}`,
            numeric: true,
            disablePadding: false,
            sortable: true,
            width: '94px',
            type: 'inputOnClick',
            validInputType: 'number',
            end: item,
            options: booleanType
          });
        })
      }
      setUpdatedColumns([...columns, ...extraColumns]);
    };

  useEffect(() => {
    if(calendarData?.length > 0){
      addBudgetColumns();
    }
  }, [calendarData]);

  const fetchCalendarData = () => {
    setIsLoading(true);
    dispatch(
      getCalendars({
        url: `/calendars/getBymodelId/${modelId}`,
        method: 'GET',
        navigate,
        success: (res) => {
          if (get(res, 'data.rows') && get(res, 'data.rows').length > 0) {
            const calendarRows = get(res, 'data.rows', []);
            const rows = calendarRows.map((item) => {
              const { _id, name, type, values } = item;
              let transformmedData = {
                id: _id,
                name,
                type,
              };
              const inputType = type === 'boolean' ? 'dropdown' : 'input';
              const cellType =
                type === 'number' || type === 'percent' ? 'number' : 'text';

              values.forEach((value, index) => {
                transformmedData[`wd-${index + 1}`] =
                  type === 'boolean'
                    ? booleanType.find((item) => item.value === value)
                    : value;

                transformmedData.inputType = inputType;
                if (type !== 'boolean') {
                  transformmedData.cellType = cellType;
                }
              });
              return transformmedData;
            });
            setCalendarData([...rows]);
            setPrevData(JSON.parse(JSON.stringify(rows)));
          }
          setIsLoading(false);
        },
        fail: (err) => {
          console.log('calendar error', err);
          setIsLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    if (isStepCompleted) {
      fetchCalendarData();
    }
  }, [isStepCompleted]);

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setUploadModal(false);
    setNewItem({});
    setErrors({});
  };

  const handleSaveItem = () => {
    const addItemRules = {
      name: [...validationRules, duplicateItemRule()],
      value: validationRules,
      type: validationRules,
    };
    if (!validateField(newItem, addItemRules, setErrors)) return;
    const budgetFields = {};
    for (let i = 1; i <= count; i++) {
      if (newItem.type === 'boolean') {
        budgetFields[`wd-${i}`] = booleanType.find(
          (item) => item.value === newItem.value
        );
      } else {
        budgetFields[`wd-${i}`] = newItem.value;
      }
    }
    const newRow = {
      id: generateUniqueId(),
      name: newItem.name,
      type: newItem.type,
      inputType: newItem.type === 'boolean' ? 'dropdown' : 'input',
      cellType:
        newItem.type === 'number' || newItem.type === 'percent'
          ? 'number'
          : 'text',
      ...budgetFields,
    };

    setCalendarData((prev) => [...prev, newRow]);
    handleCloseModal();
  };

  const handleUpdateDetails = (updatedDetails) => {
    const rows = updatedDetails
      ? JSON.parse(JSON.stringify(updatedDetails))
      : [];
    setCalendarData([...rows]);
  };

  const handleCloseAlert = () => {
    setIsAlert(false);
  };

  const handleDelete = () => {
    setCalendarData((prevRows) => prevRows.filter((row) => row.id !== rowId));
    handleCloseAlert();
  };

  const getUploadedFile = (file) => {
    setUploadedFile(file);
  };

  const handleImportData = () => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const fileContent = e.target.result;
      const fileType = uploadedFile.name.split('.').pop().toLowerCase();

      let parsedData;
      let headers;

      if (fileType === 'csv') {
        const csvData = parse(fileContent, { header: true });
        parsedData = csvData.data;
        headers = csvData.meta.fields;
      } else {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });

        headers = worksheet[0];
        parsedData = worksheet.slice(1).map((row) =>
          row.reduce((acc, value, index) => {
            acc[headers[index]] = value;
            return acc;
          }, {})
        );
      }
      if (headers.length > 0 && parsedData.length > 0) {
        if (
          headers[0]?.toLowerCase() !== 'name' ||
          headers[1]?.toLowerCase() !== 'type'
        ) {
          showNotification(
            'error',
            'Please ensure the imported file matches the template format.'
          );
          return;
        }
        parsedData = parsedData.map((item) => lowercaseKeys(item));

        const rows = parsedData
          .map(({ name, type, ...item }) => {
            if (name || type) {
              const transformedItem = Object.keys(item).reduce((acc, key) => {
                if (!isNaN(key)) {
                  acc[`wd-${key}`] =
                    item[key].toLowerCase() === 'yes'
                      ? { label: 'Yes', value: 'true' }
                      : item[key].toLowerCase() === 'no'
                        ? { label: 'No', value: 'false' }
                        : item[key];
                } else {
                  acc[key] =
                    item[key].toLowerCase() === 'yes'
                      ? { label: 'Yes', value: 'true' }
                      : item[key].toLowerCase() === 'no'
                        ? { label: 'No', value: 'false' }
                        : item[key];
                }
                return acc;
              }, {});
              return {
                id: generateUniqueId(),
                name,
                type:
                  type?.toLowerCase() === 'yes/no'
                    ? 'boolean'
                    : type?.toLowerCase(),
                inputType:
                  type?.toLowerCase() === 'yes/no' ? 'dropdown' : 'input',
                cellType:
                  type?.toLowerCase() === 'number' ||
                  type?.toLowerCase() === 'percent'
                    ? 'number'
                    : 'text',
                ...transformedItem,
              };
            }
          })
          .filter((item) => item !== undefined);
        setCalendarData((prev) => [...prev, ...rows]);
      }
    };
    const fileType = uploadedFile.name.split('.').pop().toLowerCase();
    if (fileType === 'csv') {
      reader.readAsText(uploadedFile);
    } else {
      reader.readAsArrayBuffer(uploadedFile);
    }
    handleCloseModal();
  };

  const addItemActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseModal },
    { title: 'Save', variant: 'contained', onClick: handleSaveItem },
  ];

  const deleteActions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseAlert },
    {
      title: 'Delete',
      variant: 'contained',
      onClick: handleDelete,
      color: 'danger',
    },
  ];

  const actionButtons = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseModal },
    {
      title: 'Import Data',
      variant: 'contained',
      onClick: handleImportData,
      isDisabled: uploadedFile ? false : true,
    },
  ];

  return (
    <div style={{ height: '100%' }}>
      <div className="form-title flex-container">
        <CustomTypography variant="headerh3" text="Calendar" />
        <div className="headerButtons">
          <ThemeButton
            text="Add item"
            variant="outlined"
            onClick={handleOpenModal}
            startIcon={<img src={plus} alt="plus" />}
          />
          <ThemeButton
            text="Import Calendar"
            variant="outlined"
            onClick={() => setUploadModal(true)}
            startIcon={<img src={refresh} alt="refresh" />}
          />
        </div>
      </div>
      <div className="driver-table">
        <EditableTable
          columns={updatedColumns}
          rows={calendarData}
          data={calendarData}
          tableOf={['calendar']}
          showStatusTabs={false}
          defaultOrderBy="calories"
          defaultOrder="asc"
          updateFilteredRows={(updatedDetails) =>
            handleUpdateDetails(updatedDetails.data)
          }
          showTableHeader={false}
          loading={loading || isLoading}
          tableHeight={'calc(100% - 20px)'}
          showDateHeader
          budgetType={budgetType}
        />
      </div>
      <ActionModal
        open={isUploadModal}
        close={handleCloseModal}
        className="upload-model-wrapper"
        disableEscapeKeyDown
        component={
          <UploadFiles
            getUploadedFile={getUploadedFile}
            templateSrc={calenderTemplate}
            additionalNote={[
              '1. Consider adding further columns to sheet',
              '2. Type can be "Number", "Percent", "Yes/No", "Free"',
            ]}
          />
        }
        actionButtons={actionButtons}
        title="Upload Files"
      />
      <ActionModal
        open={isOpenModal}
        close={handleCloseModal}
        actionButtons={addItemActionBtns}
        component={
          <AddCalendarItem
            newItem={newItem}
            setNewItem={setNewItem}
            errors={errors}
            booleanType={booleanType}
          />
        }
        title="Calendar data"
        className="upload-model-wrapper"
        width="25%"
      />
      <CustomDialogBox
        open={isAlert}
        onClose={handleCloseAlert}
        title="Delete Calendar data"
        description="Are you sure you want to delete this calendar data?"
        actions={deleteActions}
      />
    </div>
  );
};

export default Calendar;
