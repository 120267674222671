import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import SelectDropdown from '../../../../components/SelectDropdown';
import { conditionalOperators, operators } from './constant';
import VariableDropdown from './VariableDropdown';
import TextInput from '../../../../components/TextInput';
import ChildFormulaField from './ChildFormulaField';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { get, set } from 'lodash';

const FormulaFields = ({ data, index, rowindex, modelData }) => {
  const [group, setGroup] = data;
  const formula = get(group, `[${index}].rows[${rowindex}].formula`, {});

  const getVariable = (variableIndex) => {
    return get(formula, `variable[${variableIndex}]`, null);
  };

  const handleOperatorChange = (e, index, rowindex) => {
    const groupData = group;
    const newFormula =
      e.target.value === 'DIRECT_VALUE'
        ? { type: e.target.value, openMenu: null, source: {} }
        : e.target.value === 'IF_BOOLEAN'
          ? {
              type: e.target.value,
              variable: [
                { openMenu: null },
                { openMenu: null },
                { openMenu: null },
              ],
            }
          : e.target.value === 'IF_COMPARE'
            ? {
                type: e.target.value,
                variable: [
                  { openMenu: null },
                  {
                    openMenu: null,
                    type: 'DIRECT_VALUE',
                    source: {
                      type: 'OPERATOR',
                      path: ['OPERATOR', 'GREATER_THAN'],
                    },
                  },
                  { openMenu: null },
                  { openMenu: null },
                  { openMenu: null },
                ],
              }
            : {
                type: e.target.value,
                variable: [{ openMenu: null }, { openMenu: null }],
              };

    set(groupData, `[${index}].rows[${rowindex}].formula`, newFormula);
    setGroup([...groupData]);
  };

  const handleConditionalOperatorChange = (
    e,
    type,
    parentVariableIndex,
    childVariableIndex
  ) => {
    let groupData = group;
    let variable =
      type === 'parent'
        ? getVariable(1)
        : type === 'child'
          ? getVariable(parentVariableIndex).variable[1]
          : getVariable(parentVariableIndex).variable[childVariableIndex]
              .variable[1];
    variable = {
      ...variable,
      source: {
        ...variable.source,
        path: ['OPERATOR', e.target.value],
      },
    };
    set(
      groupData,
      type === 'parent'
        ? `[${index}].rows[${rowindex}].formula.variable[1]`
        : type === 'child'
          ? `[${index}].rows[${rowindex}].formula.variable[${parentVariableIndex}].variable[1]`
          : `[${index}].rows[${rowindex}].formula.variable[${parentVariableIndex}].variable[${childVariableIndex}].variable[1]`,
      variable
    );
    setGroup([...groupData]);
  };

  const handleOpenMenu = (
    e,
    variableIndex,
    type,
    childVariableIndex,
    subChildVariableIndex
  ) => {
    const groupData = group;

    if (type === 'parent') {
      const key =
        formula.type === 'DIRECT_VALUE'
          ? 'openMenu'
          : `variable[${variableIndex}].openMenu`;
      set(
        groupData,
        `[${index}].rows[${rowindex}].formula.${key}`,
        e.currentTarget
      );
    } else if (type === 'child') {
      set(
        groupData,
        `[${index}].rows[${rowindex}].formula.variable[${variableIndex}].variable[${childVariableIndex}].openMenu`,
        e.currentTarget
      );
    } else if (type === 'sub-child') {
      set(
        groupData,
        `[${index}].rows[${rowindex}].formula.variable[${variableIndex}].variable[${childVariableIndex}].variable[${subChildVariableIndex}].openMenu`,
        e.currentTarget
      );
    }

    setGroup([...groupData]);
  };

  const handleCloseMenu = (
    variableIndex,
    type,
    childVariableIndex,
    subChildVariableIndex
  ) => {
    const groupData = group;

    if (type === 'parent') {
      const key =
        formula.type === 'DIRECT_VALUE'
          ? 'openMenu'
          : `variable[${variableIndex}].openMenu`;
      set(groupData, `[${index}].rows[${rowindex}].formula.${key}`, null);
    } else if (type === 'child') {
      set(
        groupData,
        `[${index}].rows[${rowindex}].formula.variable[${variableIndex}].variable[${childVariableIndex}].openMenu`,
        null
      );
    } else if (type === 'sub-child') {
      set(
        groupData,
        `[${index}].rows[${rowindex}].formula.variable[${variableIndex}].variable[${childVariableIndex}].variable[${subChildVariableIndex}].openMenu`,
        null
      );
    }

    setGroup([...groupData]);
  };

  const handleFreeInputChange = (
    val,
    type,
    variableIndex,
    childVariableIndex,
    subChildVariableIndex
  ) => {
    const groupData = group;

    if (type === 'parent') {
      if (
        get(group, `[${index}].rows[${rowindex}].formula.source.type`) ===
        'FREE'
      ) {
        set(groupData, `[${index}].rows[${rowindex}].formula.source.path`, [
          'FREE',
          val,
        ]);
      } else {
        set(
          groupData,
          `[${index}].rows[${rowindex}].formula.variable[${variableIndex}].source.path`,
          ['FREE', val]
        );
      }
    } else if (type === 'child') {
      set(
        groupData,
        `[${index}].rows[${rowindex}].formula.variable[${variableIndex}].variable[${childVariableIndex}].source.path`,
        ['FREE', val]
      );
    } else if (type === 'sub-child') {
      set(
        groupData,
        `[${index}].rows[${rowindex}].formula.variable[${variableIndex}].variable[${childVariableIndex}].variable[${subChildVariableIndex}].source.path`,
        ['FREE', val]
      );
    }

    setGroup([...groupData]);
  };

  const handleBack = (type, variableIndex, parentVariableIndex, childVariableIndex) => {
    const groupData = group;
    if (type === 'parent') {
      if (
        get(group, `[${index}].rows[${rowindex}].formula.source.type`) ===
        'FREE'
      ) {
        set(groupData, `[${index}].rows[${rowindex}].formula`, {openMenu: false});
      } else {
        set(
          groupData,
          `[${index}].rows[${rowindex}].formula.variable[${variableIndex}]`,
          {openMenu: false}
        );
      }
    }
    else if (type === 'child') {
      set(groupData, `[${index}].rows[${rowindex}].formula.variable[${parentVariableIndex}].variable[${variableIndex}]`, {openMenu: false});
    }
    else if (type === 'sub-child') {
      set(groupData, `[${index}].rows[${rowindex}].formula.variable[${parentVariableIndex}].variable[${childVariableIndex}].variable[${variableIndex}]`, {openMenu: false});
    }
    setGroup([...groupData])
  }

  return (
    <div
      className={`formula-fields-wrapper ${group?.[index]?.rows?.[rowindex]?.isSubChild ? 'sub-child' : ''}`}
    >
      {formula?.type && (
        <>
          {(formula?.type === 'IF_BOOLEAN' ||
            formula?.type === 'IF_COMPARE') && (
            <Box className="bg-green">IF</Box>
          )}
          {(formula?.type === 'DIRECT_VALUE' &&
            formula?.source?.type === 'FREE') ||
          (getVariable(0)?.type === 'DIRECT_VALUE' &&
            getVariable(0)?.source?.type === 'FREE') ? (
            <TextInput
              endAdornment={<ArrowBackRoundedIcon/>}
              onIconClick={()=> handleBack('parent', 0)}
              name="variable-field"
              value={
                formula?.source?.path?.[1] || getVariable(0)?.source?.path?.[1]
              }
              className="parent"
              onInputChange={(e) =>
                handleFreeInputChange(e.target.value, 'parent', 0)
              }
              type="number"
              sizeSmall
            />
          ) : [
              'ADD',
              'SUBTRACT',
              'MULTIPLY',
              'DIVIDE',
              'IF_BOOLEAN',
              'IF_COMPARE',
            ].includes(getVariable(0)?.type) ? (
            <Box className="variable-field parent">
              <ChildFormulaField
                handleBack={handleBack}
                data={[group, setGroup]}
                index={index}
                rowindex={rowindex}
                modelData={modelData}
                variableIndex={0}
                varIndex="one"
                handleConditionalOperatorChange={handleConditionalOperatorChange}
                handleOperatorChange={handleOperatorChange}
                handleFreeInputChange={handleFreeInputChange}
                handleOpenMenu={handleOpenMenu}
                handleCloseMenu={handleCloseMenu}
              />
            </Box>
          ) : (
            <>
              <Box
                className="variable-field parent"
                id="field_variable_one"
                aria-controls="menu_variable_one"
                onClick={(e) => handleOpenMenu(e, 0, 'parent')}
              >
                {formula?.type === 'DIRECT_VALUE' &&
                formula?.source?.path?.length > 1
                  ? formula?.source?.path?.[formula?.source?.path?.length - 1]
                      ?.label ||
                    formula?.source?.path?.[formula?.source?.path?.length - 1]
                  : getVariable(0)?.type === 'DIRECT_VALUE' &&
                      getVariable(0)?.source?.path?.length > 1
                    ? getVariable(0)?.source?.path?.[
                        getVariable(0)?.source?.path?.length - 1
                      ]?.label ||
                      getVariable(0)?.source?.path?.[
                        getVariable(0)?.source?.path?.length - 1
                      ]
                    : ''}
              </Box>
              <VariableDropdown
                handleCloseMenu={handleCloseMenu}
                type="parent"
                parentVariableIndex={0}
                varIndex="one"
                className="menu-dropdown"
                data={data}
                index={index}
                rowindex={rowindex}
                anchorEl={
                  formula?.type === 'DIRECT_VALUE'
                    ? formula?.openMenu
                    : getVariable(0)?.openMenu
                }
                open={Boolean(
                  formula?.type === 'DIRECT_VALUE'
                    ? formula?.openMenu
                    : getVariable(0)?.openMenu
                )}
                modelData={modelData}
              />
            </>
          )}
        </>
      )}
      {formula?.type !== 'DIRECT_VALUE' &&
      formula?.type !== 'IF_BOOLEAN' &&
      formula?.type !== 'IF_COMPARE' ? (
        <SelectDropdown
          classNameForFormControl={`custom-min-width ${formula?.type && formula?.type !== 'DIRECT_VALUE' ? 'shrink' : ''}`}
          items={operators}
          handleChange={(e) => handleOperatorChange(e, index, rowindex)}
          iconPosition="end"
          className="icon-dropdown"
          name="operator"
          value={formula?.type}
          disabled={formula?.type}
        />
      ) : (
        ''
      )}
      {formula?.type === 'IF_COMPARE' ? (
        <SelectDropdown
          classNameForFormControl="shrink"
          items={conditionalOperators}
          handleChange={(e) => handleConditionalOperatorChange(e, 'parent')}
          iconPosition="end"
          className="icon-dropdown"
          name="operator"
          value={getVariable(1)?.source?.path?.[1]}
        />
      ) : (
        ''
      )}
      {formula?.type === 'IF_COMPARE' && (
        <>
          {getVariable(2)?.type === 'DIRECT_VALUE' &&
          getVariable(2)?.source?.type === 'FREE' ? (
            <TextInput
              endAdornment={<ArrowBackRoundedIcon/>}
              onIconClick={()=> handleBack('parent', 2)}
              name="variable-field"
              value={getVariable(2)?.source?.path?.[1]}
              onInputChange={(e) =>
                handleFreeInputChange(e.target.value, 'parent', 2)
              }
              type="number"
              sizeSmall
            />
          ) : [
              'ADD',
              'SUBTRACT',
              'MULTIPLY',
              'DIVIDE',
              'IF_BOOLEAN',
              'IF_COMPARE',
            ].includes(getVariable(2)?.type) ? (
            <Box className="variable-field parent">
              <ChildFormulaField
                handleBack={handleBack}
                data={[group, setGroup]}
                index={index}
                rowindex={rowindex}
                modelData={modelData}
                variableIndex={2}
                handleOperatorChange={handleOperatorChange}
                handleConditionalOperatorChange={handleConditionalOperatorChange}
                handleFreeInputChange={handleFreeInputChange}
                handleOpenMenu={handleOpenMenu}
                handleCloseMenu={handleCloseMenu}
              />
            </Box>
          ) : (
            <>
              <Box
                className="variable-field parent"
                id={`field_variable_three`}
                aria-controls="menu_variable_three"
                onClick={(e) => handleOpenMenu(e, 2, 'parent')}
              >
                {formula?.type === 'DIRECT_VALUE' &&
                formula?.source?.path?.length > 1
                  ? formula?.source?.path?.[formula?.source?.path?.length - 1]
                      ?.label
                  : getVariable(2)?.type === 'DIRECT_VALUE' &&
                      getVariable(2)?.source?.path?.length > 1
                    ? getVariable(2)?.source?.path?.[
                        getVariable(2)?.source?.path?.length - 1
                      ]?.label ||
                      getVariable(2)?.source?.path?.[
                        getVariable(2)?.source?.path?.length - 1
                      ]
                    : ''}
              </Box>
              <VariableDropdown
                handleCloseMenu={handleCloseMenu}
                type="parent"
                parentVariableIndex={2}
                varIndex="three"
                className="menu-dropdown"
                data={data}
                index={index}
                rowindex={rowindex}
                anchorEl={getVariable(2)?.openMenu}
                open={Boolean(getVariable(2)?.openMenu)}
                modelData={modelData}
              />
            </>
          )}
        </>
      )}
      {(formula?.type === 'IF_BOOLEAN' || formula?.type === 'IF_COMPARE') && (
        <Box className="bg-green">then</Box>
      )}
      {formula?.type && formula?.type !== 'DIRECT_VALUE' && (
        <>
          {getVariable(formula?.type === 'IF_COMPARE' ? 3 : 1)?.type ===
            'DIRECT_VALUE' &&
          getVariable(formula?.type === 'IF_COMPARE' ? 3 : 1)?.source?.type ===
            'FREE' ? (
            <TextInput
              endAdornment={<ArrowBackRoundedIcon/>}
              onIconClick={()=> handleBack('parent', formula?.type === 'IF_COMPARE' ? 3 : 1)}
              name="variable-field"
              value={
                getVariable(formula?.type === 'IF_COMPARE' ? 3 : 1)?.source
                  ?.path?.[1]
              }
              onInputChange={(e) =>
                handleFreeInputChange(
                  e.target.value,
                  'parent',
                  formula?.type === 'IF_COMPARE' ? 3 : 1
                )
              }
              type="number"
              sizeSmall
            />
          ) : [
              'ADD',
              'SUBTRACT',
              'MULTIPLY',
              'DIVIDE',
              'IF_BOOLEAN',
              'IF_COMPARE',
            ].includes(
              getVariable(formula?.type === 'IF_COMPARE' ? 3 : 1)?.type
            ) ? (
            <Box className="variable-field parent">
              <ChildFormulaField
                handleBack={handleBack}
                data={[group, setGroup]}
                index={index}
                rowindex={rowindex}
                modelData={modelData}
                variableIndex={formula?.type === 'IF_COMPARE' ? 3 : 1}
                handleOperatorChange={handleOperatorChange}
                handleConditionalOperatorChange={handleConditionalOperatorChange}
                handleFreeInputChange={handleFreeInputChange}
                handleOpenMenu={handleOpenMenu}
                handleCloseMenu={handleCloseMenu}
              />
            </Box>
          ) : (
            <>
              <Box
                className="variable-field parent"
                id={formula?.type === 'IF_COMPARE' ? 'field_variable_four' : 'field_variable_two'}
                aria-controls={formula?.type === 'IF_COMPARE' ? 'menu_variable_four' : 'menu_variable_two'}
                onClick={(e) =>
                  handleOpenMenu(
                    e,
                    formula?.type === 'IF_COMPARE' ? 3 : 1,
                    'parent'
                  )
                }
              >
                {formula?.type === 'DIRECT_VALUE' &&
                formula?.source?.path?.length > 1
                  ? formula?.source?.path?.[formula?.source?.path?.length - 1]
                      ?.label
                  : getVariable(formula?.type === 'IF_COMPARE' ? 3 : 1)
                        ?.type === 'DIRECT_VALUE' &&
                      getVariable(formula?.type === 'IF_COMPARE' ? 3 : 1)
                        ?.source?.path?.length > 1
                    ? getVariable(formula?.type === 'IF_COMPARE' ? 3 : 1)
                        ?.source?.path?.[
                        getVariable(formula?.type === 'IF_COMPARE' ? 3 : 1)
                          ?.source?.path?.length - 1
                      ]?.label ||
                      getVariable(formula?.type === 'IF_COMPARE' ? 3 : 1)
                        ?.source?.path?.[
                        getVariable(formula?.type === 'IF_COMPARE' ? 3 : 1)
                          ?.source?.path?.length - 1
                      ]
                    : ''}
              </Box>
              <VariableDropdown
                handleCloseMenu={handleCloseMenu}
                type="parent"
                parentVariableIndex={formula?.type === 'IF_COMPARE' ? 3 : 1}
                varIndex={formula?.type === 'IF_COMPARE' ? 'four' : 'two'}
                className="menu-dropdown"
                data={data}
                index={index}
                rowindex={rowindex}
                anchorEl={
                  getVariable(formula?.type === 'IF_COMPARE' ? 3 : 1)?.openMenu
                }
                open={Boolean(
                  getVariable(formula?.type === 'IF_COMPARE' ? 3 : 1)?.openMenu
                )}
                modelData={modelData}
              />
            </>
          )}
        </>
      )}
      {(formula?.type === 'IF_BOOLEAN' || formula?.type === 'IF_COMPARE') && (
        <Box className="bg-green">else</Box>
      )}
      {(formula?.type === 'IF_BOOLEAN' || formula?.type === 'IF_COMPARE') && (
        <>
          {getVariable(formula?.type === 'IF_COMPARE' ? 4 : 2)?.type ===
            'DIRECT_VALUE' &&
          getVariable(formula?.type === 'IF_COMPARE' ? 4 : 2)?.source?.type ===
            'FREE' ? (
            <TextInput
              endAdornment={<ArrowBackRoundedIcon/>}
              onIconClick={()=> handleBack('parent', formula?.type === 'IF_COMPARE' ? 4 : 2)}
              name="variable-field"
              value={
                getVariable(formula?.type === 'IF_COMPARE' ? 4 : 2)?.source
                  ?.path?.[1]
              }
              onInputChange={(e) =>
                handleFreeInputChange(e.target.value, 'parent', formula?.type === 'IF_COMPARE' ? 4 : 2)
              }
              type="number"
              sizeSmall
            />
          ) : [
              'ADD',
              'SUBTRACT',
              'MULTIPLY',
              'DIVIDE',
              'IF_BOOLEAN',
              'IF_COMPARE',
            ].includes(
              getVariable(formula?.type === 'IF_COMPARE' ? 4 : 2)?.type
            ) ? (
            <Box className="variable-field parent">
              <ChildFormulaField
                handleBack={handleBack}
                data={[group, setGroup]}
                index={index}
                rowindex={rowindex}
                modelData={modelData}
                variableIndex={formula?.type === 'IF_COMPARE' ? 4 : 2}
                handleOperatorChange={handleOperatorChange}
                handleConditionalOperatorChange={handleConditionalOperatorChange}
                handleFreeInputChange={handleFreeInputChange}
                handleOpenMenu={handleOpenMenu}
                handleCloseMenu={handleCloseMenu}
              />
            </Box>
          ) : (
            <>
              <Box
                className="variable-field parent"
                id={formula?.type === 'IF_COMPARE' ? 'field_variable_five' : 'field_variable_three'}
                aria-controls={formula?.type === 'IF_COMPARE' ? 'menu_variable_five' : 'menu_variable_three'}
                onClick={(e) =>
                  handleOpenMenu(
                    e,
                    formula?.type === 'IF_COMPARE' ? 4 : 2,
                    'parent'
                  )
                }
              >
                {formula?.type === 'DIRECT_VALUE' &&
                formula?.source?.path?.length > 1
                  ? formula?.source?.path?.[formula?.source?.path?.length - 1]
                      ?.label
                  : getVariable(formula?.type === 'IF_COMPARE' ? 4 : 2)
                        ?.type === 'DIRECT_VALUE' &&
                      getVariable(formula?.type === 'IF_COMPARE' ? 4 : 2)
                        ?.source?.path?.length > 1
                    ? getVariable(formula?.type === 'IF_COMPARE' ? 4 : 2)
                        ?.source?.path?.[
                        getVariable(formula?.type === 'IF_COMPARE' ? 4 : 2)
                          ?.source?.path?.length - 1
                      ]?.label ||
                      getVariable(formula?.type === 'IF_COMPARE' ? 4 : 2)
                        ?.source?.path?.[
                        getVariable(formula?.type === 'IF_COMPARE' ? 4 : 2)
                          ?.source?.path?.length - 1
                      ]
                    : ''}
              </Box>
              <VariableDropdown
                handleCloseMenu={handleCloseMenu}
                type="parent"
                parentVariableIndex={formula?.type === 'IF_COMPARE' ? 4 : 2}
                varIndex={formula?.type === 'IF_COMPARE' ? 'five' : 'three'}
                className="menu-dropdown"
                data={data}
                index={index}
                rowindex={rowindex}
                anchorEl={
                  getVariable(formula?.type === 'IF_COMPARE' ? 4 : 2)?.openMenu
                }
                open={Boolean(
                  getVariable(formula?.type === 'IF_COMPARE' ? 4 : 2)?.openMenu
                )}
                modelData={modelData}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FormulaFields;

FormulaFields.propTypes = {
  index: PropTypes.number,
  rowindex: PropTypes.number,
  data: PropTypes.array,
  modelData: PropTypes.object,
};
