import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
// import MuiChipCell from "./MuiChipCell";
// import ButtonCell from "./ButtonCell";
// import TableHeader from "./TableHeader";
import { IconButton, Menu, MenuItem, TableFooter } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SelectDropdown from '../SelectDropdown';
import TextInput from '../TextInput';
import typography from '../../theme/typography';
import './styles.scss';
import TableHeader from './TableHeader';
import EnhancedToolbar from './EnhancedToolbar';
import CustomDatePicker from '../CustomDatePicker';
import ButtonCell from './ButtonCell';
import dayjs from 'dayjs';
import CreatableAutocomplete from '../CreatableAutocomplete';
import { useNotification } from '../../utils/NotificationProvider';
import TableSkeleton from './TableSkeleton';
import { get } from 'lodash';
import { capitalizeFirstLetter, formatDate } from '../../utils/helper';
import CustomDialogBox from '../CustomDialogBox';

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
};
// const getColumnBackgroundColor = (index) => {
//   if (index === 0 || index === 1) {
//     return "#e9edf5";
//   } else if (index < 13) {
//     return "#d2e3e4";
//   } else {
//     return "#e0eee7";
//   }
// };
const EditableTable = ({
  rows,
  columns,
  defaultOrderBy,
  defaultOrder,
  selectable,
  pagination,
  headerButtons,
  tableOf,
  updateFilteredRows,
  data,
  onDelete,
  isSearchRequired,
  titleOFthePage,
  showTableHeader,
  cellStyle,
  dropdownItems,
  selectedDropDownValue,
  onChange,
  getColumnBackgroundColor,
  footerRow,
  editingRow,
  showStatusTabs,
  getAllColumns,
  updateColumnLabel,
  searchFilterOpt,
  setResetAllFilters,
  resetAllFilters,
  filterFE,
  setIsLevelModal,
  loading,
  tableHeight,
  handleDeleteCol,
  handleRenameColClick,
  handleRenameColumn,
  showDateHeader,
  budgetStartDate,
  budgetEndDate,
  budgetType
}) => {
  const [order, setOrder] = React.useState(defaultOrder || 'asc');
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [editingCell, setEditingCell] = React.useState({
    rowId: null,
    columnId: null,
  });
  const [errorCell, setErrorCell] = React.useState(null);
  const [columnLabel, setColumnLabel] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openConfirmModel, setOpenConfirmModel] = React.useState(null);
  const [columnId, setColumnId] = React.useState(null);
  const showNotification = useNotification();
  //   const [uploadFiles, setUploadFiles] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n[columns[0].id]);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleOnUnselect = () => {
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //   const handleCloseUploadModal = () => {
  //     setUploadFiles(false);
  //   };

  // const validateSections = (value, column) => {
  //   if (
  //     (column.validInputType === "text" && !/^[a-zA-Z]*$/.test(value)) ||
  //     (column.validInputType === "number" && !/^[0-9]*$/.test(value))
  //   ) {
  //     return true;
  //   }
  // };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(() => {
    const sortedRows = stableSort(rows, getComparator(order, orderBy));
    return sortedRows;
  }, [order, orderBy, page, rowsPerPage, rows]);

  const handleInputChange = (value, columnId, currentRow) => {
    const rowIndex = data.findIndex((row) => row.id === currentRow.id);
    const newRows = [...data];
    if (columnId === 'payRiseWeek') {
      if (value === '' || (Number(value) <= 53 && Number(value) >= 0)) {
        newRows[rowIndex][columnId] = value;
      } else {
        showNotification(
          'error',
          'The value for pay rise week should be in between 1 to 53.'
        );
      }
    } else if (columnId === 'payRise' || columnId === 'payPrePayRise') {
      newRows[rowIndex][columnId] = value;
      let postPayRise = '';
      const rowPrePayRise = Number(newRows[rowIndex].payPrePayRise);
      postPayRise =
        rowPrePayRise +
        (rowPrePayRise * Number(newRows[rowIndex].payRise)) / 100;
      newRows[rowIndex].payPostPayRise = postPayRise;
    } else if (
      columnId === 'basicMinutes' ||
      columnId === 'contingencyAllowance' ||
      columnId === 'relaxationAllowance'
    ) {
      newRows[rowIndex][columnId] = value;
      if (
        newRows[rowIndex].basicMinutes &&
        newRows[rowIndex].contingencyAllowance &&
        newRows[rowIndex].relaxationAllowance
      ) {
        newRows[rowIndex].standardMinuteValue = Number(
          (
            newRows[rowIndex].basicMinutes *
            (newRows[rowIndex].relaxationAllowance / 100) *
            (newRows[rowIndex].contingencyAllowance / 100)
          ).toFixed(4)
        );
      } else {
        newRows[rowIndex].standardMinuteValue = 0;
      }
    } else if(columnId === "startDate") {
      if(new Date(value) >= new Date(budgetStartDate)){
        newRows[rowIndex][columnId] = value;
      } else {
        showNotification('error', 'Please select Start date between Budget Start and End Date')
        newRows[rowIndex][columnId] = null;
      }
    } else if(columnId === "endDate") {
      if(new Date(value) <= new Date(budgetEndDate)){
        newRows[rowIndex][columnId] = value;
      } else {
        showNotification('error', 'Please select End date between Budget Start and End Date')
        newRows[rowIndex][columnId] = null;
      }
    } else {
      newRows[rowIndex][columnId] = value;
    }
    if (
      (tableOf[0] === 'stores' || tableOf[0] === 'drivers') &&
      newRows[rowIndex].type !== 'NEW_STORE'
    ) {
      newRows[rowIndex].type = 'UPDATE_STORE';
    }
    updateFilteredRows({ data: newRows });
  };

  const shouldDisableDate = (date, columnId, currentRow) => {
    const { startDate, endDate } = currentRow;
    if (columnId === 'startDate') {
      return endDate ? date > new Date(endDate) : false;
    }
    if (columnId === 'endDate') {
      return startDate ? date < new Date(startDate) : false;
    }
    return false;
  };

  const handleSelectChange = (value, columnId, currentRow, options) => {
    const rowIndex = rows.findIndex((row) => row === currentRow);
    const newRows = [...rows];
    const selectedValue = options?.filter((opt) => opt.value === value);
    newRows[rowIndex][columnId] = selectedValue[0];
    updateFilteredRows({ data: newRows });
  };

  const handleAutocompleteChange = (event, currentRow, column, newValue) => {
    const { id, options, toUpdateOptions } = column;
    let selectedValue;
    if (newValue && newValue.inputValue) {
      selectedValue = { label: newValue.inputValue, value: options.length + 1 };
      toUpdateOptions([...options, selectedValue]);
    } else {
      selectedValue = newValue;
    }
    const rowIndex = rows.findIndex((row) => row === currentRow);
    const newRows = [...rows];
    newRows[rowIndex][id] = selectedValue;
    updateFilteredRows({ data: newRows });
  };

  const handleColumnInputChange = (value) => {
    setColumnLabel(value);
  };

  const checkColumnLabelMatch = () => {
    const labelLowerCase = columnLabel.toLowerCase();
    return columns.some((column) => labelLowerCase === column.id.toLowerCase());
  };

  const addNewColumn = () => {
    const newColumnName = columnLabel;
    if (columnLabel && columnLabel?.trim()) {
      if (checkColumnLabelMatch()) {
        showNotification('error', 'Duplicate column name.');
      } else {
        const newColumn = {
          id:
            tableOf[0] === 'characteristics'
              ? `col${columns.length - 1}`
              : newColumnName?.toLowerCase()?.replace(' ', ''),
          numeric: false,
          disablePadding: false,
          label: newColumnName?.toUpperCase()?.trim(),
          searchFilter: true,
          sortable: true,
          filterType: 'checkbox',
          type: 'inputOnClick',
          isLevel: true,
          width: '60px',
          showMenuIcon: tableOf[0] === 'characteristics',
        };
        getAllColumns(newColumn);
        setIsLevelModal(true);
      }
    } else {
      showNotification('error', 'Please enter the column name to add.');
    }
    setColumnLabel('');
  };
  //   const handleAutocompleteChange = (value, currentRow, column, newValue) => {
  //     const { id, options, toUpdateOptions } = column;
  //     let selectedValue;
  //     if (newValue && newValue.inputValue) {
  //       selectedValue = { label: newValue.inputValue, value: options.length + 1 };
  //     } else {
  //       selectedValue = newValue;
  //     }
  //     const rowIndex = rows.findIndex((row) => row === currentRow);
  //     const newRows = [...rows];
  //     newRows[rowIndex][id] = selectedValue;
  //     if (newValue && newValue.inputValue) {
  //       toUpdateOptions([
  //         ...options,
  //         { label: newValue.inputValue, value: options.length + 1 },
  //       ]);
  //     }
  //     updateFilteredRows(newRows);
  //   };

  const handleBlur = (value, columnId, rowId) => {
    if (tableOf[0] === 'calendar' && (!value || value?.trim() === '')) {
      setErrorCell({ rowId, columnId });
    } else {
      setErrorCell(null);
      setEditingCell({ rowId: null, columnId: null });
    }
  };

  const handleCellClick = (rowId, columnId) => {
    if (errorCell) {
      showNotification(
        'error',
        'Fill the current cell before editing another cell'
      );
      return;
    }
    setEditingCell({ rowId, columnId });
  };

  const editCell = (column, row) => {
    // if (row.id === editingRow) {
    switch (column.type) {
      case 'dropdown':
        return (
          <SelectDropdown
            value={
              column?.options?.filter(
                (opt) =>
                  opt.value === row[column.id].value &&
                  opt.label === row[column.id].label
              )[0]?.value || {}
            }
            items={column.options || []}
            handleChange={(e) =>
              handleSelectChange(e.target.value, column.id, row, column.options)
            }
            minWidth="100%"
            specialValue={column.specialValue || ''}
          />
        );
      case 'creatableAutocomplete':
        return (
          <CreatableAutocomplete
            options={column.options}
            setOptions={column.toUpdateOptions}
            placeholder="Type to search or add"
            value={column?.options?.find(
              (opt) =>
                opt?.value === row[column.id]?.value &&
                opt?.label === row[column.id]?.label
            )}
            onChange={(e, newValue) => {
              handleAutocompleteChange(e, row, column, newValue);
            }}
          />
        );
      case 'inputOnClick':
        {
          const isEditing =
            editingCell.rowId === row.id && editingCell.columnId === column.id;
          const hasError =
            tableOf[0] === 'calendar'
              ? errorCell?.rowId === row.id && errorCell?.columnId === column.id
              : false;
          if (isEditing) {
            if (row.inputType === 'input' || column.id === 'name') {
              return (
                <TextInput
                  type={
                    column.validInputType ??
                    (column.id === 'name' ? 'text' : row.cellType ?? 'text')
                  }
                  value={row[column.id]}
                  onInputChange={(e) =>
                    handleInputChange(e.target.value, column.id, row)
                  }
                  onBlur={(e) => handleBlur(e.target.value, column.id, row.id)}
                  autoFocus
                  error={hasError}
                  startAdornment={column?.startAdornment}
                  endAdornment={column?.endAdornment}
                  disabled={column.id === 'payPostPayRise'}
                />
              );
            }
            if (row.inputType === 'dropdown') {
              return (
                <SelectDropdown
                  value={
                    column?.options?.filter(
                      (opt) =>
                        opt.value === row[column.id].value &&
                        opt.label === row[column.id].label
                    )[0]?.value || {}
                  }
                  items={column.options || []}
                  handleChange={(e) =>
                    handleSelectChange(
                      e.target.value,
                      column.id,
                      row,
                      column.options
                    )
                  }
                  onBlur={(e) => handleBlur(e.target.value, column.id, row.id)}
                  onClose={(e) => handleBlur(e.target.value, column.id, row.id)}
                  minWidth="100%"
                  specialValue={column.specialValue || ''}
                  defaultOpen
                />
              );
            }
          } else {
            return (
              <div
                onClick={() =>
                  column.id === 'newCharacteristics' ||
                  column.id === 'standardMinuteValue'
                    ? null
                    : handleCellClick(row.id, column.id)
                }
                style={{
                  cursor: `${column.id === 'newCharacteristics' || column.id === 'standardMinuteValue' ? 'not-allowed' : 'pointer'}`,
                  border: '1px solid',
                  borderRadius: '6px',
                  borderColor: '#868fa029',
                  boxShadow: '0px 1px 2px #0000000f',
                  padding: '6px 12px',
                  textAlign: 'left',
                  minHeight: '1.2rem',
                  color: `${column.id === 'newCharacteristics' || column.id === 'standardMinuteValue' ? '#ced1d6' : '#111827'}`,
                }}
              >
                {row.inputType === 'dropdown'
                  ? column.id === 'name'
                    ? row[column.id]
                    : row[column.id]?.label
                  : row[column.id]}
              </div>
            );
          }
        }
        break;
      case 'datePickerOnClick': {
        const isEditing =
          editingCell.rowId === row.id && editingCell.columnId === column.id;
        if (isEditing) {
          return (
            <CustomDatePicker
              value={
                row[column.id] ? dayjs(row[column.id], 'DD/MM/YYYY') : null
              }
              className="bg-white"
              onChange={(date) => handleInputChange(date, column.id, row)}
              shouldDisableDate={(date) =>
                shouldDisableDate(date, column.id, row)
              }
            />
          );
        } else {
          return (
            <div
              onClick={() => handleCellClick(row.id, column.id)}
              style={{
                cursor: 'pointer',
                border: '1px solid',
                borderRadius: '6px',
                borderColor: '#868fa029',
                boxShadow: '0px 1px 2px #0000000f',
                padding: '6px 12px',
                textAlign: 'left',
                minHeight: '1.2rem',
              }}
            >
              {row[column.id]
                ? formatDate(row[column.id], 'DD/MM/YYYY')
                : row[column.id]}
            </div>
          );
        }
      }
      case 'input':
        if (column.id === 'newCharacteristics') {
          return (
            <div>
              <TextInput
                type={column.validInputType || 'text'}
                value=""
                placeholder="e.g.1"
                disabled
              />
            </div>
          );
        } else {
          return (
            <div>
              <TextInput
                type={column.validInputType || 'text'}
                value={row[column.id]}
                onInputChange={(e) =>
                  handleInputChange(e.target.value, column.id, row)
                }
                disabled={column.id === 'payPostPayRise'}
                startAdornment={column?.startAdornment}
                endAdornment={column?.endAdornment}
              />
            </div>
          );
        }
      // return (
      //   <TextInput
      //     className="bg-white"
      //     type="text"
      //     value={row[column.id]}
      //     width={column.width}
      //     onInputChange={(e) =>
      //       handleInputChange(e.target.value, column.id, row)
      //     }
      //   />
      // );
      case 'date':
        return (
          <CustomDatePicker
            value={row[column.id] ? dayjs(row[column.id], 'DD/MM/YYYY') : null}
            className="bg-white"
            onChange={(date) => handleInputChange(date, column.id, row)}
            shouldDisableDate={(date) =>
              shouldDisableDate(date, column.id, row)
            }
          />
        );
      case 'buttons':
        return (
          <ButtonCell
            buttons={column.moreOptions.buttons}
            info={row}
            onClick={(index, type) => {
              column.moreOptions.buttonClick(index, row, type);
            }}
          />
        );
      case 'customButton':
        return column.component ? (
          <column.component
            onClick={(type) => column.buttonOnClick(type, row)}
            row={row}
            editingRowId={editingRow}
          />
        ) : null;
      default:
        return capitalizeFirstLetter(
          row[column.id] === 'boolean' ? 'Yes/No' : row[column.id]
        );
    }
    // } else {
    //   switch (column.type) {
    //     // case "chip":
    //     //   return <MuiChipCell initialChips={row[column.id]} />;
    //     // case "buttons":
    //     //   return (
    //     //     <ButtonCell
    //     //       buttons={column.moreOptions.buttons}
    //     //       info={row}
    //     //       onClick={(index, type) =>
    //     //         column.moreOptions.buttonClick(index, row, type)
    //     //       }
    //     //     />
    //     //   );
    //     case "customButton":
    //       return column.component ? (
    //         <column.component
    //           onClick={(type) => column.buttonOnClick(type, row)}
    //           row={row}
    //           editingRowId={editingRow}
    //         />
    //       ) : null;
    //     case "logo":
    //       return <Avatar alt="Remy Sharp" src={row[column.id]} />;
    //     // case "bool":
    //     //   return <img src={row[column.id] && checkCircle} alt="" />;
    //     case "dropdown":
    //       return row[column.id].label;
    //     case "autocomplete":
    //       return row[column.id]?.label;
    //     // case "date":
    //     //   return dayjs(row[column.id]).format("DD MMMM YYYY");
    //     default:
    //       return row[column.id];
    //   }
    // }
  };

  const handleMenuOpen = (event, colId) => {
    setAnchorEl(event.currentTarget);
    setColumnId(colId);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleCloseModel = () => {
    setOpenConfirmModel(false);
  };
  const deleteActions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseModel },
    {
      title: 'Delete',
      variant: 'contained',
      color: 'danger',
      onClick: () => {
        handleDeleteCol(columnId);
        handleCloseModel();
      },
    },
  ];

  return (
    <div
      id="model-table"
      className={`editable-table-wrapper table-wrapper page-table ${selected && selected.length > 0 ? 'toolbar-active' : ''}`}
      style={{ height: tableHeight }}
    >
      <TableHeader
        headerButtons={headerButtons}
        columns={columns}
        rows={rows}
        updateFilteredRows={updateFilteredRows}
        data={data}
        isSearchRequired={isSearchRequired}
        titleOFthePage={titleOFthePage}
        showTableHeader={showTableHeader}
        dropdownItems={dropdownItems}
        selectedDropDownValue={selectedDropDownValue}
        onChange={onChange}
        showStatusTabs={showStatusTabs}
        searchFilterOpt={searchFilterOpt}
        resetAllFilters={resetAllFilters}
        setResetAllFilters={setResetAllFilters}
        filterFE={filterFE}
        tableOf={tableOf}
      />

      {selected && selected.length > 0 && (
        <EnhancedToolbar
          selectedItems={selected}
          tableOf={tableOf}
          onUnSelect={handleOnUnselect}
          onDelete={onDelete}
        />
      )}
      {/* Table Container */}
      <div className="table-wrapper" style={{ height: '100%' }}>
        {/* <div style={{height: '100%'}}> */}
        {loading ? (
          <TableSkeleton
            rows={rows.length || 6}
            columns={columns.length || 5}
          />
        ) : (
          <TableContainer sx={{ maxWidth: '94vw', scrollbarWidth: 'thin' }}>
            {/* Table */}
            <Table
              sx={{ minWidth: 750, overflow: 'auto' }}
              aria-labelledby="tableTitle"
              size="medium"
              stickyHeader
            >
              {/* Table Head */}
              <TableHead>
                {showDateHeader && visibleRows.length > 0 && (
                  <TableRow>
                    {columns.map((column) => {
                      return (
                        <TableCell
                          key={column.id}
                          align="left"
                          padding={column.disablePadding ? 'none' : 'normal'}
                        >
                          <Typography
                            style={{
                              ...typography.overline,
                              width: column.width,
                            }}
                          >
                            {budgetType === "weekly" ? get(column, 'start') : ''}
                            {get(column, 'end')}
                          </Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )}
                <TableRow>
                  {selectable && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selected.length > 0 && selected.length < rows.length
                        }
                        checked={
                          rows.length > 0 && selected.length === rows.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          'aria-label': 'select all items',
                        }}
                      />
                    </TableCell>
                  )}
                  {columns.map((column) => {
                    return (
                      <TableCell
                        key={column.id}
                        align="left"
                        padding={column.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === column.id ? order : false}
                      >
                        {column.sortable ? (
                          <span>
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : 'asc'}
                              onClick={() => handleRequestSort(null, column.id)}
                            >
                              <Typography
                                style={{
                                  ...typography.overline,
                                  width: column.width,
                                }}
                              >
                                {column.label}
                              </Typography>
                              {orderBy === column.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {order === 'desc'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                            {column.showMenuIcon && (
                              <IconButton
                                size="small"
                                className="more-icon"
                                onClick={(event) =>
                                  handleMenuOpen(event, column.id)
                                }
                              >
                                <MoreVertIcon fontSize="small" />
                              </IconButton>
                            )}
                          </span>
                        ) : column.headerType === 'input' ? (
                          column.id === 'newCharacteristics' ? (
                            <TextInput
                              className="bg-white"
                              type="text"
                              value={columnLabel || ''}
                              // width={column.width}
                              placeholder={column.placeholder}
                              onInputChange={(e) =>
                                handleColumnInputChange(e.target.value)
                              }
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') addNewColumn();
                              }}
                            />
                          ) : (
                            <TextInput
                              type="text"
                              value={column.label || ''}
                              className="bg-white"
                              onInputChange={(e) => {
                                const newColumns = columns.map((col) =>
                                  col.id === column.id
                                    ? { ...col, label: e.target.value }
                                    : col
                                );
                                updateColumnLabel(newColumns);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') handleRenameColumn();
                              }}
                              onBlur={handleRenameColumn}
                            />
                          )
                        ) : (
                          <Typography style={typography.overline}>
                            {column.label}
                          </Typography>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              {/* Table Body */}
              <TableBody>
                {visibleRows && visibleRows.length > 0 ? (
                  visibleRows.map((row, index) => {
                    const isItemSelected = isSelected(row[columns[0]?.id]);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >
                        {selectable && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              onClick={(event) =>
                                handleClick(event, row[columns[0].id])
                              }
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                        )}
                        {columns.map((column, index) => (
                          <TableCell
                            key={column.id}
                            sx={{ width: column.width }}
                            align={column.numeric ? 'right' : 'left'}
                            className="datepicker-div"
                            style={{
                              backgroundColor: cellStyle
                                ? getColumnBackgroundColor(index)
                                : '',
                              padding: cellStyle ? '8px' : '',
                              borderBottom: cellStyle ? '1px solid white' : '',
                              minWidth: column.width,
                            }}
                          >
                            {editCell(column, row)}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={columns.length + (selectable ? 1 : 0)}>
                      <Typography variant="subtitle2">
                        No results found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell
                      colSpan={columns.length + (selectable ? 1 : 0)}
                    />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {footerRow &&
                    columns.map((column) => (
                      <TableCell key={column.id}>
                        {footerRow[column.id]}
                      </TableCell>
                    ))}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </div>

      {/* Table Pagination */}
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {/* {uploadFiles && (
        <UploadFiles open={open} close={handleCloseUploadModal} />
      )} */}
      <Menu
        className="column-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleRenameColClick(columnId);
            handleMenuClose();
          }}
        >
          Rename
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenConfirmModel(true);
            handleMenuClose();
          }}
          sx={{ color: 'red' }}
        >
          Delete
        </MenuItem>
      </Menu>
      <CustomDialogBox
        open={openConfirmModel}
        onClose={handleCloseModel}
        title={`Delete Column`}
        description={`Are you sure you want to delete this column?`}
        actions={deleteActions}
      />
    </div>
  );
};

EditableTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultOrderBy: PropTypes.string,
  headerButtons: PropTypes.array,
  defaultOrder: PropTypes.oneOf(['asc', 'desc']),
  selectable: PropTypes.bool,
  pagination: PropTypes.bool,
  tableOf: PropTypes.array,
  data: PropTypes.array,
  updateFilteredRows: PropTypes.func,
  onDelete: PropTypes.func,
  isSearchRequired: PropTypes.bool,
  titleOFthePage: PropTypes.string,
  showTableHeader: PropTypes.bool,
  showDateHeader: PropTypes.bool,
  cellStyle: PropTypes.bool,
  dropdownItems: PropTypes.array,
  selectedDropDownValue: PropTypes.string,
  onChange: PropTypes.func,
  getColumnBackgroundColor: PropTypes.func,
  setIsLevelModal: PropTypes.func,
  footerRow: PropTypes.object,
  editingRow: PropTypes.object,
  handleDeleteCol: PropTypes.func,
  handleRenameColClick: PropTypes.func,
  handleRenameColumn: PropTypes.func,
  budgetStartDate: PropTypes.any,
  budgetEndDate: PropTypes.any,
  budgetType: PropTypes.string,
};
EditableTable.defaultProps = {
  isSearchRequired: true,
  showTableHeader: true,
  showStatusTabs: true,
  filterFE: false,
  setIsLevelModal: () => {},
  rows: [],
};
export default EditableTable;
