import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import whiteAdd from "../../assets/images/common/whiteAdd.svg"
import DataTable from '../../components/DataGrid/DataTable';
import CustomDialogBox from '../../components/CustomDialogBox';
import { useDispatch } from 'react-redux'; 
import { useNotification } from '../../utils/NotificationProvider';
import NoDataFoundMessage from '../../components/NoDataFoundMessage';
import CustomTypography from '../../components/CustomTypography';
import ThemeButton from '../../components/ThemeButton';
import theme from '../../theme';
import { changeModelStatus, fetchModelList, filterModels } from '../../redux/slice/model';
import { capitalize } from 'lodash';
import {  budgetTypes, modelWeeks, weekStartDays } from './addEditModel/GlobalSettings/constant';

const Model = () => {
  const [filteredRows, setFilteredRows] = useState([]);
  const [isAlert, setAlert] = useState(false);
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [statusAction, setStatusAction] = useState("")
  const [isDataFilter, setIsDataFilter] = useState(false);
  const [modelList, setModelList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalModels, setTotalModels] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showNotification = useNotification();
  const [initialFilters, setInitialFilters] = useState({
    modelFilter: [],
    statusFilter: ['active', 'archive', 'draft'],
    budgetTypeFilter: ["weekly", "daily"],
    modelWeeksFilter: [52, 53],
    weekStartDayFilter: ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"],
    searchText: "",
    page: 1,
    limit: 10
  })

  const updateFilteredRows = (data) => {
    setIsDataFilter(true)
    const { searchQuery, page, limit, statusFilter, Model, StartDay, Weeks, BudgetType } = data;
    const modelFilter = Model ? Object.keys(Model).filter(key => Model[key]) : [];
    const budgetTypeFilter = BudgetType ? Object.keys(BudgetType).filter(key => BudgetType[key]) : [];
    const modelWeeksFilter = Weeks ? Object.keys(Weeks).filter(key => Weeks[key])?.map((item) => Number(item)) : [];
    const weekStartDayFilter = StartDay ? Object.keys(StartDay).filter(key => StartDay[key]) : [];
    const requestedPayload = {
        modelFilter,
        statusFilter: statusFilter ? statusFilter : initialFilters.statusFilter,
        budgetTypeFilter: budgetTypeFilter ? budgetTypeFilter : initialFilters.budgetTypeFilter,
        modelWeeksFilter: modelWeeksFilter ? modelWeeksFilter : initialFilters.modelWeeksFilter,
        weekStartDayFilter: weekStartDayFilter ? weekStartDayFilter : initialFilters.weekStartDayFilter,
        searchText: searchQuery || '',
        page: page || initialFilters.page,
        limit: limit || initialFilters.limit,
    };
    setInitialFilters(prevFilters => ({
        ...prevFilters,
        ...requestedPayload,
    }));
  };

  const searchFilterOpt = [
    {name: 'Model', options: modelList, type: 'checkbox'},
    {name: 'StartDay', options: weekStartDays, type: 'checkbox'},
    {name: 'Weeks', options: modelWeeks, type: 'checkbox'},
    {name: 'BudgetType', options: budgetTypes, type: 'checkbox'},
  ]

  const getModelList = () => {
    dispatch(fetchModelList({
      url: '/models',
      method: 'GET',
      navigate,
      success: (res) => {
        if (res && res.data && res.data.length > 0) {
          const formattedData = res.data.map(item => ({
            label: item.name,
            value: item._id
          }));
          setModelList(formattedData)
        } else {
          setModelList([])
        }
      },
      fail: (err) => {
        console.log("err", err);
        showNotification('error', err.message);
      }
    }))
  }

  const getModels = () => {
    setLoading(true)
    dispatch(filterModels({
      url: '/models/filter',
      method: 'POST',
      navigate,
      data: {
        modelFilter: initialFilters.modelFilter,
        searchFilter: {
          searchField: [
            "name",
        ],
        searchText: initialFilters.searchText
        },
        page: initialFilters.page,
        limit: initialFilters.limit,
        statusFilter: initialFilters.statusFilter,
        budgetTypeFilter: initialFilters.budgetTypeFilter,
        modelWeeksFilter: initialFilters.modelWeeksFilter,
        weekStartDayFilter: initialFilters.weekStartDayFilter,
      },
      success: (res) => {
        if (res && res.data) {
          const sortedData = res.data.models.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          const rows = sortedData.map((item) => {
            const { _id, name, budgetType, numberOfSplit, status, weekStartDay } = item;
            return {
              id: _id,
              name,
              budgetType,
              numberOfSplit,
              status,
              modelStatus: [status],
              weekStartDay,
            };
          });
          setFilteredRows(rows);
          setTotalModels(res.data.total_models)
        }
        setLoading(false)
      },
      fail: (err) => {
        console.log('err', err);
        setLoading(false);
        showNotification('error', err.message);
      },
    }))
  }

  useEffect(() => {
    getModels()
  }, [initialFilters]);

  useEffect(() => {
    getModelList();
  }, []);

  const onActionHandle = (index, row, type) => {
    if (type === 'edit') {
      navigate(`/models/edit-model/${row.id}`);
    } else if (type === 'delete') {
      setItemsToDelete([row.id]);
      setStatusAction('delete');
      setAlert('delete');
    } else if (type === 'archived'){
      setItemsToDelete([row.id]);
      setAlert('archive');
    } else if (type === 'active'){
      setItemsToDelete([row.id]);
      setAlert('active');
    } else if (type === 'tool'){
      navigate(`/models/fine-tuning/${row.id}`)
    }
  };

  const handleClose = () => {
    setAlert(false);
    setItemsToDelete(false);
  };

  const handleCloseDialog = () => {
    setIsBulkDelete(false);
  };

  const handleBulkDeleteModal = (selectedItems, status) => {
    setIsBulkDelete(true)
    setItemsToDelete(selectedItems);
    setStatusAction(status);
  }

  const handleBulkDelete = (items, action) => {
    dispatch(changeModelStatus({
     url: "/models/status",
     method: 'POST',
     navigate,
     data: {
      list: isBulkDelete ? itemsToDelete : items,
      status: isBulkDelete ? statusAction : action
     },
     success: (res) => {
       handleCloseDialog();
       handleClose();
       showNotification('success', res.message);
       getModels();
     },
     fail: (err) => {
       showNotification('error', err.message);
       handleClose();
     },
    }))
 }

  const columns = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'NAME',
      sortable: true,
      filterType: 'checkbox',
    },
    {
      id: 'budgetType',
      numeric: false,
      disablePadding: false,
      label: 'BUDGET TYPE',
      sortable: true,
      searchFilter: true,
      valueType: 'array',
      filterType: 'checkbox',
    },
    {
      id: 'numberOfSplit',
      numeric: false,
      disablePadding: false,
      label: 'WEEKS',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
    },
    {
      id: 'modelStatus',
      numeric: false,
      disablePadding: false,
      label: 'STATUS',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      type: 'chip'
    },
    {
      id: 'weekStartDay',
      numeric: false,
      disablePadding: false,
      label: 'START DAY',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
    },
    {
      id: 'totalCost',
      numeric: false,
      disablePadding: false,
      label: 'TOTAL COST',
      sortable: true,
      searchFilter: true,
    },
    {
      id: 'totalHours',
      numeric: false,
      disablePadding: false,
      label: 'TOTAL HOURS',
      sortable: true,
      searchFilter: true,
    },
    {
      id: '',
      numeric: true,
      disablePadding: false,
      label: '',
      sortable: false,
      type: 'buttons',
      moreOptions: {
        buttonClick: onActionHandle,
        buttons: [{title: 'Tool', tooltip: 'Fine tuning'}, { title: 'Edit' }, { title: 'Archived' }, { title: 'Active' }, { title: 'Delete' }],
      },
    },
  ];

  const headerButtons = [
    {
      text: 'New model',
      variant: 'contained',
      color: 'success',
      width: '114px !important',
      startIcon: <img src={whiteAdd} alt="add" />,
      onClick: () => {
        navigate('/models/add-model');
      },
    },
  ];

  const actions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleClose },
    {
      title: `${capitalize(isAlert ? isAlert === 'active' ? "Activate" : isAlert : "")} model`,
      variant: 'contained',
      color:'danger',
      onClick: () => isAlert ? handleBulkDelete(itemsToDelete, isAlert) : '',
    },
  ];

  const actionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseDialog },
    {
      title: `${statusAction} models`,
      variant: 'contained',
      onClick: handleBulkDelete,
      color: `danger`
    },
  ];

  return (
    <>
      {(!isDataFilter && !filteredRows.length && !loading) ?  
       <NoDataFoundMessage title="No models to display yet." content={
        <>
          <CustomTypography
            variant="subtitleS1"
            text="Start by creating a new model using the button below"
            color={theme.palette.text.primary}
          />
          <ThemeButton
            variant="contained"
            text="New Model"
            startIcon= {<img src={whiteAdd} alt="add" />}
            onClick= {() => {navigate('/models/add-model');}}
          />
        </>
      } />  :
      <div style={{height: "100%"}}>
        <DataTable
          data={filteredRows}
          rows={filteredRows}
          columns={columns}
          onDelete={handleBulkDeleteModal}
          tableOf={['model', 'models']}
          defaultOrderBy="calories"
          defaultOrder="asc"
          selectable
          pagination={true}
          headerButtons={headerButtons}
          showStatusTabs={true}
          updateFilteredRows={updateFilteredRows}
          initialFilters={initialFilters}
          loading={loading}
          searchFilterOpt={searchFilterOpt}
          totalData = {totalModels}
          clickableRow={true}
        />
        <CustomDialogBox
          open={isAlert}
          onClose={handleClose}
          title={`${capitalize(isAlert || "")} Model`}
          description={`Are you sure you want to ${isAlert ? isAlert === 'active' ? "activate" : isAlert : ""} this model?`}
          actions={actions}
        />
        <CustomDialogBox
          open={isBulkDelete}
          onClose={handleCloseDialog}
          title={`${statusAction} Models`}
          description={`Are you sure you want to ${statusAction} ${itemsToDelete?.length > 1 ? `${itemsToDelete.length} models` : 'this model'}?`}
          actions={actionBtns}
        />
      </div>
      }
    </>
  );
};
export default Model;
  