import React from 'react';
import PropTypes from 'prop-types';
// import { weekDays } from './constant';
import { getWeeklyDetails } from '../../../../utils/helper';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const WeeklyDetailsTable = ({startDate, startDay, endDate}) => {
    const weekDetails = getWeeklyDetails(startDate, endDate, startDay);
    
    return (
        <div className='page-table'>
            <div className='table-wrapper table-details-container'>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Week number</TableCell>
                                <TableCell>1</TableCell>
                                <TableCell>2</TableCell>
                                <TableCell>...</TableCell>
                                <TableCell>{weekDetails.length - 1}</TableCell>
                                <TableCell>{weekDetails.length}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Starting dates</TableCell>
                                <TableCell>{weekDetails[0]?.startDate}</TableCell>
                                <TableCell>{weekDetails[1]?.startDate}</TableCell>
                                <TableCell>{weekDetails[2]?.startDate + " - " + weekDetails[weekDetails.length - 3]?.startDate}</TableCell>
                                <TableCell>{weekDetails[weekDetails.length - 2]?.startDate}</TableCell>
                                <TableCell>{weekDetails[weekDetails.length - 1]?.startDate}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Days in week</TableCell>
                                <TableCell>{weekDetails[0]?.daysInWeek}</TableCell>
                                <TableCell>7</TableCell>
                                <TableCell>7</TableCell>
                                <TableCell>7</TableCell>
                                <TableCell>{weekDetails[weekDetails.length - 1]?.daysInWeek}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default WeeklyDetailsTable

WeeklyDetailsTable.propTypes = {
    startDate: PropTypes.instanceOf(Date),
    startDay: PropTypes.string,
    endDate: PropTypes.instanceOf(Date),
    noOfWeeks: PropTypes.string,
};