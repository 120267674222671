import React from 'react';
import TextInput from '../../../../components/TextInput';
import CustomAutocomplete from '../../../../components/CustomAutocomplete';

const AddDriver = ({ newDriver, setNewDriver, errors, storeList }) => {

  const handleChange = (name, value) => {
    setNewDriver((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAutocomplete = (event, newValue) => {
    setNewDriver((prevValues) => ({
      ...prevValues,
      stores: newValue,
    }));
  };

  return (
    <div className="pd-24 pb-120">
      <TextInput
        label="Name"
        name="name"
        value={newDriver.name}
        onInputChange={(e) => handleChange(e.target.name, e.target.value)}
        mb="15px"
        helperText={errors.name}
      />
      <CustomAutocomplete
        name="stores"
        label="Stores"
        value={newDriver.stores}
        options={storeList}
        onChange={handleAutocomplete}
        width="100%"
        isMultiple={true}
      />
    </div>
  );
};

export default AddDriver;
