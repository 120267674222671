import React, { useEffect, useState } from 'react';
import TextInput from '../../../../../components/TextInput';
import { Box, Typography } from '@mui/material';
import SelectDropdown from '../../../../../components/SelectDropdown';
import CustomDatePicker from '../../../../../components/CustomDatePicker';
import moment from 'moment';
import { ValueTypes } from './constant';
import CustomDialogBox from '../../../../../components/CustomDialogBox';

const AddWeeklyParameter = ({
  parameter,
  setParameter,
  allGroupsData,
  selectedGroup,
  setSelectedGroup,
  editParameter,
  errors,
}) => {
  const [groupTypes, setGroupTypes] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    show: false
  });

  useEffect(() => {
    const existingGroups =
      allGroupsData &&
      allGroupsData.length > 0 &&
      allGroupsData?.map((item) => ({
        label: item.groupName,
        value: item.id,
      }));
    setGroupTypes(existingGroups || []);
  }, [allGroupsData]);

  useEffect(() => {
    if (editParameter) {
      setParameter({
        id: editParameter.id,
        groupId: editParameter.groupId,
        parameterName: editParameter.parameterName || '',
        notes: editParameter.notes || '',
        group: editParameter.group || '',
        type: editParameter.type || '',
        groupName: editParameter.group || '',
        value: editParameter.value || [],
      });
      setSelectedGroup(editParameter.groupId);
    } else {
      setParameter({
        ...parameter,
        value: [''],
      });
    }
  }, [editParameter]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'value') {
      const newValue = [...parameter.value];
      newValue[0] = value;
      setParameter({
        ...parameter,
        [name]: newValue,
      });
    } else if (name === 'type') {
      if(parameter?.type){
         setConfirmDialog({
          show: true,
          name, 
          value
         })
      } else{
        setParameter({
          ...parameter,
          [name]: value,
          value: [''],
        });
      }
    } else {
      setParameter({
        ...parameter,
        [name]: value,
      });
    }
  };

  const handleGroupChange = (event) => {
    const groupName = event.target.value;
    if (groupTypes.some((g) => g.label?.toLowerCase() === groupName?.toLowerCase())){
      const group = groupTypes.find((g) => g.label?.toLowerCase() === groupName?.toLowerCase()).value;
      setSelectedGroup(group);
    } else {
      setSelectedGroup(event.target.value);
    }
  };

  const checkInputType = () => {
    return (
      parameter?.type === 2 || parameter?.type === 3 || parameter?.type === 4
    );
  };

  const renderInput = () => {
    switch (parameter?.type) {
      case 1:
        return (
          <CustomDatePicker
            placeholder="29/08/2023"
            value={parameter?.value?.length ? parameter?.value[0] : ''}
            helperText={Array.isArray(errors?.value) ? errors?.value[0] : errors?.value}
            onChange={(date) =>
              handleChange({
                target: {
                  name: 'value',
                  value: moment(new Date(date)).format('YYYY-MM-DD'),
                },
              })
            }
          />
        );
      default:
        return (
          <TextInput
            type={checkInputType() ? 'number' : 'text'}
            placeholder=""
            value={parameter?.value?.length ? parameter?.value[0] : ''}
            onChange={(e) => handleChange(e)}
            className="text-input-height"
            name="value"
            helperText={Array.isArray(errors?.value) ? errors?.value[0] : errors?.value}
            width="100%"
          />
        );
    }
  };

  const changeGroupType = () => {
    setParameter({
      ...parameter,
      [confirmDialog.name]: confirmDialog.value,
      value: [''],
    });
    setConfirmDialog({show: false})
  }

  const confirmActions = [
    { title: 'Continue', variant: 'outlined', onClick: changeGroupType },
    {
      title: 'Cancel',
      variant: 'contained',
      onClick: () =>  setConfirmDialog({show: false}),
      color: 'danger',
    },
  ];

  return (
    <div className="parameter-modal-container">
      <Box mt={2}>
        <Typography variant="caption" sx={{ color: 'black' }}>
          Parameter name
        </Typography>
        <TextInput
          placeholder="Parameter name"
          value={parameter?.parameterName}
          onChange={handleChange}
          className="text-input-height"
          name="parameterName"
          helperText={errors?.parameterName}
        />
      </Box>
      <Box mt={2}>
        <Typography variant="caption" sx={{ color: 'black' }}>
          Notes (optional)
        </Typography>
        <TextInput
          placeholder="Notes (optional)"
          value={parameter?.notes}
          onChange={(e) => handleChange(e)}
          className="text-input-height"
          name="notes"
        />
      </Box>
      <Box mt={2}>
        <div className="flex-container" style={{ alignItems: 'center', gap: '8px' }}>
          <TextInput
            label="Group"
            className="bg-white w-100"
            placeholder={`Create a new group ${groupTypes && groupTypes.length > 0 ? 'or select' : ''}`}
            width="-webkit-fill-available"
            value={groupTypes.find((item) => item.value === selectedGroup)?.label || selectedGroup}
            name="group"
            onInputChange={handleGroupChange}
            helperText={errors?.selectedGroup}
          />
          {groupTypes && groupTypes.length > 0 && <div>
            <div style={errors.selectedGroup ? {height: '5px'} : {height: '25px'}} />
            <SelectDropdown
              classNameForFormControl="m-0 bg-white"
              items={groupTypes || []}
              handleChange={handleGroupChange}
              value={selectedGroup}
              name="group"
            />
          </div>}
        </div>
      </Box>
      <Box mt={2}>
        <Typography variant="caption" sx={{ color: 'black' }}>
          Value
        </Typography>

        <div className="parameter-input-wrapper">
          <SelectDropdown
            name="type"
            value={parameter?.type}
            items={ValueTypes || []}
            handleChange={handleChange}
            helperText={errors?.type}
            minWidth="100%"
          />
          {renderInput()}
        </div>
      </Box>
      <CustomDialogBox
        open={confirmDialog.show}
        onClose={() => setConfirmDialog({show: false})}
        title="Confirm Change value Type?"
        description="Are you sure you want to change value type? This will clear all the values."
        actions={confirmActions}
      />
    </div>
  );
};

export default AddWeeklyParameter;
