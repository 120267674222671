import React from "react";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import PropTypes from "prop-types";
import typography from "../theme/typography";
import './styles.scss'
import CustomTypography from "./CustomTypography";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
});

const TextInput = ({
  name,
  value,
  placeholder,
  onInputChange,
  type,
  sizeSmall,
  startAdornment,
  endAdornment,
  label,
  variant,
  widthInput,
  className,
  style,
  width,
  onIconClick,
  mb,
  ref,
  error,
  helperText,
  disabled,
  ...other
}) => {
  const classes = useStyles();
  return (
    <div style={{ width: width, marginBottom: mb }} className="text-input-wrapper">
      <CustomTypography style={typography.subtitle2} text={label}/>
      <TextField
        sx={style}
        className={`textField-input ${className} ${classes.input}`}
        type={type}
        value={value}
        placeholder={placeholder}
        name={name}
        inputRef={ref}
        widthInput={widthInput}
        onChange={onInputChange}
        variant={variant}
        size={sizeSmall ? "small" : "medium"}
        error={error}
        disabled={disabled}
        autoComplete="off"
        helperText={helperText}
        InputProps={{
          startAdornment: startAdornment && (
            <InputAdornment position="start" onClick={onIconClick}>
              {startAdornment}
            </InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment position="end" onClick={!startAdornment && onIconClick}>{endAdornment}</InputAdornment>
          ),
        }}
        {...other}
      />
    </div>
  );
};
TextInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  onInputChange: PropTypes.func,
  type: PropTypes.string,
  sizeSmall: PropTypes.bool,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  widthInput: PropTypes.string,
  onIconClick: PropTypes.func,
  mb: PropTypes.string,
  ref: PropTypes.object,
  disabled: PropTypes.bool,
};
TextInput.defaultProps = {
  variant: "outlined",
  disabled: false
};

export default TextInput;
