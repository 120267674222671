import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import SelectDropdown from '../../../../components/SelectDropdown';
import { conditionalOperators, operators } from './constant';
import VariableDropdown from './VariableDropdown';
import TextInput from '../../../../components/TextInput';
import { get } from 'lodash';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

const SubChildFormulaField = ({
  data,
  index,
  rowindex,
  modelData,
  variableIndex,
  childVariableIndex,
  handleOperatorChange,
  handleFreeInputChange,
  handleOpenMenu,
  handleCloseMenu,
  handleConditionalOperatorChange,
  handleBack
}) => {
  const [group] = data;
  const formula = get(group, `[${index}].rows[${rowindex}].formula`, {});

  const getParentVariable = () => {
    return formula.variable?.[variableIndex];
  };

  const getChildVariable = () => {
    return getParentVariable().variable?.[childVariableIndex];
  };

  const getSubChildVariable = (subChildVariableIndex) => {
    return getChildVariable().variable?.[subChildVariableIndex];
  };

  return (
    <div className="formula-fields-wrapper">
      {getChildVariable().type && (
        <>
          {
            (getChildVariable()?.type === 'IF_BOOLEAN' ||
            getChildVariable()?.type === 'IF_COMPARE') &&
            <Box className="bg-green">IF</Box>
          }
          {getSubChildVariable(0).type === 'DIRECT_VALUE' &&
          getSubChildVariable(0).source?.type === 'FREE' ? (
            <TextInput
              onIconClick={()=> handleBack('sub-child', 0, variableIndex, childVariableIndex)}
              endAdornment={<ArrowBackRoundedIcon/>}
              name="variable-field"
              value={getSubChildVariable(0).source?.path[1]}
              onInputChange={(e) =>
                handleFreeInputChange(
                  e.target.value,
                  'sub-child',
                  variableIndex,
                  childVariableIndex,
                  0
                )
              }
              type="number"
              className="sub-child"
              sizeSmall
            />
          ) : (
            <>
              <Box
                className="variable-field child"
                id="sub_child_field_variable_one"
                aria-controls="sub_child_menu_variable_one"
                onClick={(e) =>
                  handleOpenMenu(
                    e,
                    variableIndex,
                    'sub-child',
                    childVariableIndex,
                    0
                  )
                }
              >
                {getSubChildVariable(0).type === 'DIRECT_VALUE' &&
                getSubChildVariable(0).source?.path?.length > 1
                  ? getSubChildVariable(0).source?.path[
                      getSubChildVariable(0)?.source?.path?.length - 1
                    ]?.label || getSubChildVariable(0).source?.path[
                      getSubChildVariable(0)?.source?.path?.length - 1
                    ]
                  : ''}
              </Box>
              <VariableDropdown
                handleCloseMenu={handleCloseMenu}
                type="sub-child"
                parentVariableIndex={variableIndex}
                childVariableIndex={childVariableIndex}
                subChildVariableIndex={0}
                varIndex="one"
                className="menu-dropdown"
                data={data}
                index={index}
                rowindex={rowindex}
                anchorEl={getSubChildVariable(0).openMenu}
                open={Boolean(getSubChildVariable(0).openMenu)}
                modelData={modelData}
              />
            </>
          )}
        </>
      )}
      {
        (getChildVariable().type !== "IF_BOOLEAN" && getChildVariable()?.type !== 'IF_COMPARE') &&
        <SelectDropdown
          classNameForFormControl={`custom-min-width ${getChildVariable().type && getChildVariable().type !== 'DIRECT_VALUE' ? 'shrink' : ''}`}
          items={operators}
          handleChange={(e) =>
            handleOperatorChange(
              e,
              index,
              rowindex,
              variableIndex,
              childVariableIndex,
              0
            )
          }
          iconPosition="end"
          className="icon-dropdown"
          name="operator"
          value={getChildVariable().type}
          disabled={getChildVariable()?.type}
        />
      }
      {getChildVariable()?.type === 'IF_COMPARE' ? (
        <SelectDropdown
          classNameForFormControl="shrink"
          items={conditionalOperators}
          handleChange={(e) => handleConditionalOperatorChange(e, 'sub-child', variableIndex, childVariableIndex)}
          iconPosition="end"
          className="icon-dropdown"
          name="operator"
          value={getSubChildVariable(1)?.source?.path?.[1]}
        />
      ) : (
        ''
      )}
      {getChildVariable()?.type === 'IF_COMPARE' && (
        <>
          {getSubChildVariable(2).type === 'DIRECT_VALUE' &&
          getSubChildVariable(2).source?.type === 'FREE' ? (
            <TextInput
              onIconClick={()=> handleBack('sub-child', 2, variableIndex, childVariableIndex)}
              endAdornment={<ArrowBackRoundedIcon/>}
              name="variable-field"
              value={getSubChildVariable(2).source?.path[1]}
              onInputChange={(e) =>
                handleFreeInputChange(e.target.value, 'sub-child', variableIndex, childVariableIndex, 2)
              }
              type="number"
              className="sub-child"
              sizeSmall
            />
          ) : (
            <>
              <Box
                className="variable-field sub-child"
                id="child_field_variable_three"
                aria-controls="child_menu_variable_three"
                onClick={(e) => handleOpenMenu(e, variableIndex, 'sub-child', childVariableIndex, 2)}
              >
                {getSubChildVariable(2).type === 'DIRECT_VALUE' &&
                getSubChildVariable(2).source?.path?.length > 1
                  ? getSubChildVariable(2).source?.path[
                      getSubChildVariable(2)?.source?.path?.length - 1
                    ]?.label ||
                    getSubChildVariable(2).source?.path[
                      getSubChildVariable(2)?.source?.path?.length - 1
                    ]
                  : ''}
              </Box>
              <VariableDropdown
                handleCloseMenu={handleCloseMenu}
                type="sub-child"
                parentVariableIndex={variableIndex}
                childVariableIndex={childVariableIndex}
                subChildVariableIndex={2}
                varIndex="three"
                className="menu-dropdown"
                data={data}
                index={index}
                rowindex={rowindex}
                anchorEl={getSubChildVariable(2).openMenu}
                open={Boolean(getSubChildVariable(2).openMenu)}
                modelData={modelData}
              />
            </>
          )}
        </>
      )}
      {
        (getChildVariable()?.type === 'IF_BOOLEAN' ||
        getChildVariable()?.type === 'IF_COMPARE') &&
        <Box className="bg-green">then</Box>
      }
      {getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 3 : 1).type === 'DIRECT_VALUE' &&
      getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 3 : 1).source?.type === 'FREE' ? (
        <TextInput
          onIconClick={()=> handleBack('sub-child', getChildVariable()?.type === 'IF_COMPARE' ? 3 : 1, variableIndex, childVariableIndex)}
          endAdornment={<ArrowBackRoundedIcon/>}
          name="variable-field"
          value={getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 3 : 1).source?.path[1]}
          className="sub-child"
          onInputChange={(e) =>
            handleFreeInputChange(
              e.target.value,
              'sub-child',
              variableIndex,
              childVariableIndex,
              getChildVariable()?.type === 'IF_COMPARE' ? 3 : 1
            )
          }
          type="number"
          sizeSmall
        />
      ) : (
        <>
          <Box
            className="variable-field child"
            id={getChildVariable()?.type === 'IF_COMPARE' ? 'sub_child_field_variable_four' : `sub_child_field_variable_two`}
            aria-controls={getChildVariable()?.type === 'IF_COMPARE' ? 'sub_child_menu_variable_four' : `sub_child_menu_variable_two`}
            onClick={(e) =>
              handleOpenMenu(
                e,
                variableIndex,
                'sub-child',
                childVariableIndex,
                getChildVariable()?.type === 'IF_COMPARE' ? 3 : 1
              )
            }
          >
            {getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 3 : 1)?.type === 'DIRECT_VALUE' &&
            getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 3 : 1)?.source?.path?.length > 1
              ? getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 3 : 1).source?.path[
                  getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 3 : 1)?.source?.path?.length - 1
                ]?.label || getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 3 : 1).source?.path[
                  getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 3 : 1)?.source?.path?.length - 1
                ]
              : ''}
          </Box>
          <VariableDropdown
            handleCloseMenu={handleCloseMenu}
            type="sub-child"
            parentVariableIndex={variableIndex}
            childVariableIndex={childVariableIndex}
            subChildVariableIndex={getChildVariable()?.type === 'IF_COMPARE' ? 3 : 1}
            className="menu-dropdown"
            varIndex={getChildVariable()?.type === 'IF_COMPARE' ? 'four' : 'two'}
            data={data}
            index={index}
            rowindex={rowindex}
            anchorEl={getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 3 : 1).openMenu}
            open={Boolean(getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 3 : 1).openMenu)}
            modelData={modelData}
          />
        </>
      )}
      {
        (getChildVariable()?.type === 'IF_BOOLEAN' ||
        getChildVariable()?.type === 'IF_COMPARE') &&
        <Box className="bg-green">else</Box>
      }
      {(getChildVariable()?.type === 'IF_BOOLEAN' ||
        getChildVariable()?.type === 'IF_COMPARE') && (

      getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 4 : 2).source?.type === 'FREE' ? (
        <TextInput
          onIconClick={()=> handleBack('sub-child', getChildVariable()?.type === 'IF_COMPARE' ? 4 : 2, variableIndex, childVariableIndex)}
          endAdornment={<ArrowBackRoundedIcon/>}
          name="variable-field"
          value={getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 4 : 2).source?.path[1]}
          className="sub-child"
          onInputChange={(e) =>
            handleFreeInputChange(
              e.target.value,
              'sub-child',
              variableIndex,
              childVariableIndex,
              getChildVariable()?.type === 'IF_COMPARE' ? 4 : 2
            )
          }
          type="number"
          sizeSmall
        />
      ) : (
        <>
          <Box
            className="variable-field child"
            id={getChildVariable()?.type === 'IF_COMPARE' ? 'sub_child_field_variable_five' : `sub_child_field_variable_three`}
            aria-controls={getChildVariable()?.type === 'IF_COMPARE' ? 'sub_child_menu_variable_five' : `sub_child_menu_variable_three`}
            onClick={(e) =>
              handleOpenMenu(
                e,
                variableIndex,
                'sub-child',
                childVariableIndex,
                getChildVariable()?.type === 'IF_COMPARE' ? 4 : 2
              )
            }
          >
            {getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 4 : 2)?.type === 'DIRECT_VALUE' &&
            getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 4 : 2)?.source?.path?.length > 1
              ? getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 4 : 2).source?.path[
                  getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 4 : 2)?.source?.path?.length - 1
                ]?.label || getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 4 : 2).source?.path[
                  getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 4 : 2)?.source?.path?.length - 1
                ]
              : ''}
          </Box>
          <VariableDropdown
            handleCloseMenu={handleCloseMenu}
            type="sub-child"
            parentVariableIndex={variableIndex}
            childVariableIndex={childVariableIndex}
            subChildVariableIndex={getChildVariable()?.type === 'IF_COMPARE' ? 4 : 2}
            className="menu-dropdown"
            varIndex={getChildVariable()?.type === 'IF_COMPARE' ? "five" : "three"}
            data={data}
            index={index}
            rowindex={rowindex}
            anchorEl={getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 4 : 2)?.openMenu}
            open={Boolean(getSubChildVariable(getChildVariable()?.type === 'IF_COMPARE' ? 4 : 2)?.openMenu)}
            modelData={modelData}
          />
        </>
        )
      )}
    </div>
  );
};

export default SubChildFormulaField;

SubChildFormulaField.propTypes = {
  index: PropTypes.number,
  rowindex: PropTypes.number,
  data: PropTypes.array,
  modelData: PropTypes.object,
  variableIndex: PropTypes.number,
  handleOperatorChange: PropTypes.func,
  handleFreeInputChange: PropTypes.func,
  handleOpenMenu: PropTypes.func,
  handleCloseMenu: PropTypes.func,
  handleConditionalOperatorChange: PropTypes.func,
  handleBack: PropTypes.func,
};
