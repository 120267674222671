import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../components/DataGrid/DataTable';
import CustomDialogBox from '../../components/CustomDialogBox';
import { useDispatch } from 'react-redux';
import { changeUserStatus, filterUsers } from '../../redux/slice/users';
import { appConfig } from '../../config/appConfig';
import { useNotification } from '../../utils/NotificationProvider';
import whiteAdd from "../../assets/images/common/whiteAdd.svg"
import NoDataFoundMessage from '../../components/NoDataFoundMessage';
import CustomTypography from '../../components/CustomTypography';
import ThemeButton from '../../components/ThemeButton';
import theme from '../../theme';
import { fetchCompanies } from '../../redux/slice/company';
import { capitalize } from 'lodash';

const Users = () => {
  const [filteredRows, setFilteredRows] = useState([]);
  const [isAlert, setAlert] = useState(false);
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [statusAction, setStatusAction] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDataFilter, setIsDataFilter] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showNotification = useNotification();
  const [initialFilters, setInitialFilters] = useState({
    companyFilter: [],
    roleFilter: [],
    searchText: "",
    statusFilter: ['active', 'archive'],
    page: 1,
    limit: 10
  })

const updateFilteredRows = (data) => {
  setIsDataFilter(true)
  const { Role, searchQuery, Company, page, limit, statusFilter } = data;
  
  const roleFilter = Role ? Object.keys(Role).filter(key => Role[key]).map(Number) : [];
  const companyFilter = Company ? Object.keys(Company).filter(key => Company[key]) : [];
  
  const requestedPayload = {
      roleFilter,
      companyFilter,
      searchText: searchQuery ?? "",
      page: page || initialFilters.page,
      limit: limit || initialFilters.limit,
      statusFilter: statusFilter ? statusFilter : initialFilters.statusFilter,
  };
  
  setInitialFilters(prevFilters => ({
      ...prevFilters,
      ...requestedPayload,
  }));
};

const searchFilterOpt = [
  {name: 'Role', options: appConfig.userRoles, type: 'checkbox'},
  {name: 'Company', options: companyList, type: 'checkbox'},
]

  const getUsers = () => {
    setLoading(true)
    dispatch(filterUsers({
      url: '/users/filter',
      method: 'POST',
      navigate,
      data : {
        companyFilter: initialFilters.companyFilter,
        roleFilter: initialFilters.roleFilter,
        searchFilter: {
          searchField: [
            "firstName",
            "lastName",
            "email"
        ],
        searchText: initialFilters.searchText
        },
        page: initialFilters.page,
        limit: initialFilters.limit,
        statusFilter: initialFilters.statusFilter
      },
      success: (res) => {
        if (res && res.data && res.data.users) {
          const rows = res.data.users.map((item) => {
            const { firstName, lastName, role, email, _id, companies, status } = item;
            return {
              id: _id,
              fullName: `${firstName} ${lastName}`,
              email,
              role: appConfig?.userRoles?.find((item) => item.value === role)?.label || null,
              company: companies.map((company) => company.name) || null,
              status
            };
          });
          setFilteredRows(rows);
          setTotalUsers(res.data.total_users)
        }
        setLoading(false)
      },
      fail: (err) => {
        console.log('err', err);
        setLoading(false)
      },
    }))
  }

  const getCompanies = () => {
    setLoading(true)
    dispatch(
      fetchCompanies({
        url: `/companies/list`,
        method: 'GET',
        navigate,
        success: (res) => {
          if (res && res.data && res.data.length > 0) {
            const formattedData = res.data.map(item => ({
              label: item.name,
              value: item._id
            }));
            setCompanyList(formattedData)
          } else {
            setCompanyList([])
          }
          setLoading(false)
        },
        fail: (err) => {
          showNotification('error', err.message);
          setLoading(false)
        },
      })
    );
  };

  useEffect(() => {
    getUsers()
  }, [initialFilters]);

  useEffect(() => {
    getCompanies()
  }, [initialFilters]);

  const onActionHandle = (index, row, type) => {
    if (type === 'edit') {
      navigate(`/users/edit-user/${row.id}`);
    } else if (type === 'delete') {
      setItemsToDelete([row.id]);
      setStatusAction('delete');
      setAlert('delete');
    } else if (type === 'archived'){
      setItemsToDelete([row.id]);
      setAlert('archive');
    } else if (type === 'active'){
      setItemsToDelete([row.id]);
      setAlert('active');
    }
  };

  const handleClose = () => {
    setAlert(false);
    setItemsToDelete(false);
  };

  const handleCloseDialog = () => {
    setIsBulkDelete(false);
  };

  const handleBulkDelete = (items, action) => {
     dispatch(changeUserStatus({
      url: "/users/status",
      method: 'POST',
      navigate,
      data: {
        list: isBulkDelete ? itemsToDelete : items,
        status: isBulkDelete ? statusAction : action
      },
      success: () => {
        handleCloseDialog();
        handleClose();
        showNotification('success', `${(itemsToDelete?.length || items?.length) > 1 ? 'Users' : 'User'} ${(statusAction || action) === 'active' ? 'activated' : `${statusAction || action}d`} successfully!`);
        getUsers()
      },
      fail: (err) => {
        showNotification('error', err.message)
      },
     }))
  }

  const handleBulkDeleteModal = (selectedItems, status) => {
    setIsBulkDelete(true)
    setItemsToDelete(selectedItems);
    setStatusAction(status);
  }

  const columns = [
    {
      id: 'fullName',
      numeric: false,
      disablePadding: true,
      label: 'FULL NAME',
      sortable: true,
      width: '20%'
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'EMAIL',
      sortable: true,
      filterType: 'checkbox',
      width: '20%',
      small: true
    },
    {
      id: 'role',
      numeric: false,
      disablePadding: false,
      label: 'ROLE',
      sortable: true,
      searchFilter: true,
      valueType: 'array',
      filterType: 'checkbox',
      width: '18%',
      capital: true
    },
    {
      id: 'company',
      numeric: false,
      disablePadding: false,
      label: 'COMPANY',
      type: 'chip',
      toDisplay: 2,
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '25%'
    },
    {
      id: '',
      numeric: true,
      disablePadding: false,
      label: '',
      sortable: false,
      type: 'buttons',
      moreOptions: {
        buttonClick: onActionHandle,
        buttons: [{ title: 'Edit' }, { title: 'Archived' }, { title: 'Active' }, { title: 'Delete' }],
      },
      width: '17%'
    },
  ];

  const headerButtons = [
    {
      text: 'New User',
      variant: 'contained',
      color: 'success',
      width: '114px !important',
      startIcon: <img src={whiteAdd} alt="add" />,
      onClick: () => {
        navigate('/users/add-user');
      },
    },
  ];

  const actions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleClose },
    {
      title: `${capitalize(isAlert ? isAlert === 'active' ? "Activate" : isAlert : "")} user`,
      variant: 'contained',
      color:'danger',
      onClick: () => isAlert ? handleBulkDelete(itemsToDelete, isAlert) : '',
    },
  ];

  const actionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseDialog },
    {
      title: `${statusAction} users`,
      variant: 'contained',
      onClick: handleBulkDelete,
      color: `danger`
    },
  ];

  return (
    <>
      {(!isDataFilter && !filteredRows.length && !loading) ?  
       <NoDataFoundMessage title="No users created yet." content={
        <>
          <CustomTypography
            variant="subtitleS1"
            text="Start by creating a new user using the button below"
            color={theme.palette.text.primary}
          />
          <ThemeButton
            variant="contained"
            text="New User"
            startIcon= {<img src={whiteAdd} alt="add" />}
            onClick= {() => {navigate('/users/add-user');}}
          />
        </>
      } /> :
      <div className='users-list' style={{height: "100%"}}>
        <DataTable
          data={filteredRows}
          rows={filteredRows}
          columns={columns}
          onDelete={handleBulkDeleteModal}
          tableOf={['user', 'users']}
          defaultOrderBy="calories"
          defaultOrder="asc"
          selectable
          pagination={true}
          headerButtons={headerButtons}
          updateFilteredRows={updateFilteredRows}
          initialFilters={initialFilters}
          searchFilterOpt={searchFilterOpt}
          totalData = {totalUsers}
          loading={loading}
          NoDataFoundTitle="No users created yet."
          NoDataFoundText='Start by creating a new user using the button above.'
        />
        <CustomDialogBox
          open={isAlert}
          onClose={handleClose}
          title={`${capitalize(isAlert || "")} User`}
          description={`Are you sure you want to ${isAlert ? isAlert === 'active' ? "activate" : isAlert : ""} this user?`}
          actions={actions}
        />
        <CustomDialogBox
          open={isBulkDelete}
          onClose={handleCloseDialog}
          title={`${statusAction} Users`}
          description={`Are you sure you want to ${statusAction} ${itemsToDelete?.length > 1 ? `${itemsToDelete.length} users` : 'this user'}? `}
          actions={actionBtns}
        />
      </div>}
    </>
  );
};
export default Users;
