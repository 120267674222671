import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNotification } from '../../../../utils/NotificationProvider';
import SelectDropdown from '../../../../components/SelectDropdown';
import TextInput from '../../../../components/TextInput';
import { generateUniqueId } from '../../../../utils/helper';
import CustomTypography from '../../../../components/CustomTypography';
import typography from '../../../../theme/typography';
import CustomCheckbox from '../../../../components/CustomCheckbox';
import CustomTooltip from '../../../../components/CustomTooltip';
import FormulaFields from './FormulaFields';
import { outputType } from './constant';
import { get, set } from 'lodash';
import SubTotalFields from './SubTotalFields';

const RowComponent = ({
  item,
  index,
  row,
  rowindex,
  handleNameChange,
  data,
  key,
  modelData,
  handleRemoveRow,
}) => {
  const showNotification = useNotification();
  const [group, setGroup] = data;

  const getRows = (index) => {
    return get(group, `[${index}].rows`, []);
  };

  const handleChangeFineTuning = (e, i, rowindex) => {
    const groupData = group;
    set(groupData, `[${i}].rows[${rowindex}].fineTuning`, e.target.checked);
    setGroup([...groupData]);
  };

  const handleChangeOutputType = (e, i, rowindex) => {
    const groupData = group;
    set(groupData, `[${i}].rows[${rowindex}].outputType`, e.target.value);
    setGroup([...groupData]);
  };

  const addRow = (index) => {
    const groupData = group;

    if (
      groupData[index].type === 'WORKLOAD' &&
      groupData[index]?.taskOptions?.length ===
        groupData[index]?.rows.length - 1
    ) {
      showNotification(
        'error',
        `You can't add more than ${groupData[index]?.taskOptions?.length} rows`
      );
    } else {
      const newRowData = {
        _id: generateUniqueId(),
        name: '',
        rowId: `${groupData[index].letter}${getRows(index).length}`,
        outputType: 'NUMBER',
        fineTuning: false,
        formula: {},
      };

      set(
        groupData,
        `[${index}].rows[${getRows(index).length - 1}].rowId`,
        `${groupData[index].letter}${getRows(index).length + 1}`
      );

      const updatedRows = [...getRows(index)];
      updatedRows.splice(getRows(index).length - 1, 0, newRowData);

      // Add new row to Group sub total
      if (updatedRows.length > 1) {
        let formula = {
          type: 'ADD',
          variable: [],
        };
        updatedRows.forEach((item) => {
          if (item.type !== 'subtotal') {
            formula.variable = [
              ...formula.variable,
              {
                type: 'DIRECT_VALUE',
                source: {
                  type: 'REFERENCE',
                  path: ['REFERENCE', item.rowId],
                },
              },
            ];
          }
        });
        updatedRows[updatedRows.length - 1].formula = formula;
      }

      set(groupData, `[${index}].rows`, updatedRows);

      setGroup([...groupData]);
    }
  };
  return (
    <div key={key} className="group-row">
      <div className="row-id">
        <CustomTypography text={row.rowId} style={typography.subtitle2} />
      </div>
      <div className={`row-items-wrapper ${item.rows[rowindex].error && 'error'}`}>
        <div className="row-items">
          <div className="row-name-field">
            {rowindex !== item.rows.length - 1 && item.type === 'WORKLOAD' ? (
              <SelectDropdown
                classNameForFormControl="custom-min-width"
                items={item?.taskOptions}
                handleChange={(e) => handleNameChange(e, index, rowindex)}
                name="rowName"
                value={item.rows[rowindex].itemId || item.rows[rowindex].name}
                menuClasses="row-names"
              />
            ) : (
              <TextInput
                placeholder="Row Name"
                className="text-input-height field"
                onInputChange={(e) => handleNameChange(e, index, rowindex)}
                name="rowName"
                value={item.rows[rowindex].name}
                disabled={item.rows[rowindex].type === 'subtotal'}
              />
            )}
          </div>
          <SelectDropdown
            width="20%"
            classNameForFormControl="custom-min-width"
            items={outputType}
            handleChange={(e) => handleChangeOutputType(e, index, rowindex)}
            name="output-type"
            value={row.outputType}
          />
          <span className="equal-symbol">=</span>
          {item.rows[rowindex].type && item.rows[rowindex].type === 'subtotal' ? (
            <SubTotalFields groupItem={item} rowindex={rowindex} />
          ) : (
            <FormulaFields
              data={[group, setGroup]}
              index={index}
              rowindex={rowindex}
              modelData={modelData}
            />
          )}
          
        </div>
        {item.rows[rowindex].error && <CustomTypography text={item.rows[rowindex].error} style={{...typography.caption, color: '#d12929', marginTop: '2px'}} />}
      </div>
      {row?.type !== 'subtotal' && (
        <>
        <CustomCheckbox
          key={row._id}
          label={'Apply FT'}
          name={'apply_ft'}
          checked={row?.fineTuning}
          handleChange={(e) => handleChangeFineTuning(e, index, rowindex)}
        />
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => handleRemoveRow(index, row._id)}
            className="remove-row-btn"
          >
            <DeleteOutlineOutlinedIcon color='danger' />
          </IconButton>
        </>
      )}
      {rowindex === item.rows.length - 1 && (
        <div className="add-row-btn">
          <CustomTooltip
            title="Add Row"
            component={
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => addRow(index)}
              >
                <AddRoundedIcon /> <span>Add Row</span>
              </IconButton>
            }
          />
        </div>
      )}
    </div>
  );
};

export default RowComponent;

RowComponent.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  row: PropTypes.object,
  rowindex: PropTypes.number,
  handleNameChange: PropTypes.func,
  handleRemoveRow: PropTypes.func,
  data: PropTypes.array,
  key: PropTypes.string,
  modelData: PropTypes.object,
};
