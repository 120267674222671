import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { PropTypes } from 'prop-types';
import CompanyHierarchy from './CompanyHierarchy';
import Roles from './Roles';
import Drivers from './Drivers';
import Calendar from './Calendar';
import Calculations from './Calculations';
import GlobalSettings from './GlobalSettings';
import ThemeButton from '../../../components/ThemeButton';
import VerticalStepper from '../../../components/VerticalStepper';
import './styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {
  budgetTypeOpt,
  currencyOpt,
  weekDays,
  weekModelTabs,
} from './GlobalSettings/constant';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { addModel, fetchModel, getStepsInfo, updateHours } from '../../../redux/slice/model';
import CompanyCharacteristics from './CompanyCharacteristics';
import { get } from 'lodash';

import {
  deepCompareArrays,
  deepCompareObjects,
  formatDate,
  validateFormData,
} from '../../../utils/helper';

import { useNotification } from '../../../utils/NotificationProvider';
import { ValueTypes } from './GlobalSettings/Group/constant';
import { initialSteps } from '../../../utils/constants';
import Workgroup from './Workgroup';
import CustomDialogBox from '../../../components/CustomDialogBox';
import Loading from '../../../utils/loading';

const AddEditModel = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [validationRules, setValidationRules] = useState({});
  const [errors, setErrors] = useState({});
  const [modelId, setModelId] = useState('');
  const [completedSteps, setCompletedSteps] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showNotification = useNotification();
  const params = useParams();
  const editMode = params.action === 'edit-model';
  const [modelSteps, setModelSteps] = useState([]);
  const [expandMenu, setExpandMenu] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isCancelAlert, setIsCancelAlert] = useState(false);
  const [openStep, setOpenStep] = useState(true);
  const [isPrevious, setIsPrevious] = useState(false);
  const [stepperStep, setStep] = useState(null);
  const [modelData, setModelData] = useState();

  useEffect(() => {
    setModelId(get(params, 'id', ''));
  }, [params]);

  useEffect(() => {
    setModelSteps([...initialSteps]);
  }, []);

  useEffect(() => {
    if (editMode) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [editMode]);

  const steps = [
    {
      component: (
        <GlobalSettings
          formData={formData}
          setFormData={setFormData}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
          setErrors={setErrors}
          errors={errors}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
        />
      ),
      heading: 'Global Settings',
      key: 'globalSettings',
    },
    {
      component: (
        <CompanyHierarchy
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
          modelData={modelData}
        />
      ),
      heading: 'Company hierarchy',
      key: 'companyHierarchy',
    },
    {
      component: (
        <Roles
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
        />
      ),

      heading: 'Roles',
      key: 'role',
    },
    {
      component: (
        <CompanyCharacteristics
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
        />
      ),
      heading: 'Characteristics',
      key: 'companyCharacteristics',
    },
    // {
    //   component: (
    //     <TimeStandards
    //       formData={formData}
    //       setFormData={setFormData}
    //       modelId={modelId}
    //       editMode={editMode}
    //       completedSteps={completedSteps}
    //       loading={loading}
    //       validationRules={validationRules}
    //       setValidationRules={setValidationRules}
    //       modelData={modelData}
    //     />
    //   ),

    //   heading: 'SMVs',
    //   key: 'timeStandards',
    // },
    {
      component: (
        <Workgroup
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
          modelData={modelData}
        />
      ),

      heading: 'Workgroup and SMVs',
      key: 'workgroupManagement',
    },
    {
      component: (
        <Drivers
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
          modelData={modelData}
        />
      ),

      heading: 'Weekly Drivers',
      key: 'drivers',
    },
    {
      component: (
        <Calendar
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
          modelData={modelData}
        />
      ),

      heading: 'Calendar data',
      key: 'calendar',
    },
    {
      component: (
        <Calculations
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
          modelData={modelData}
        />
      ),

      heading: 'Calculation',
      key: 'calculation',
    },
  ];

  const fetchStepsInfo = () => {
    dispatch(
      getStepsInfo({
        url: `/models/steps/${modelId}`,
        method: 'GET',
        success: (res) => {
          setCompletedSteps(res.data);
        },
        fail: (err) => {
          console.log('err', err);
        },
      })
    );
  };

  useEffect(() => {
    if (modelId) {
      fetchStepsInfo();
    }
  }, [modelId]);

  const getModelData = () => {
    dispatch(
      fetchModel({
        url: `/models/${modelId}`,
        method: 'GET',
        navigate,
        success: (res) => {
          const data = get(res, 'data[0].model', {});
          setModelData(data);
          setLoading(false);
        },
        fail: (err) => {
          console.log('err of single model', err);
          setLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    if (get(completedSteps, 'globalSettings')) {
      getModelData();
    }
  }, [modelId, completedSteps]);

  const getActiveStep = () => {
    const nextStepIndex = modelSteps.findIndex(
      (step) => !completedSteps[step.key]
    );
    const updatedSteps = modelSteps.map((step, index) => {
      if (index === nextStepIndex) {
        return { ...step, status: 'inProgress' };
      }
      if (index < nextStepIndex) {
        return { ...step, status: 'completed' };
      }
      if (index > nextStepIndex) {
        return { ...step, status: 'pending' };
      }
      return step;
    });
    setModelSteps(updatedSteps);
    setActiveStep(nextStepIndex >= 0 ? nextStepIndex : 0);
    setOpenStep(false);
  };

  useEffect(() => {
    if (completedSteps && openStep && editMode) {
      getActiveStep();
    }
  }, [completedSteps]);

  /* eslint-disable no-unused-vars */
  const getPayload = (payload, stepName) => {
    let formattedPayload = {};

    const isEqual = (row, prevRow) => {
      return Object.keys(row).every((key) => {
        const rowValue = row[key];
        const prevRowValue = prevRow[key];

        if (Array.isArray(rowValue) && Array.isArray(prevRowValue)) {
          return deepCompareArrays(rowValue, prevRowValue, 0);
        }
        if (
          typeof rowValue === 'object' &&
          rowValue !== null &&
          typeof prevRowValue === 'object' &&
          prevRowValue !== null
        ) {
          return deepCompareObjects(rowValue, prevRowValue);
        }
        return row[key] === prevRow[key];
      });
    };
    // payload for global settings (step 1)
    if (stepName === 'globalSettings') {
      const {
        fyName,
        currency,
        budgetType,
        budgetStartDate,
        budgetEndDate,
        modelWeeks,
        parameters,
        weekStartDay,
      } = payload;

      const findLabel = (array, value, key) =>
        array.find((item) => item.value === value)?.[key];
      const transformedData = parameters.map((group) => ({
        name: group.groupName,
        _id: group.id,
        parameters: group.parameters.map((param) => ({
          name: param.parameterName,
          note: param.notes || '',
          type: ValueTypes.find((item) => item.value === param.type)?.type,
          values: param.value,
          _id: param.id,
        })),
      }));
      const modelData = {
        name: fyName,
        budgetType: findLabel(
          budgetTypeOpt,
          budgetType,
          'label'
        )?.toLowerCase(),
        currency: findLabel(currencyOpt, currency, 'label'),
        numberOfSplit: findLabel(weekModelTabs, modelWeeks, 'title'),
        reportingPeriod: 'monthly',
        weekStartDay: findLabel(weekDays, weekStartDay, 'label')?.toLowerCase(),
        budgetStartDate: budgetStartDate
          ? moment(new Date(budgetStartDate)).format('YYYY-MM-DD')
          : null,
        budgetEndDate: budgetEndDate
          ? moment(budgetEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
        parameterGroups: transformedData,
      };
      const prevData = {
        name: payload.prevData.name,
        budgetType: payload.prevData.budgetType,
        currency: payload.prevData.currency,
        numberOfSplit: payload.prevData.numberOfSplit,
        reportingPeriod: payload.prevData.reportingPeriod,
        weekStartDay: payload.prevData.weekStartDay,
        budgetStartDate: payload.prevData.budgetStartDate
          ? moment(new Date(payload.prevData.budgetStartDate)).format(
              'YYYY-MM-DD'
            )
          : null,
        budgetEndDate: payload.prevData.budgetEndDate
          ? moment(
              new Date(payload.prevData.budgetEndDate),
              'DD/MM/YYYY'
            ).format('YYYY-MM-DD')
          : null,
        parameterGroups: payload.prevData.parameterGroups,
      };
      if (!isEqual(modelData, prevData)) {
        formattedPayload.model = modelData;
      } else {
        formattedPayload = null;
      }
    }

    // payload for company hierarchy (step 2)
    if (stepName === 'companyHierarchy') {
      const hierarchyData = {
        updateStores: [],
        addedStores: [],
        deletedStores: [],
        levels: [],
        structureColumns: [],
      };

      const formattedData = (arr) => {
        const rowData = arr.map((item) => ({
          ...item,
          no: item.no,
          startDate: item.startDate ? formatDate(item.startDate) : '',
          endDate: item.endDate ? formatDate(item.endDate) : '',
        }));
        return rowData;
      };

      formattedData(get(payload, 'stores', [])).forEach((row) => {
        const matchingPrev = formattedData(
          get(payload, 'prevData.rows', [])
        ).find((prev) => prev.id === row.id);
        if (matchingPrev) {
          if (!isEqual(row, matchingPrev)) {
            hierarchyData.updateStores.push(row);
          }
        } else {
          hierarchyData.addedStores.push(row);
        }
      });

      formattedData(get(payload, 'prevData.rows', [])).forEach((prev) => {
        const matchingRow = formattedData(get(payload, 'stores', [])).find(
          (row) => row.id === prev.id
        );

        if (!matchingRow) {
          hierarchyData.deletedStores.push(prev.id);
        }
      });

      const transformedData = (data, isUpdate) => {
        const rows = data.map(
          ({
            id,
            no,
            name,
            startDate,
            endDate,
            characteristicData,
            ...rest
          }) => {
            const structureData = {};
            for (const [key, value] of Object.entries(rest)) {
              structureData[key] = value;
            }
            let requestedObj = {};
            requestedObj = {
              no: no ? no : '',
              name,
              startDate,
              endDate,
              structureData,
              characteristicData,
            };
            if (isUpdate) {
              requestedObj._id = id;
            }
            return requestedObj;
          }
        );
        return rows;
      };

      const levelData = payload.level.map((lv) => {
        return lv.value.map((item) => item.value);
      });

      if (
        hierarchyData.addedStores.length === 0 &&
        hierarchyData.updateStores.length === 0 &&
        hierarchyData.deletedStores.length === 0 &&
        deepCompareArrays(
          get(payload, 'structureColumns', []),
          get(payload, 'prevData.columns', []),
          1
        ) &&
        deepCompareArrays(
          get(payload, 'level', []),
          get(payload, 'prevData.levels', []),
          0
        )
      ) {
        formattedPayload = null;
      } else {
        formattedPayload = {
          hierarchy: {
            level: levelData,
            addStores: transformedData(hierarchyData.addedStores, false),
            updateStores: transformedData(hierarchyData.updateStores, true),
            deleteStores: hierarchyData.deletedStores,
            structureColumns: payload.structureColumns,
          },
        };
      }
    }

    // payload for company characteristics (step 3)
    if (stepName === 'companyCharacteristics') {
      const characteristicsData = {
        updateStores: [],
      };
      get(payload, 'stores', []).forEach((row) => {
        const matchingPrev = get(payload, 'prevData.rows', []).find(
          (prev) => prev.id === row.id
        );
        if (matchingPrev) {
          if (!isEqual(row, matchingPrev)) {
            characteristicsData.updateStores.push(row);
          }
        }
      });
      const transformedData = characteristicsData.updateStores.map(
        ({ no, name, id, inputType, newCharacteristics, ...rest }) => {
          const characteristicData = {};
          for (const [key, value] of Object.entries(rest)) {
            characteristicData[key] = value;
          }
          return {
            _id: id,
            characteristicData,
          };
        }
      );
      if (
        characteristicsData.updateStores.length === 0 &&
        deepCompareArrays(
          get(payload, 'characteristicColumns', []),
          get(payload, 'prevData.columns', []),
          0
        )
      ) {
        formattedPayload = null;
      } else {
        formattedPayload = {
          hierarchy_characteristicData: {
            characteristicColumns: payload.characteristicColumns,
            updateStores: transformedData,
          },
        };
      }
    }

    // payload for tasks (step 4)
    if (stepName === 'timeStandards') {
      const tasksData = {
        addTasks: [],
        updateTasks: [],
        deleteTasks: [],
      };

      const formattedData = (arr) => {
        const rowData = arr.map((item) => ({
          ...item,
          basicMinutes: Number(item.basicMinutes),
          relaxationAllowance: Number(item.relaxationAllowance),
          contingencyAllowance: Number(item.contingencyAllowance),
          standardMinuteValue: Number(item.standardMinuteValue),
          startDate: item.startDate ? formatDate(item.startDate) : '',
          endDate: item.endDate ? formatDate(item.endDate) : '',
        }));
        return rowData;
      };

      formattedData(get(payload, 'tasks', [])).forEach((row) => {
        const matchingPrev = formattedData(get(payload, 'prevData', [])).find(
          (prev) => prev.id === row.id
        );
        if (matchingPrev) {
          if (!isEqual(row, matchingPrev)) {
            tasksData.updateTasks.push(row);
          }
        } else {
          tasksData.addTasks.push(row);
        }
      });

      formattedData(get(payload, 'prevData', [])).forEach((prev) => {
        const matchingRow = formattedData(get(payload, 'tasks', [])).find(
          (row) => row.id === prev.id
        );

        if (!matchingRow) {
          tasksData.deleteTasks.push(prev.id);
        }
      });

      const transformedData = (data, isUpdate) => {
        const rows = data.map((item) => {
          const {
            id,
            startDate,
            endDate,
            basicMinutes,
            relaxationAllowance,
            contingencyAllowance,
            standardMinuteValue,
            name,
            unitOfMeasure,
          } = item;
          let requestedObj = {};
          requestedObj = {
            startDate: startDate ? formatDate(startDate) : '',
            endDate: endDate ? formatDate(endDate) : '',
            basicMinutes: Number(basicMinutes),
            relaxationAllowance: Number(relaxationAllowance),
            contingencyAllowance: Number(contingencyAllowance),
            standardMinuteValue: Number(standardMinuteValue),
            name,
            unitOfMeasure,
          };
          if (isUpdate) {
            requestedObj._id = id;
          }
          return requestedObj;
        });
        return rows;
      };
      if (
        tasksData.addTasks.length === 0 &&
        tasksData.updateTasks.length === 0 &&
        tasksData.deleteTasks.length === 0
      ) {
        formattedPayload = null;
      } else {
        formattedPayload = {
          task: {
            addTasks: transformedData(tasksData.addTasks, false),
            updateTasks: transformedData(tasksData.updateTasks, true),
            deleteTasks: tasksData.deleteTasks,
          },
        };
      }
    }

    // payload for Workgroup (step 5)
    if (stepName === 'workgroupManagement') {
      if (!payload || !payload.workgroups || !payload.prevData) return null;

      const workgroupData = {
        addWorkloads: [],
        updateWorkloads: [],
        deleteWorkloads: [],
      };

      const getDifferentValues = (obj1, obj2) => {
        if((obj1._id || obj1.id) === (obj2._id || obj2.id)){
          let changes = {};
          Object.keys(obj1).forEach((key) => {
            if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
              changes[key] = obj2[key];
            }
          });
          return Object.keys(changes).length ? changes : null;
        }
        return null
      };

      get(payload, 'workgroups', []).forEach((row) => {
        const matchingPrev = get(payload, 'prevData', []).find(
          (prev) => prev.id === row.id
        );
        matchingPrev
          ? !isEqual(row, matchingPrev) &&
            workgroupData.updateWorkloads.push(row)
          : workgroupData.addWorkloads.push(row);
      });

      get(payload, 'prevData', []).forEach((prev) => {
        if (!get(payload, 'workgroups', []).some((row) => row.id === prev.id)) {
          workgroupData.deleteWorkloads.push(prev);
        }
      });

      const formatTask = (task) => ({
        name: task.name,
        startDate: new Date(task.startDate).toISOString().substring(0, 10),
        endDate: new Date(task.endDate).toISOString().substring(0, 10),
        category: task.category,
        basicMinutes: +task.basicMinutes,
        relaxationAllowance: +task.relaxationAllowance,
        contingencyAllowance: +task.contingencyAllowance,
        standardMinuteValue: +task.standardMinuteValue,
        unitOfMeasure: task.unitOfMeasure,
      });

      const newWorkGroups = workgroupData.addWorkloads.map((wg) => ({
        name: wg.workload,
        type: 'NEW_WORKLOAD',
        tasks: { ...(wg.tasksList.length && {add: wg.tasksList?.map(formatTask)}) },
      }));

      const updatedWorkGroups = [];

      workgroupData.updateWorkloads.forEach((wg) => {
        const prevWorkload = get(payload, 'prevData', []).find(
          (prev) => prev.id === wg.id
        );
        if (!prevWorkload) return;

        let tasks = { add: [], update: [], delete: [] };
        if(prevWorkload.id === wg.id){
          wg.tasksList.forEach((task) => {
            const prevTask = prevWorkload.tasksList.find(
              (t) => (t._id || t.id) === (task.id || task._id)
            );
            if (!prevTask) {
              tasks.add.push(formatTask(task));
            } else {
              const updatedTask = getDifferentValues(prevTask, task);
              if (updatedTask) {
                tasks.update.push({ _id: (task.id || task._id), ...formatTask(task) });
              }
            }
          });
  
          prevWorkload.tasksList.forEach((prevTask) => {
            if (!wg.tasksList.some((task) => (task.id || task._id) === (prevTask._id || prevTask.id)))
              tasks.delete.push(prevTask._id);
          });
        }


        if (
          tasks.add.length ||
          tasks.update.length ||
          tasks.delete.length ||
          prevWorkload.workload !== wg.workload
        ) {
          updatedWorkGroups.push({
            _id: wg.id,
            name: wg.workload,
            type: 'UPDATE_WORKLOAD',
            tasks: {
              ...(tasks.add.length && {add: tasks.add}),
              ...(tasks.update.length && {update: tasks.update}),
              ...(tasks.delete.length && {delete: tasks.delete}),
            },
          });
        }
      });

      const deletedWorkGroups = workgroupData.deleteWorkloads.map((wg) => ({
        _id: wg._id || wg.id,
        type: 'DELETE_WORKLOAD',
      }));

      if (
        newWorkGroups.length ||
        updatedWorkGroups.length ||
        deletedWorkGroups.length
      ) {
        formattedPayload = {
          workgroup: {
            workloads: [
              ...newWorkGroups,
              ...updatedWorkGroups,
              ...deletedWorkGroups,
            ],
          },
        };
      } else {
        formattedPayload = null
      }
    }

    // payload for Role (step 6)
    if (stepName === 'role') {
      const formattedStores = (arr) => {
        const rowData = arr.map((item) => ({
          ...item,
          postPayRise: Number(item.payPostPayRise),
          prePayRise: Number(item.payPrePayRise),
          payRise: Number(item.payRise),
          payRiseWeek: Number(item.payRiseWeek),
          FTEHours: Number(item.hours),
          rule: Number(item.rule),
        }));
        return rowData;
      };
      const formattedData = (arr) => {
        const rowData = arr.map((item) => ({
          ...item,
          stores: formattedStores(item.stores),
        }));
        return rowData;
      };
      const prevData = formattedData(get(payload, 'prevData', []));
      const groups = formattedData(get(payload, 'groups', []));

      const getModifiedRoles = (prevRoles, updatedRoles) => {
        return updatedRoles
          .filter((role) => role.type)
          .map((role) => {
            if (role.type === 'UPDATE_ROLE') {
              const originalRole = prevRoles.find((r) => r.id === role.id);
              if (!originalRole) return role;
              const modifiedStores = role.stores.filter((store) => store.type);
              const storesChanged =
                modifiedStores.length > 0 ||
                role.stores.length !== originalRole.stores.length;
              return {
                ...role,
                stores: storesChanged ? modifiedStores : [],
              };
            }
            return role;
          });
      };

      const transformmedData = (data) => {
        const rows = data.map((item) => {
          const { maximum, minimum, roleName, stores, type, id } = item;
          let requestedObj = {};
          requestedObj = {
            name: roleName,
            type,
            stores: stores.map((st) => {
              const {
                storeId,
                type,
                payPostPayRise,
                payPrePayRise,
                payRise,
                payRiseWeek,
                FTEHours,
                rule,
                id,
              } = st;
              let requestedStore = {};
              requestedStore = {
                storeId,
                type,
                postPayRise: Number(payPostPayRise),
                prePayRise: Number(payPrePayRise),
                payRise: Number(payRise),
                payRiseWeek: Number(payRiseWeek),
                FTEHours: Number(FTEHours),
                rule: Number(rule),
              };
              return requestedStore;
            }),
          };
          if (type !== 'NEW_ROLE') {
            requestedObj._id = id;
          }
          return requestedObj;
        });
        return rows;
      };
      const modifiedData = getModifiedRoles(prevData, groups);
      if (modifiedData.length === 0) {
        formattedPayload = null;
      } else {
        formattedPayload = {
          role: {
            groups: transformmedData(modifiedData),
          },
        };
      }
    }

    // payload for Drivers (step 7)
    if (stepName === 'drivers') {
      const formattedStores = (stores) => {
        return stores.map((store) => {
          const updatedStore = { ...store };

          Object.keys(updatedStore).forEach((key) => {
            if (key.startsWith('wd')) {
              updatedStore[key] = parseFloat(updatedStore[key]);
            }
          });

          return updatedStore;
        });
      };

      const formattedData = (arr) => {
        const rowData = arr.map((item) => ({
          ...item,
          stores: formattedStores(item.stores),
        }));
        return rowData;
      };

      const prevData = formattedData(get(payload, 'prevData', []));
      const groups = formattedData(get(payload, 'groups', []));

      const getModifiedDrivers = (prevDrivers, updatedDrivers) => {
        return updatedDrivers
          .filter((driver) => driver.type)
          .map((driver) => {
            if (driver.type === 'UPDATE_DRIVER') {
              const originalDriver = prevDrivers.find(
                (r) => r.id === driver.id
              );
              if (!originalDriver) return driver;
              const modifiedStores = driver.stores.filter(
                (store) => store.type
              );
              const storesChanged =
                modifiedStores.length > 0 ||
                driver.stores.length !== originalDriver.stores.length;
              return {
                ...driver,
                stores: storesChanged ? modifiedStores : [],
              };
            }
            return driver;
          });
      };

      const transformData = (drivers) => {
        const rows = drivers.map((driver) => {
          let requestedObj = {};
          requestedObj = {
            name: driver.name,
            actualData: driver.actualData,
            type: driver.type,
            stores: driver.stores.map((store) => {
              const values = Object.keys(store)
                .filter((key) => key.startsWith('wd-'))
                .sort(
                  (a, b) =>
                    parseInt(a.split('-')[1]) - parseInt(b.split('-')[1])
                )
                .map((key) => parseFloat(store[key]));
              let requestedStore = {};
              requestedStore = {
                storeId: store.storeId,
                type: store.type,
                values: values,
              };
              return requestedStore;
            }),
          };
          if (driver.type !== 'NEW_DRIVER') {
            requestedObj._id = driver.id;
          }
          return requestedObj;
        });
        return rows;
      };

      const modifiedData = getModifiedDrivers(prevData, groups);
      if (modifiedData.length === 0) {
        formattedPayload = null;
      } else {
        formattedPayload = {
          driver: {
            groups: transformData(modifiedData),
          },
        };
      }
    }

    if (stepName === 'calendar') {
      const calendarData = {
        addCalendars: [],
        updateCalendars: [],
        deleteCalendars: [],
      };

      const formattedData = (arr) => {
        const rowData = arr.map((item) => {
          return {
            ...item,
            inputType: '',
            cellType: '',
          };
        });
        return rowData;
      };

      formattedData(get(payload, 'data', [])).forEach((row) => {
        const matchingPrev = formattedData(get(payload, 'prevData', [])).find(
          (prev) => prev.id === row.id
        );
        if (matchingPrev) {
          if (!isEqual(row, matchingPrev)) {
            calendarData.updateCalendars.push(row);
          }
        } else {
          calendarData.addCalendars.push(row);
        }
      });

      formattedData(get(payload, 'prevData', [])).forEach((prev) => {
        const matchingRow = formattedData(get(payload, 'data', [])).find(
          (row) => row.id === prev.id
        );

        if (!matchingRow) {
          calendarData.deleteCalendars.push(prev.id);
        }
      });

      const transformmedData = (data, isUpdate) => {
        const rows = data.map((item) => {
          const { name, type, id, ...rest } = item;
          const values = Object.keys(rest)
            .filter((key) => key.startsWith('wd-'))
            .sort(
              (a, b) => parseInt(a.split('-')[1]) - parseInt(b.split('-')[1])
            )
            .map((key) =>
              type === 'boolean' ? rest[key].value : rest[key].toString()
            );
          const requestedObj = {
            name,
            type,
            values,
          };
          if (isUpdate) {
            requestedObj._id = id;
          }
          return requestedObj;
        });
        return rows;
      };

      if (
        calendarData.addCalendars.length === 0 &&
        calendarData.updateCalendars.length === 0 &&
        calendarData.deleteCalendars.length === 0
      ) {
        formattedPayload = null;
      } else {
        formattedPayload = {
          calendar: {
            addCalendars: transformmedData(calendarData.addCalendars, false),
            updateCalendars: transformmedData(
              calendarData.updateCalendars,
              true
            ),
            deleteCalendars: calendarData.deleteCalendars,
          },
        };
      }
    }
    if (stepName === 'calculation') {
      let payload = formData.calculation.groups.map(
        ({ _id, error, taskOptions, fold, rows, ...rest }) => ({
          ...rest,
          rows: rows.map(({ _id, error, formula, ...rowRest }) => ({
            ...rowRest,
            formula: formula
              ? {
                  ...Object.fromEntries(
                    Object.entries(formula).filter(
                      ([key, value]) => key !== '_id' && key !== 'output'
                    )
                  ),
                  ...(formula.variable && {
                    variable: formula.variable.map(
                      ({ output, _id, source, variable, ...varItem }) => ({
                        ...varItem,
                        ...(source && {
                          source: {
                            ...source,
                            path: source.path.map((pathItem) =>
                              typeof pathItem === 'object' && pathItem.value
                                ? pathItem.value
                                : pathItem
                            ),
                          },
                        }),
                        ...(variable && {
                          variable: variable.map(
                            ({
                              output,
                              _id,
                              source,
                              variable,
                              ...childVarItem
                            }) => ({
                              ...childVarItem,
                              ...(source && {
                                source: {
                                  ...source,
                                  path: source.path.map((childPathItem) =>
                                    typeof childPathItem === 'object' &&
                                    childPathItem.value
                                      ? childPathItem.value
                                      : childPathItem
                                  ),
                                },
                              }),
                              ...(variable && {
                                variable: variable.map(
                                  ({
                                    output,
                                    _id,
                                    source,
                                    ...subChildVarItem
                                  }) => ({
                                    ...subChildVarItem,
                                    ...(source && {
                                      source: {
                                        ...source,
                                        path: source.path.map(
                                          (subChildPathItem) =>
                                            typeof subChildPathItem ===
                                              'object' && subChildPathItem.value
                                              ? subChildPathItem.value
                                              : subChildPathItem
                                        ),
                                      },
                                    }),
                                  })
                                ),
                              }),
                            })
                          ),
                        }),
                      })
                    ),
                  }),
                  ...(formula.source && {
                    source: {
                      ...formula.source,
                      path: formula.source.path.map((pathItem) =>
                        typeof pathItem === 'object' && pathItem.value
                          ? pathItem.value
                          : pathItem
                      ),
                    },
                  }),
                }
              : undefined,
          })),
        })
      );

      formattedPayload = {
        calculation: {
          groups: payload,
        },
      };
    }
    return formattedPayload;
  };

  const onNext = () => {
    const updatedSteps = modelSteps.map((step, index) => {
      if (index === activeStep) {
        return { ...step, status: 'completed' };
      }
      if (index === activeStep + 1) {
        return { ...step, status: 'inProgress' };
      }
      if (activeStep === modelSteps.length - 1) {
        return { ...step, status: 'inProgress' };
      }
      return step;
    });
    setModelSteps(updatedSteps);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (modelId) {
      fetchStepsInfo();
    }
  };

  const handleAddModelStep = (payloadData, stepName, saveDraft, isPrevious) => {
    const validateErrors = validateFormData(
      payloadData,
      validationRules[stepName]
    );
    const formattedPayload = getPayload(payloadData, stepName);
    if (validateErrors && validateErrors.length > 0) {
      showNotification('error', validateErrors[0]);
      return;
    }
    if (isPrevious && formattedPayload.calculation.groups.length === 0) {
      handleBack();
      return;
    }
    if (formattedPayload) {
      setLoading(true);
      dispatch(
        addModel({
          url: '/models/steps',
          method: 'POST',
          navigate,
          data: {
            ...(modelId ? { modelId: modelId || '' } : {}),
            data: formattedPayload,
          },
          success: (res) => {
            if (stepName === 'globalSettings') {
              setModelId(res.data.model._id);
              setTimeout(() => {
                setCompletedSteps(res.data.fields);
              }, 1500);
            }
            if (saveDraft) {
              if (isPrevious) {
                handleBack();
              } else {
                handleClose();
                if (!params.id) {
                  showNotification('success', res?.message);
                } else {
                  showNotification('success', 'Model updated successfully');
                }
              }
            } else {
              if (activeStep < 7) {
                onNext();
              } else {
                showNotification(
                  'success',
                  `Model ${params.id ? 'Updated' : 'Added'} successfully`
                );
              }
            }
            setLoading(false);
          },
          fail: (err) => {
            console.log('err', err);
            const errorMessage = err.message || 'Something went wrong.';
            showNotification('error', errorMessage);
            setLoading(false);
          },
        })
      );
      if(activeStep === modelSteps.length - 1){
        let summaryPayload = {
          model: modelId,
          roles: formData.calculation.workHourSummary.roles?.map((role) => ({
            _id: role._id,
            hoursSummery: {
              type: role.hoursSummery.type,
              ...(role.hoursSummery.hours ? {hours: parseInt(role.hoursSummery.hours)} : {})
            }
          }))
        }
        dispatch(
          updateHours({
            url: '/roles/updatehours',
            method: 'PUT',
            navigate,
            data: summaryPayload,
            success: (res) => {
              setLoading(false);
            },
            fail: (err) => {
              console.log('err', err);
              const errorMessage = err.message || 'Something went wrong.';
              showNotification('error', errorMessage);
              setLoading(false);
            },
          })
        );
      }
    } else {
      if (saveDraft) {
        handleClose();
      } else {
        onNext();
      }
    }
  };

  const handleNext = (saveDraft, isPrevious) => {
    const stepName = steps[activeStep]?.key;
    const valuesToValidate = formData[stepName] || {};
    handleAddModelStep(valuesToValidate, stepName, saveDraft, isPrevious);
  };

  const handleCloseCancelAlert = () => {
    setIsCancelAlert(false);
  };

  const handleClosePreviousAlert = () => {
    setIsPrevious(false);
  };

  const handleBack = () => {
    const updatedSteps = modelSteps.map((step, index) => {
      if (index === activeStep) {
        return { ...step, status: 'pending' };
      }
      if (index === activeStep - 1) {
        return { ...step, status: 'inProgress' };
      }
      return step;
    });
    setModelSteps(updatedSteps);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleClosePreviousAlert();
  };

  const handleClose = () => {
    navigate('/models');
    setActiveStep(0);
  };

  const handleDirectToStep = (step) => {
    const updatedSteps = modelSteps.map((item, index) => {
      if (index < step.id - 1) {
        return { ...item, status: 'completed' };
      }
      if (index === step.id - 1) {
        return { ...item, status: 'inProgress' };
      }
      return { ...item, status: 'pending' };
    });
    setModelSteps(updatedSteps);
    setActiveStep(step.id - 1);
    handleClosePreviousAlert();
    setStep(null);
  };

  const onBack = () => {
    navigate(-1);
    setIsCancelAlert(false);
  };

  const cancelActions = [
    { title: 'Continue', variant: 'outlined', onClick: handleCloseCancelAlert },
    {
      title: 'Cancel changes',
      variant: 'contained',
      onClick: onBack,
      color: 'danger',
    },
  ];

  const previousActions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleClosePreviousAlert },
    {
      title: 'Previous',
      variant: 'contained',
      onClick: () =>
        stepperStep ? handleDirectToStep(stepperStep) : handleBack(),
    },
  ];

  const handlePreviousClick = (step, type) => {
    if (activeStep === 7) {
      handleNext(true, true);
    } else {
      const stepName = steps[activeStep]?.key;
      const valuesToValidate = formData[stepName] || {};
      const formattedPayload = getPayload(valuesToValidate, stepName);
      if (formattedPayload) {
        if (type === 'stepperClick') {
          setStep(step);
        }
        setIsPrevious(true);
      } else {
        if (step) {
          handleDirectToStep(step);
        } else {
          handleBack();
        }
      }
    }
  };

  const handleMoveToStep = (step) => {
    handlePreviousClick(step, 'stepperClick');
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Grid container className="model-container" spacing={3} pr={0}>
          <Grid item className="model-wrapper">
            <VerticalStepper
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={modelSteps}
              expandMenu={expandMenu}
              setExpandMenu={setExpandMenu}
              handleMoveToStep={handleMoveToStep}
            />
          </Grid>
          <Grid
            item
            className="model-step-container"
            //  style={{maxWidth: `${expandMenu ? 'calc(100% - 316px)' : 'calc(100% - 112px)'}`}}
          >
            <div className="model-step-wrapper">
              {steps[activeStep]?.component}
            </div>

            <div>
              <div className="flex-container model-step-footer">
                <div className="flex-container" style={{ gap: '10px' }}>
                  <ThemeButton
                    text="Save Draft"
                    onClick={() => handleNext(true)}
                    variant="outlined"
                  />
                  <ThemeButton
                    text="Leave"
                    onClick={() => setIsCancelAlert(true)}
                    variant="contained"
                    color="danger"
                  />
                </div>
                <div className="flex-container" style={{ gap: '10px' }}>
                  {activeStep > 0 && (
                    <ThemeButton
                      text="Previous"
                      onClick={() => handlePreviousClick()}
                      variant="text"
                    />
                  )}

                  {activeStep < 7 && (
                    <ThemeButton
                      text={`${loading ? '...' : 'Next step'}`}
                      onClick={() => handleNext(false)}
                      variant="contained"
                      disabled={loading}
                    />
                  )}
                  {activeStep === 7 && (
                    <ThemeButton
                      text={editMode ? 'Save changes' : 'New model'}
                      onClick={() => handleNext(false)}
                      variant="contained"
                    />
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      <CustomDialogBox
        open={isCancelAlert}
        onClose={handleCloseCancelAlert}
        title="Cancel Changes?"
        description="Are you sure you want to leave? Please note, any progress made so far will be lost."
        actions={cancelActions}
      />
      <CustomDialogBox
        open={isPrevious}
        onClose={handleClosePreviousAlert}
        title="Go Back?"
        description="Are you sure you want to go back? Please note, any unsaved changes will be lost."
        actions={previousActions}
      />
    </>
  );
};

AddEditModel.propTypes = {
  component: PropTypes.element.isRequired,
};
export default AddEditModel;
