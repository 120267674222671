import React from 'react';
import typography from '../../../../theme/typography';
import CustomTypography from '../../../../components/CustomTypography';
import TextInput from '../../../../components/TextInput';

const RoleSummary = ({ key, role, roleIndex, workHourSummaryState }) => {
    const [workHourSummary, setWorkHourSummary] = workHourSummaryState;

    const handleChangeInput = (hours) => {
        let data = workHourSummary;
        data.roles[roleIndex].hoursSummery = {
            ...data.roles[roleIndex].hoursSummery,
            hours,
        };
        setWorkHourSummary({...data});
    };

    const handleChangeType = (type) => {
        let data = workHourSummary;
        data.roles[roleIndex].hoursSummery = {
            type,
        };
        setWorkHourSummary({...data});
    };

    return (
        <div key={key} className="role-row">
            <CustomTypography text={role?.name} style={{ ...typography.subtitle2 }} />
            <form noValidate>
                <div>
                    <input
                        type='radio'
                        id={`${key}_fixed`}
                        name="roles"
                        value="FIXED"
                        checked={role.hoursSummery.type === 'FIXED'}
                        onChange={(e) => handleChangeType(e.target.value)}
                    />
                    <label htmlFor={`${key}_fixed`}>Fixed</label>
                </div>
                <div>
                    <input
                        type='radio'
                        id={`${key}_variable`}
                        name="roles"
                        value="VARIABLE"
                        checked={role.hoursSummery.type === 'VARIABLE'}
                        onChange={(e) => handleChangeType(e.target.value)}
                    />
                    <label htmlFor={`${key}_variable`}>Variable</label>
                </div>
                <div>
                    <input
                        type='radio'
                        id={`${key}_input`}
                        name="roles"
                        value="INPUT"
                        checked={role.hoursSummery.type === 'INPUT'}
                        onChange={(e) => handleChangeType(e.target.value)}
                    />
                    <label htmlFor={`${key}_input`}>Free</label>
                </div>
            </form>
            {
                role.hoursSummery.type === 'INPUT' &&
                <TextInput
                    type="number"
                    placeholder="Enter Hours"
                    sizeSmall
                    variant="outlined"
                    onInputChange={(e) => handleChangeInput(e.target.value)}
                    onKeyDown={(e) => handleChangeInput(e.target.value)}
                    value={role.hoursSummery.hours}
                />
            }
        </div>
    );
};

export default RoleSummary;
