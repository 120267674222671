import React, { useEffect, useState } from 'react';
import CustomTypography from '../../../../components/CustomTypography';
import ThemeButton from '../../../../components/ThemeButton';
import importIcon from '../../../../assets/images/common/importIcon.svg';
import plusCircle from '../../../../assets/images/common/plusCircle.svg';
import minusCircle from '../../../../assets/images/common/minusCircleSmall.svg';
import trash from '../../../../assets/images/common/trash.svg';
import edit from '../../../../assets/images/common/edit.svg';
import CustomTooltip from '../../../../components/CustomTooltip';
import '../Roles/styles.scss';
import CustomDialogBox from '../../../../components/CustomDialogBox';
import { getDrivers, getHierarchy } from '../../../../redux/slice/model';
import { useDispatch } from 'react-redux';
import EditableTable from '../../../../components/DataGrid/EditableTable';
import { capitalize, get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ActionModal from '../../../../components/ActionModal';
import AddDriver from './AddDriver';
import { required, validateField } from '../../../../utils/Validator';
import { generateUniqueId, getDatesInRange, getWeeklyDetails, parseSlashDate, validateInput } from '../../../../utils/helper';
import ChangeRelative from './ChangeRelative';
import UploadFiles from '../../../../components/UploadFiles';
import { parse } from 'papaparse';
import CustomCheckbox from '../../../../components/CustomCheckbox';
import driverTemplate from '../../../../assets/templates/driver-template.csv';
import driverExImg from '../../../../assets/images/common/driverExImg.png';
import typography from '../../../../theme/typography';
import TableSkeleton from '../../../../components/DataGrid/TableSkeleton';
import moment from 'moment';

const Drivers = ({
  modelId,
  formData,
  setFormData,
  completedSteps,
  modelData,
}) => {
  const [drivers, setDrivers] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [driverId, setDriverId] = useState();
  const [isAddDriverModal, setIsAddDriverModal] = useState(false);
  const [isCRModal, setIsCRModal] = useState(false);
  const [isDeleteStoreAlert, setIsDeleteStoreAlert] = useState(false);
  const [storeId, setStoreId] = useState();
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [changeRelativeData, setChangeRelativeData] = useState({
    applyFrom: 'all',
  });
  const [driverList, setDriverList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [allStores, setAllStores] = useState([]);
  const [errors, setErrors] = useState({});
  const [prevData, setPrevData] = useState([]);
  const [storeCR, setStoreCR] = useState(false);
  const [storeRow, setStoreRow] = useState(false);
  const [isEditDriver, setIsEditDriver] = useState(false);
  const [isUploadModal, setUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newDriver, setNewDriver] = useState({
    name: '',
    stores: [],
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const budgetType = get(modelData, 'budgetType');
  const budgetStartDate = get(modelData, 'budgetStartDate');
  const budgetEndDate = get(modelData, 'budgetEndDate');
  const modelWeeks = get(modelData, 'numberOfSplit');
  const isStepCompleted = get(completedSteps, 'driver', false);
  const startDay = get(modelData, 'weekStartDay');
  const weekDetails = getWeeklyDetails(new Date(budgetStartDate.slice(0, 10)), budgetEndDate, startDay);
  const dailyDetails = getDatesInRange(new Date(budgetStartDate.slice(0, 10)), budgetEndDate);

  const validationRules = [
    required('Input is required'),
    validateInput('Input is required'),
  ];

  const duplicateDriverRule =
    (message, isEditDriver = false, currentDriverId = null) =>
    (value) => {
      const driverList = [...drivers]
        .filter(
          (item) =>
            item.type !== 'DELETE_DRIVER' &&
            (!isEditDriver || item.id !== currentDriverId)
        )
        .map((r) => r.name);
      if (driverList.some((d) => value?.toLowerCase() === d?.toLowerCase())) {
        return message;
      }
    };

  useEffect(() => {
    setFormData({
      ...formData,
      drivers: {
        groups: [...drivers],
        prevData: prevData,
      },
    });
  }, [drivers, prevData]);

  useEffect(() => {
    const list = drivers
      .filter((item) => item.type !== 'DELETE_DRIVER')
      .map((d) => {
        return {
          label: d.name,
          value: d.id,
        };
      });
    setDriverList([...list]);
  }, [drivers]);

  const fetchStores = () => {
    setIsLoading(true);
    dispatch(
      getHierarchy({
        url: `/hierarchies/get/${modelId}`,
        method: 'GET',
        navigate,
        success: (res) => {
          if (get(res, 'data.stores') && get(res, 'data.stores').length > 0) {
            const rows = res.data.stores;
            const stores = rows.map(({ no, name, _id }) => ({
              label: `${name} (${no})`,
              value: _id,
            }));
            const storeData = rows.map(({ no, name, _id }) => ({
              storeId: _id,
              no,
              name,
            }));
            setStoreList(stores);
            setAllStores(storeData);
          } else {
            setIsLoading(false);
          }
        },
        fail: (err) => {
          console.log('err stores', err);
          setIsLoading(false);
        },
      })
    );
  };

  const fetchDrivers = () => {
    dispatch(
      getDrivers({
        url: `/drivers/getBymodelId/${modelId}`,
        method: 'GET',
        navigate,
        success: (res) => {
          if (get(res, 'data.groups') && get(res, 'data.groups').length > 0) {
            const driversData = get(res, 'data.groups', []);
            const rows = driversData.map((item) => {
              const { _id, name, actualData, stores } = item;
              return {
                id: _id,
                name,
                actualData,
                stores: stores.map((st) => {
                  const { _id, storeId, values } = st;
                  let transformedStore = {
                    id: _id,
                    name:
                      allStores.find((store) => store.storeId === storeId)
                        ?.name || '',
                    no:
                      allStores.find((store) => store.storeId === storeId)
                        ?.no || '',
                    storeId,
                    inputType: 'input',
                  };
                  values.forEach((value, index) => {
                    transformedStore[`wd-${index + 1}`] = value;
                  });
                  return transformedStore;
                }),
              };
            });
            setDrivers([...rows]);
            setPrevData(JSON.parse(JSON.stringify(rows)));
          }
          setIsLoading(false);
        },
        fail: (err) => {
          console.log('driver err', err);
          setIsLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    if (isStepCompleted) {
      fetchDrivers();
    } else {
      setIsLoading(false);
    }
  }, [isStepCompleted, allStores]);

  const getStoreId = (no, name) => {
    const store = allStores.find(
      (store) =>
        store.no === no &&
        store.name === name
    );
    return store ? store.storeId : null;
  };

  const isStoreMatched = (no, name) => {
    return allStores.some(
      (store) =>
        store.no === no &&
        store.name === name
    );
  };

  const handleImportData = () => {
    if (!uploadedFile) return;
    parse(uploadedFile, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const parsedData = results.data;
        const existingDriversMap = new Map();
        drivers.forEach((driver) => {
          existingDriversMap.set(driver.name, driver);
        });
        let currentDriver = null;
        parsedData.forEach((row) => {
          const { driverName, no, name, ...rest } = row;
          if (driverName) {
            currentDriver = existingDriversMap.get(driverName);
            if (currentDriver) {
              if (currentDriver.type !== 'NEW_DRIVER') {
                currentDriver.type = 'UPDATE_DRIVER';
              }
            } else {
              currentDriver = {
                id: generateUniqueId(),
                name: driverName,
                type: 'NEW_DRIVER',
                actualData: false,
                stores: [],
                showDetails: true,
              };
              drivers.push(currentDriver);
              existingDriversMap.set(driverName, currentDriver);
            }
          }

          if (!currentDriver) return;
          if (no && name && isStoreMatched(no, name)) {
            const storeId = getStoreId(no, name);
            if (!storeId) return;

            const existingStore = currentDriver.stores.find(
              (store) =>
                store.no === parseInt(no, 10) &&
                store.name.toLowerCase() === name.toLowerCase()
            );

            if (existingStore) {
              if (existingStore.type !== 'NEW_STORE') {
                existingStore.type = 'UPDATE_STORE';
              }
              Object.keys(rest).forEach((key) => {
                if (key.startsWith('wd-')) {
                  existingStore[key] = rest[key];
                }
              });
            } else {
              const newStore = {
                storeId,
                no: no,
                name,
                id: generateUniqueId(),
                type: 'NEW_STORE',
                ...rest,
              };
              currentDriver.stores.push(newStore);
            }
          }
        });
        setDrivers([...drivers]);
        handleCloseUploadModal();
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
      },
    });
  };

  const addBudgetColumns = () => {
    let extraColumns = [];
    if(budgetType === 'weekly') {
      weekDetails.forEach((item, i)=>{
        extraColumns.push({
          id: `wd-${i+1}`,
          label: `${i+1}`,
          numeric: true,
          disablePadding: false,
          sortable: true,
          width: '94px',
          type: 'inputOnClick',
          validInputType: 'number',
          end: moment(parseSlashDate(item.endDate)).format('D MMM, YYYY'),
          start: moment(parseSlashDate(item.startDate)).format('D-'),
        });
      })
    } else{
      dailyDetails.forEach((item, i)=>{
        extraColumns.push({
          id: `wd-${i+1}`,
          label: `${i+1}`,
          numeric: true,
          disablePadding: false,
          sortable: true,
          width: '94px',
          type: 'inputOnClick',
          validInputType: 'number',
          end: item,
        });
      })
    }
    setUpdatedColumns([...columns, ...extraColumns]);
  };

  useEffect(() => {
    addBudgetColumns();
  }, [budgetType]);

  const handleCloseAddDriverModal = () => {
    setIsAddDriverModal(false);
    setIsEditDriver(false);
    setNewDriver({
      name: '',
      stores: [],
    });
    setErrors({});
  };

  const handleOpenAddDriverModal = () => {
    setIsAddDriverModal(true);
    setNewDriver({
      ...newDriver,
      stores: storeList,
    });
  };

  const handleOpenCRModal = () => {
    setIsCRModal(true);
  };

  const handleCloseCRModal = () => {
    setIsCRModal(false);
    setChangeRelativeData({ applyFrom: 'all' });
    setStoreCR(false);
    setErrors({});
  };

  const handleCloseUploadModal = (event, reason) => {
    if (reason === 'backdropClick') return;
    else {
      setUploadModal(false);
      setUploadedFile(null);
    }
  };

  const onDelete = (id) => {
    setDriverId(id);
    setIsAlert(true);
  };

  const handleClose = () => {
    setIsAlert(false);
  };

  const getUploadedFile = (file) => {
    setUploadedFile(file);
  };

  const handleDeleteRole = () => {
    const updatedDrivers = drivers
      .filter((item) => item.type !== 'NEW_DRIVER' || item.id !== driverId)
      .map((item) => {
        if (item.id === driverId && item.type !== 'NEW_DRIVER') {
          return {
            ...item,
            type: 'DELETE_DRIVER',
            stores: item.stores.map((st) => {
              return {
                ...st,
                type: 'DELETE_STORE',
              };
            }),
          };
        }
        return item;
      });
    setDrivers([...updatedDrivers]);
    handleClose();
  };

  const onExpandDriver = (id) => {
    setIsLoading(true);
    const driverData = drivers.map((driver) =>
      driver.id === id
        ? { ...driver, showDetails: !driver.showDetails }
        : { ...driver, showDetails: false }
    )
    setDrivers([...driverData]);
    setIsLoading(false);
  };

  const handleChange = (id, name, val) => {
    setDrivers(
      drivers.map((driver) =>
        driver.id === id
          ? {
              ...driver,
              [name]: val,
              type:
                driver.type === 'NEW_DRIVER' ? driver.type : 'UPDATE_DRIVER',
            }
          : driver
      )
    );
  };

  const handleUpdate = (driver) => {
    setIsEditDriver(true);
    setIsAddDriverModal(true);
    const { name, id, stores } = driver;
    const driverDetails = stores.map((st) => {
      return {
        label: `${st.name} (${st.no})`,
        value: st.storeId,
        id: id,
        type: st.type,
      };
    });
    const filteredStores = storeList.filter((store) =>
      driverDetails
        .filter((st) => st.type !== 'DELETE_STORE')
        .some((newStores) => newStores.value === store.value)
    );
    const editDriver = {
      id,
      name,
      stores: filteredStores,
    };
    setNewDriver({ ...editDriver });
  };

  const handleUpdateDetails = (driverId) => {
    // const rows = updatedDetails
    //   ? JSON.parse(JSON.stringify(updatedDetails))
    //   : [];
    setDrivers(
      drivers.map((driver) =>
        driver.id === driverId
          ? {
              ...driver,
              type:
                driver.type === 'NEW_DRIVER' ? 'NEW_DRIVER' : 'UPDATE_DRIVER',
              stores: [...driver.stores],
            }
          : driver
      )
    );
  };

  const handleSaveDriver = () => {
    let addDriverRules = {};
    const errorMsg = 'Driver with same name already exists';
    if (isEditDriver) {
      addDriverRules.name = [
        ...validationRules,
        duplicateDriverRule(errorMsg, true, newDriver.id),
      ];
    } else {
      addDriverRules.name = [...validationRules, duplicateDriverRule(errorMsg)];
    }
    if (!validateField(newDriver, addDriverRules, setErrors)) return;

    const budgetCount = updatedColumns.length;

    const addDefaultBudgetFields = (store) => {
      const budgetFields = {};
      for (let i = 1; i <= budgetCount; i++) {
        budgetFields[`wd-${i}`] = '0.00';
      }
      return { ...store, ...budgetFields };
    };

    const filteredRows = allStores.filter((store) =>
      newDriver.stores.some((newStore) => newStore.value === store.storeId)
    );

    const driverDetails = (driverId) => {
      const prevDrivers = prevData.find((driver) => driver.id === driverId);
      const prevStores = prevDrivers ? prevDrivers.stores : [];
      return [
        ...filteredRows.map((item) => {
          const existingStore = prevStores.find(
            (store) => store.storeId === item.storeId
          );
          if (existingStore) {
            return addDefaultBudgetFields({
              ...existingStore,
              inputType: 'input',
            });
          } else {
            return addDefaultBudgetFields({
              ...item,
              id: generateUniqueId(),
              type: 'NEW_STORE',
              inputType: 'input',
            });
          }
        }),
        ...prevStores
          .filter(
            (prevStore) =>
              !filteredRows.some((store) => store.storeId === prevStore.storeId)
          )
          .map((deletedStore) => ({
            ...deletedStore,
            type: 'DELETE_STORE',
          })),
      ];
    };
    if (isEditDriver) {
      const allDrivers = [...drivers];
      const driverToEdit = allDrivers.map((driver) => {
        if (driver.id === newDriver.id) {
          if (prevData.some((r) => r.id === driver.id)) {
            return {
              ...driver,
              name: newDriver.name,
              type: 'UPDATE_DRIVER',
              stores: driverDetails(driver.id)
                ? JSON.parse(JSON.stringify(driverDetails(driver.id)))
                : [],
            };
          } else {
            return {
              ...driver,
              name: newDriver.name,
              stores: driverDetails(driver.id)
                ? JSON.parse(JSON.stringify(driverDetails(driver.id)))
                : [],
            };
          }
        } else {
          return { ...driver };
        }
      });

      setDrivers([...driverToEdit]);
    } else {
      const newRow = {
        id: generateUniqueId(),
        name: newDriver.name,
        type: 'NEW_DRIVER',
        actualData: false,
        stores: driverDetails()
          ? JSON.parse(JSON.stringify(driverDetails()))
          : [],
      };
      setDrivers((prevRoles) => [...prevRoles, newRow]);
    }
    setIsAddDriverModal(false);
    handleCloseAddDriverModal();
  };

  const applyPercentageChange = (value, percentage) => {
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) return value;

    const changeAmount = (numericValue * Math.abs(percentage)) / 100;
    if (percentage > 0) {
      return (numericValue + changeAmount).toFixed(2);
    } else {
      return (numericValue - changeAmount).toFixed(2);
    }
  };

  const handleApplyCR = () => {
    const applyCRRules = {
      drivers: validationRules,
      calculationVal: validationRules,
    };
    if (!validateField(changeRelativeData, applyCRRules, setErrors)) return;
    const {
      drivers: targetDrivers,
      calculationVal,
      applyFrom,
    } = changeRelativeData;

    const targetDriverIds = new Set(
      targetDrivers.map((driver) => driver.value)
    );
    const updatedDrivers = drivers.map((driver) => {
      if (targetDriverIds.has(driver.id)) {
        const updatedStores = driver.stores.map((store) => {
          const updatedStore = {
            ...store,
            type: store.type === 'NEW_STORE' ? store.type : 'UPDATE_STORE',
          };

          Object.keys(store).forEach((key) => {
            if (key.startsWith('wd-')) {
              const budgetWeek = parseInt(key.split('-')[1]);

              if (budgetWeek >= applyFrom || applyFrom === 'all') {
                updatedStore[key] = applyPercentageChange(
                  store[key],
                  calculationVal
                );
              }
            }
          });

          return updatedStore;
        });

        return {
          ...driver,
          type: driver.type === 'NEW_DRIVER' ? driver.type : 'UPDATE_DRIVER',
          stores: updatedStores,
        };
      }
      return driver;
    });
    setDrivers([...updatedDrivers]);
    handleCloseCRModal();
  };

  const handleApplyCRonStore = () => {
    const applyCRRules = {
      calculationVal: validationRules,
    };
    if (!validateField(changeRelativeData, applyCRRules, setErrors)) return;
    const { calculationVal, applyFrom } = changeRelativeData;
    const updatedStore = { ...storeRow };

    Object.keys(storeRow).forEach((key) => {
      if (key.startsWith('wd-')) {
        const budgetWeek = parseInt(key.split('-')[1]);

        if (budgetWeek >= applyFrom || applyFrom === 'all') {
          updatedStore[key] = applyPercentageChange(
            storeRow[key],
            calculationVal
          );
        }
      }
    });

    const updatedDrivers = drivers.map((driver) => {
      let driverUpdated = false;

      const updatedStores = driver.stores.map((store) => {
        if (store.id === updatedStore.id) {
          driverUpdated = true;
          return {
            ...store,
            ...updatedStore,
            type: store.type === 'NEW_STORE' ? store.type : 'UPDATE_STORE',
          };
        }
        return store;
      });
      const modifiedDriver = {
        ...driver,
        stores: updatedStores,
      };
      if (driverUpdated) {
        modifiedDriver.type =
          driver.type === 'NEW_DRIVER' ? driver.type : 'UPDATE_DRIVER';
      }
      return modifiedDriver;
    });
    setDrivers([...updatedDrivers]);
    handleCloseCRModal();
  };

  useEffect(() => {
    fetchStores();
  }, []);

  const handleCloseStoreAlert = () => {
    setIsDeleteStoreAlert(false);
  };

  const onActionHandle = (index, row, type) => {
    if (type === 'delete') {
      setIsDeleteStoreAlert(true);
      setStoreId(row.id);
    }
    if (type === 'change') {
      handleOpenCRModal();
      setStoreCR(true);
      setStoreRow(row);
    }
  };

  const handleDeleteStore = () => {
    const updatedDrivers = drivers.map((driver) => {
      let hasDeletedStore = false;

      const updatedStores = driver.stores
        .filter((item) => item.type !== 'NEW_STORE' || item.id !== storeId)
        .map((item) => {
          if (item.id === storeId && item.type !== 'NEW_STORE') {
            hasDeletedStore = true;
            return {
              ...item,
              type: 'DELETE_STORE',
            };
          }
          return item;
        });

      if (hasDeletedStore) {
        return {
          ...driver,
          stores: updatedStores,
          type: driver.type !== 'NEW_DRIVER' ? 'UPDATE_DRIVER' : driver.type,
        };
      } else {
        return {
          ...driver,
          stores: updatedStores,
        };
      }
    });

    setDrivers([...updatedDrivers]);
    handleCloseStoreAlert();
  };

  const columns = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'NAME',
      sortable: true,
      searchFilter: true,
      valueType: 'array',
      filterType: 'checkbox',
      width: '100px',
    },
    {
      id: 'no',
      numeric: false,
      disablePadding: false,
      label: 'STORE NO',
      sortable: true,
      filterType: 'checkbox',
      width: '90px',
    },
    {
      id: 'button',
      numeric: true,
      disablePadding: false,
      label: '',
      sortable: false,
      type: 'buttons',
      moreOptions: {
        buttonClick: onActionHandle,
        buttons: [{ title: 'Delete' }],
        // { title: 'Change', tooltip: 'Change relative %' }
      },
      width: '5%',
    },
  ];

  const actions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleClose },
    {
      title: 'Delete driver',
      variant: 'contained',
      onClick: handleDeleteRole,
      color: 'danger',
    },
  ];

  const addDriverActionBtns = [
    {
      title: 'Cancel',
      variant: 'outlined',
      onClick: handleCloseAddDriverModal,
    },
    {
      title: 'Save',
      variant: 'contained',
      onClick: handleSaveDriver,
    },
  ];

  const addCRActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseCRModal },
    {
      title: 'Save',
      variant: 'contained',
      onClick: storeCR ? handleApplyCRonStore : handleApplyCR,
    },
  ];

  const deleteStoreActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseStoreAlert },
    {
      title: 'Delete store',
      variant: 'contained',
      onClick: handleDeleteStore,
      color: 'danger',
    },
  ];

  const uploadActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseUploadModal },
    {
      title: 'Import Data',
      variant: 'contained',
      onClick: handleImportData,
      isDisabled: uploadedFile ? false : true,
    },
  ];

  return (
    <>
      {isLoading ? (
        <TableSkeleton rows={drivers.length || 6} columns={3} />
      ) : (
        <div style={{ height: '100%' }}>
          <div className="form-title flex-container">
            <CustomTypography
              variant="headerh3"
              text={`${capitalize(budgetType)} Driver`}
            />
            <div className="d-flex g-8">
              <ThemeButton
                text="Add driver"
                variant="contained"
                onClick={handleOpenAddDriverModal}
              />
              {/* <ThemeButton
            text="% Change relative"
            variant="outlined"
            onClick={handleOpenCRModal}
            isDisabled={drivers.length === 0}
          /> */}
              <ThemeButton
                text="Import Drivers"
                variant="outlined"
                onClick={() => setUploadModal(true)}
                startIcon={<img src={importIcon} alt="importIcon" />}
              />
            </div>
          </div>
          <div className="driver-table">
            {drivers && drivers.length > 0 ? (
              drivers
                .filter((r) => r.type !== 'DELETE_DRIVER')
                .map((driver) => {
                  return (
                    <div key={driver.id} className="role-table-wrapper">
                      <div
                        className="role-container g-8 flex-container"
                        style={{
                          backgroundColor: `${driver.showDetails ? '#E9EDF5' : 'white'}`,
                        }}
                      >
                        <div className="d-flex g-8">
                          <CustomTooltip
                            title="Driver stores"
                            component={
                              <button
                                type="button"
                                className="icon-btn btn-secondary m-0"
                                onClick={() => onExpandDriver(driver.id)}
                              >
                                <img
                                  src={
                                    driver.showDetails
                                      ? minusCircle
                                      : plusCircle
                                  }
                                  alt="expand"
                                />
                              </button>
                            }
                          />
                          <CustomTypography
                            text={driver.name}
                            style={typography.subtitle2}
                          />
                        </div>
                        <div className="d-flex">
                          <CustomCheckbox
                            label="Actual data"
                            name="actualData"
                            checked={driver.actualData}
                            handleChange={(e) =>
                              handleChange(
                                driver.id,
                                e.target.name,
                                e.target.checked
                              )
                            }
                          />
                          <CustomTooltip
                            title="Edit"
                            component={
                              <button
                                type="button"
                                className="icon-btn btn-secondary"
                                onClick={() => handleUpdate(driver)}
                              >
                                <img src={edit} alt="edit" />
                              </button>
                            }
                          />
                          <CustomTooltip
                            title="Delete"
                            component={
                              <button
                                type="button"
                                className="icon-btn btn-secondary"
                                onClick={() => onDelete(driver.id)}
                              >
                                <img src={trash} alt="delete" />
                              </button>
                            }
                          />
                        </div>
                      </div>
                      {driver.showDetails && (
                        <EditableTable
                          columns={updatedColumns}
                          rows={driver.stores.filter(
                            (st) => st.type !== 'DELETE_STORE'
                          )}
                          data={driver.stores}
                          tableOf={['drivers']}
                          showStatusTabs={false}
                          defaultOrderBy="calories"
                          defaultOrder="asc"
                          updateFilteredRows={(updatedDetails) =>
                            handleUpdateDetails(driver.id, updatedDetails.data)
                          }
                          showTableHeader={false}
                          showDateHeader
                          budgetType={budgetType}
                        />
                      )}
                    </div>
                  );
                })
            ) : (
              <CustomTypography
                variant="body1"
                text="Click the button above to add drivers."
                className="no-data-msg"
                style={{ marginTop: '4px', ...typography.subtitle2 }}
              />
            )}
          </div>
          <ActionModal
            open={isAddDriverModal}
            close={handleCloseAddDriverModal}
            component={
              <AddDriver
                newDriver={newDriver}
                setNewDriver={setNewDriver}
                errors={errors}
                storeList={storeList}
              />
            }
            actionButtons={addDriverActionBtns}
            title={`${isEditDriver ? 'Edit' : 'Add new'} Driver`}
            width="40%"
          />
          <ActionModal
            open={isCRModal}
            close={handleCloseCRModal}
            component={
              <ChangeRelative
                changeRelativeData={changeRelativeData}
                setChangeRelativeData={setChangeRelativeData}
                errors={errors}
                driverList={driverList}
                budgetType={budgetType}
                storeCR={storeCR}
                storeRow={storeRow}
                drivers={drivers}
                modelWeeks={modelWeeks}
              />
            }
            actionButtons={addCRActionBtns}
            title="Change Relative Value"
            width="30%"
          />
          <ActionModal
            open={isUploadModal}
            close={handleCloseUploadModal}
            component={
              <UploadFiles
                getUploadedFile={getUploadedFile}
                templateSrc={driverTemplate}
                exampleImg={driverExImg}
                additionalNote={[
                  '1. All fields must contain strictly numerical values, except for the driverName and name fields.',
                  '2. For new drivers, include the driverName field.',
                  '3. If only store details are provided (without driverName), it will be treated as driver assignment data for the stores.',
                  '4. If the budget type is weekly, fill the sheet up to wd-52 or wd-53. If the budget type is daily, fill the entire sheet.',
                ]}
              />
            }
            actionButtons={uploadActionBtns}
            title="Upload Files"
            className="upload-model-wrapper"
          />
          <CustomDialogBox
            open={isAlert}
            onClose={handleClose}
            title="Delete driver"
            description="Are you sure you want to delete this driver?"
            actions={actions}
          />
          <CustomDialogBox
            open={isDeleteStoreAlert}
            onClose={handleCloseStoreAlert}
            title="Delete store"
            description="Are you sure you want to delete this store?"
            actions={deleteStoreActionBtns}
          />
        </div>
      )}
    </>
  );
};

export default Drivers;
