import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ParameterWeeklyCard from './parameterWeeklyCard';
import { capitalize } from 'lodash';
import trash from '../../../../../assets/images/common/trash.svg';
import ParameterDailyCard from './parameterDailyCard';
import CustomDialogBox from '../../../../../components/CustomDialogBox';
import TextInput from '../../../../../components/TextInput';
import '../styles.scss';
import CustomTypography from '../../../../../components/CustomTypography';
import typography from '../../../../../theme/typography';
import ActionModal from '../../../../../components/ActionModal';
import AddWeeklyParameter from './addWeeklyParameter';
import ThemeButton from '../../../../../components/ThemeButton';
import AddDailyParameter from './addDailyParameter';
import { required, validateField } from '../../../../../utils/Validator';
import { generateUniqueId, validateInput } from '../../../../../utils/helper';

const Groups = ({ budgetType, formData, setFormData, daysState }) => {
  const [hovered, setHovered] = useState('');
  const [isAlert, setAlert] = useState(false);
  const [errors, setErrors] = useState({});
  const [deleteGroup, setDeleteGroup] = useState({});
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [allGroupsData, setAllGroupsData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [parameter, setParameter] = useState({});
  const [editParameter, setEditParameter] = useState(null); // State to hold the parameter being edited
  const [days, ] = daysState;

  const duplicateParameterRule = (message) => (value) => {
    const parameterList = filteredGroups.flatMap((group) =>
      group.parameters
        .filter((item) => !editParameter || item.id !== editParameter.id)
        .map((item) => item.parameterName)
    );
    if (parameterList.some((p) => value?.toLowerCase() === p?.toLowerCase())) {
      return message;
    }
  };

  const validationRules = {
    parameterName: [
      required('Parameter name is required'),
      validateInput('Parameter name is required'),
      duplicateParameterRule('Parameter with same name already exists'),
    ],
    selectedGroup: [
      required('Group is required'),
      validateInput('Group is required'),
    ],
    type: [
      required('Value type is required'),
      validateInput('Value type is required'),
    ],
    value: [required('Value is required'), validateInput('Value is required')],
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setErrors({});
    setIsModalOpen(false);
    setEditParameter(null); // Clear edit parameter state when closing modal
    setParameter(null);
    setSelectedGroup(null);
  };

  useEffect(() => {
    setAllGroupsData(formData?.globalSettings?.parameters);
    setFilteredGroups(formData?.globalSettings?.parameters);
  }, [budgetType, formData]);

  const addParameter = () => {
    const isParameterValid = validateField(
      { ...parameter, selectedGroup },
      validationRules,
      setErrors
    );
    const valueErrors =
      parameter.value?.map((val) => (!val ? 'Value is required' : '')) || [];
    if (valueErrors.some((err) => err)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        value: valueErrors,
      }));
    }
    if (!isParameterValid || valueErrors.some((err) => err)) return;
    let updatedParameters = filteredGroups;
    const oldGroup = editParameter?.groupId;
    if (editParameter) {
      if (oldGroup !== selectedGroup) {
        updatedParameters = updatedParameters.map((grp) =>
          grp.id === oldGroup
            ? {
                ...grp,
                parameters: grp.parameters.filter(
                  (param) => param.id !== editParameter.id
                ),
              }
            : grp
        );
      }
    }
    let existingGroupIndex;
    if (updatedParameters && updatedParameters.length > 0) {
      existingGroupIndex = updatedParameters.findIndex(
        (grp) => grp.id === selectedGroup
      );
    } else {
      existingGroupIndex = -1;
    }

    if (existingGroupIndex !== -1) {
      // Group already exists, update its parameters
      const existingGroup = updatedParameters[existingGroupIndex];
      const newGroupParameters =
        oldGroup === selectedGroup && editParameter
          ? existingGroup?.parameters &&
            existingGroup?.parameters.length > 0 &&
            existingGroup?.parameters?.map((param) =>
              param.id === editParameter.id ? { ...parameter } : param
            )
          : [
              ...existingGroup.parameters,
              { id: generateUniqueId(), ...parameter },
            ];
      updatedParameters[existingGroupIndex] = {
        ...existingGroup,
        parameters: newGroupParameters,
      };
    } else {
      updatedParameters.push({
        id: generateUniqueId(),
        groupName: selectedGroup,
        parameters: [{ id: generateUniqueId(), ...parameter }],
      });
      setGroups((prevGroups) => [
        ...prevGroups,
        { label: selectedGroup, value: groups?.length + 1 },
      ]);
    }

    setFilteredGroups(updatedParameters);
    setFormData((prevState) => ({
      ...prevState,
      ...formData,
      globalSettings: {
        ...prevState.globalSettings,
        parameters: updatedParameters,
      },
    }));
    setAllGroupsData(updatedParameters);
    closeModal();
  };

  const deleteParameter = (id) => {
    const updatedParameters = filteredGroups?.map((group) => ({
      ...group,
      parameters: group.parameters.filter((param) => param.id !== id),
    }));
    setFilteredGroups(updatedParameters);
    setFormData((prevState) => ({
      ...prevState,
      ...formData,
      globalSettings: {
        ...prevState.globalSettings,
        parameters: updatedParameters,
      },
    }));
    setAllGroupsData(updatedParameters);
  };

  const editParameterHandler = (parameter, group) => {
    const { id, parameterName, notes, type, value } = parameter || {};
    let updateParameter = {
      id: id,
      groupId: group?.id,
      group: group?.groupName,
      parameterName: parameterName,
      notes: notes,
      type: type,
      value: value,
    };
    setEditParameter(updateParameter);
    setIsModalOpen(true);
  };

  const handleMouseEnter = (id) => {
    setHovered(id);
  };
  const handleMouseLeave = () => {
    setHovered('');
  };
  const handleClose = () => {
    setAlert(false);
  };

  const onDeleteGroup = (group) => {
    setAlert(true);
    setDeleteGroup(group);
  };

  const handleDelete = () => {
    const updatedParameters = filteredGroups?.filter(
      (group) => group.id !== deleteGroup?.id
    );
    const updatedGroups = groups?.filter(
      (group) => group.value !== deleteGroup?.id
    );
    setGroups(updatedGroups);

    setFilteredGroups(updatedParameters);
    setFormData((prevState) => ({
      ...prevState,
      ...formData,
      globalSettings: {
        ...prevState.globalSettings,
        parameters: updatedParameters,
      },
    }));
    setAllGroupsData(updatedParameters);
    setAlert(false);
  };

  const actions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleClose },
    {
      title: 'Delete Group',
      variant: 'contained',
      color: 'danger',
      onClick: handleDelete,
    },
  ];

  const filterParameters = (e) => {
    const searchQuery = e.target.value.toLowerCase();

    const filteredParameters = allGroupsData
      .map((group) => {
        if (group?.groupName?.toLowerCase().includes(searchQuery)) {
          return { ...group };
        } else {
          return {
            ...group,
            parameters: group?.parameters?.filter((parameter) =>
              parameter.parameterName.toLowerCase().includes(searchQuery)
            ),
          };
        }
      })
      .filter(
        (group) =>
          group?.parameters?.length > 0 ||
          group?.groupName?.toLowerCase().includes(searchQuery)
      );
    if (searchQuery) {
      setFilteredGroups(filteredParameters);
    } else {
      setFilteredGroups(allGroupsData);
    }
  };

  const parameterButtons = [
    { title: 'Cancel', variant: 'outlined', onClick: closeModal },
    {
      title: editParameter ? 'Save changes' : 'Add Parameter',
      variant: 'contained',
      onClick: addParameter,
    },
  ];

  return (
    <Box className="pd-16">
      <CustomDialogBox
        open={isAlert}
        onClose={handleClose}
        title="Delete Group"
        description="Are you sure you want to delete this group?"
        actions={actions}
      />
      <div className="flex-container">
        <CustomTypography text="Parameters" style={typography.subtitle2} />
        <div className="d-flex">
          <TextInput
            placeholder="Search Parameter"
            className="text-input-height"
            name="search"
            onInputChange={(e) => filterParameters(e)}
            
          />
          <ThemeButton
            variant="outlined"
            color="primary"
            size="small"
            className="add-parameter-btn"
            onClick={openModal}
            text="New parameter"
            startIcon={<AddRoundedIcon />}
          />
        </div>
      </div>
      {filteredGroups && filteredGroups?.length > 0 ? (
        filteredGroups?.slice()
        .sort((a, b) =>
          a?.groupName?.localeCompare(b.groupName)
        ).map((group) => (
          <Box
            key={group.group}
            className="group-wrapper"
            onMouseEnter={() => handleMouseEnter(group?.group)}
            onMouseLeave={() => handleMouseLeave()}
          >
            <Box className="group-inner-class">
              <CustomTypography
                text={capitalize(group?.groupName)}
                style={typography.semibold}
              />
              {hovered === group?.group && (
                <button
                  type="button"
                  className="icon-btn"
                  onClick={() => onDeleteGroup(group)}
                >
                  <img src={trash} alt="delete" />
                </button>
              )}
            </Box>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {group?.parameters && group?.parameters?.length > 0 ? (
                group?.parameters
                  ?.slice()
                  .sort((a, b) =>
                    a?.parameterName?.localeCompare(b.parameterName)
                  )
                  ?.map((parameter) => (
                    <>
                      {budgetType === '1' ? (
                        <ParameterWeeklyCard
                          key={parameter.id}
                          title={parameter.parameterName}
                          parameter={parameter}
                          value={parameter.value}
                          valueType={parameter.type}
                          notes={parameter.notes}
                          currency={formData?.globalSettings?.currency}
                          onDelete={deleteParameter}
                          onEdit={() => editParameterHandler(parameter, group)}
                        />
                      ) : (
                        <ParameterDailyCard
                          key={parameter.id}
                          title={parameter.parameterName}
                          parameter={parameter}
                          valueType={parameter.type}
                          notes={parameter.notes}
                          value={parameter.value}
                          currency={formData?.globalSettings?.currency}
                          onDelete={deleteParameter}
                          onEdit={() => editParameterHandler(parameter, group)}
                          daysArray={days}
                        />
                      )}
                    </>
                  ))
              ) : (
                <CustomTypography
                  className="pd-md"
                  style={typography.bodyB2}
                  text="No Parameter found"
                />
              )}
            </Grid>
          </Box>
        ))
      ) : (
        <CustomTypography style={typography.bodyB2} text="No parameter found" />
      )}
      {budgetType === '1' ? (
        <ActionModal
          open={isModalOpen}
          close={closeModal}
          component={
            <AddWeeklyParameter
              parameter={parameter}
              setParameter={setParameter}
              allGroupsData={allGroupsData}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              editParameter={editParameter}
              errors={errors}
            />
          }
          title={
            editParameter ? 'Edit global parameter' : 'New global parameter'
          }
          actionButtons={parameterButtons}
        />
      ) : (
        <ActionModal
          open={isModalOpen}
          close={closeModal}
          component={
            <AddDailyParameter
              parameter={parameter}
              setParameter={setParameter}
              allGroupsData={allGroupsData}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              editParameter={editParameter}
              errors={errors}
              daysArray={days}
            />
          }
          title={
            editParameter ? 'Edit global parameter' : 'New global parameter'
          }
          actionButtons={parameterButtons}
        />
      )}
    </Box>
  );
};

export default Groups;
