import React, { useEffect, useState } from 'react';
import EditableTable from '../../../../components/DataGrid/EditableTable';
import refresh from '../../../../assets/images/common/refresh.svg';
import UploadFiles from '../../../../components/UploadFiles';
import ActionModal from '../../../../components/ActionModal';
import * as XLSX from 'xlsx';
import {
  excelDateToJSDate,
  generateUniqueId,
  getCleanedData,
  formatNum,
} from '../../../../utils/helper';
import CustomDialogBox from '../../../../components/CustomDialogBox';
import { parse } from 'papaparse';
import { get } from 'lodash';
import timeStandardTemplate from '../../../../assets/templates/time-standards-template.csv';
import dayjs from 'dayjs';
import moment from 'moment';
import { SMVRules } from '../modelRules';

const TimeStandards = ({ validationRules, setValidationRules, modelData, workgroupState, workgroupIndex }) => {
  const [filteredRows, setFilteredRows] = useState([]);
  // const [searchFilterOpt, setSearchFilterOpt] = useState([])
  const [originalData, setOriginalData] = useState([]);
  // const [resetAllFilters, setResetAllFilters] = useState(false);
  const [isUploadModal, setUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [rowId, setRowId] = useState(null);
  const budgetStartDate = get(modelData, 'budgetStartDate');
  const budgetEndDate = get(modelData, 'budgetEndDate');
  const [workgroups, setWorkgroups] = workgroupState;

  const onActionHandle = (index, row, type) => {
    if (type === 'delete') {
      setRowId(row.id);
      setIsAlert(true);
    }
  };

  const columns = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'TASK',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '80px',
      type: 'inputOnClick',
    },
    {
      id: 'category',
      numeric: false,
      disablePadding: false,
      label: 'CATEGORY',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '70px',
      type: 'inputOnClick',
    },
    {
      id: 'lastUpdated',
      numeric: false,
      disablePadding: false,
      label: 'LAST UPDATED',
      sortable: true,
      searchFilter: true,
      filterType: '',
      width: '90px',
    },
    {
      id: 'basicMinutes',
      numeric: false,
      disablePadding: false,
      label: 'BASIC MINUTES',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      small: true,
      type: 'inputOnClick',
      validInputType: 'number',
      width: '95px',
    },
    {
      id: 'relaxationAllowance',
      numeric: false,
      disablePadding: false,
      label: 'R.A%',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      small: true,
      type: 'inputOnClick',
      validInputType: 'number',
      endAdornment: '%',
      width: '30px',
    },
    {
      id: 'contingencyAllowance',
      numeric: false,
      disablePadding: false,
      label: 'C.A%',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      small: true,
      validInputType: 'number',
      type: 'inputOnClick',
      endAdornment: '%',
      width: '30px',
    },
    {
      id: 'standardMinuteValue',
      numeric: false,
      disablePadding: false,
      label: 'SMV',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      small: true,
      validInputType: 'number',
      type: 'inputOnClick',
      width: '30px',
    },
    {
      id: 'unitOfMeasure',
      numeric: false,
      disablePadding: false,
      label: 'SMV UOM',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      small: true,
      type: 'inputOnClick',
      width: '60px',
    },
    {
      id: 'startDate',
      numeric: false,
      disablePadding: false,
      label: 'START DATE',
      sortable: true,
      searchFilter: true,
      filterType: 'date-picker',
      small: true,
      type: 'date',
      width: '112px',
    },
    {
      id: 'endDate',
      numeric: false,
      disablePadding: false,
      label: 'END DATE',
      sortable: true,
      searchFilter: true,
      filterType: 'date-picker',
      small: true,
      type: 'date',
      width: '112px',
    },
    {
      id: 'button',
      numeric: true,
      disablePadding: false,
      label: '',
      sortable: false,
      type: 'buttons',
      width: '4%',
      moreOptions: {
        buttonClick: onActionHandle,
        buttons: [{ title: 'Delete' }],
      },
    },
  ];

  useEffect(() => {
    setValidationRules({
      ...validationRules,
      timeStandards: SMVRules,
    })
    let rows = workgroups[workgroupIndex].tasksList;
    if(rows && rows.length > 0){
      rows = rows.map((item) => {
        const { _id, basicMinutes, contingencyAllowance, endDate, relaxationAllowance, standardMinuteValue, startDate, updatedAt, ...rest} = item;
        return { 
          ...rest,
          id: _id,
          basicMinutes: formatNum(basicMinutes)?.toString(),
          contingencyAllowance: contingencyAllowance?.toString(),
          relaxationAllowance: relaxationAllowance?.toString(),
          standardMinuteValue: formatNum(standardMinuteValue)?.toString(),
          startDate: startDate ? dayjs(startDate) : null,
          endDate: endDate ? dayjs(endDate) : null,
          inputType: 'input',
          lastUpdated: updatedAt ? moment(updatedAt).format('LLL') : '',
        }
      });
      setOriginalData([...rows]);
      setFilteredRows([...rows]);
      // setPrevData([...rows]);
    }
  }, []);

  // useEffect(() => {
  //   const searchOptions = columns.map((item) => {
  //     let searchObj = {
  //       name: item.id,
  //       type: item.filterType,
  //     };
  //     if (item.filterType === 'date-picker') {
  //       searchObj.keyName = item.id;
  //     } else {
  //       const uniqueOptionsSet = new Set(
  //         originalData.map((d) => d[item.id])
  //       );

  //       const uniqueOptions = [...uniqueOptionsSet].map((value) => ({
  //         label: value?.toString(),
  //         value: value,
  //       }));
  
  //       searchObj.options = uniqueOptions;
  //     }
    
  //     return searchObj;
  //   });
  //   const allSearchData = searchOptions ? searchOptions.filter((item) => item.type !== '')?.slice(0, -1) : [];
  //   setSearchFilterOpt(allSearchData)
  // }, [originalData, columns]); 


  const handleCloseModal = (event, reason) => {
    if(reason === "backdropClick")
    return;  
    else{
      setUploadModal(false);
      setUploadedFile(null);
    }
  };

  const handleClose = () => {
    setIsAlert(false);
  };

  const handleDelete = () => {
    setFilteredRows((prevRows) => prevRows.filter((row) => row.id !== rowId));
    setOriginalData((prevRows) => prevRows.filter((row) => row.id !== rowId));
    const workloadData = workgroups.map((wg)=> ({
      ...wg,
      tasksList: wg.tasksList.filter((task) => task.id || task._id !== rowId)
    }));
    setWorkgroups([...workloadData]);
    handleClose();
  };

  const addStandards = () => {
    const newRow = {
      id: generateUniqueId(),
      name: '',
      basicMinutes: '',
      relaxationAllowance: '',
      contingencyAllowance: '',
      category: '',
      standardMinuteValue: '',
      unitOfMeasure: '',
      startDate: dayjs(budgetStartDate),
      endDate: dayjs(budgetEndDate),
      inputType: 'input',
      lastUpdated: moment(new Date()).format('LLL'),
    };
    let wgData = workgroups;
    wgData[workgroupIndex].tasksList = [
      ...(wgData[workgroupIndex].tasksList ? wgData[workgroupIndex].tasksList : []),
      newRow
    ];
    setWorkgroups([...wgData]);
    setFilteredRows([
      ...filteredRows,
      newRow
    ]);
    setOriginalData([
      ...originalData,
      newRow
    ]);
    // setResetAllFilters(true);
  };

  const headerButtons = [
    {
      text: 'Add Task',
      variant: 'contained',
      color: 'success',
      width: '114px !important',
      onClick: addStandards,
    },
    {
      text: 'Import Tasks',
      variant: 'outlined',
      color: 'success',
      width: '114px !important',
      startIcon: <img src={refresh} alt="refresh" />,
      onClick: () => setUploadModal(true),
    },
  ];

  const updateFilteredRows = (rows) => {
    let data;
    if (rows.data){
      data = rows.data
      if (rows.filterFE){
        setFilteredRows(data);
      } else {
        setOriginalData(rows.data);
      }
    } else if ('searchQuery' in rows){
      const trimmedQuery = rows.searchQuery.trim();
      if (trimmedQuery === "") {
        data = originalData
          } else {
            const filteredQuery = filteredRows.filter((row) =>
              Object.values(row).some(
                (value) =>
                  typeof value === "string" &&
                  value.toLowerCase().includes(trimmedQuery.toLowerCase())
              )
            );
            data = filteredQuery
          }
    } else {
      data = filteredRows
      setOriginalData(originalData);
    }
    setFilteredRows(data);
    // setResetAllFilters(false);
    let workgroupData = workgroups;
    workgroupData[workgroupIndex] = {
      ...workgroups[workgroupIndex],
      tasksList: workgroups[workgroupIndex].tasksList.map((task, i) => {
        const matchingItem = data.filter((item) => item.id || item._id === task.id || task._id)[i];
        if(matchingItem){
          return matchingItem
        } else{
          return task
        }
      })
    }
    setWorkgroups([...workgroupData])
  };

  const getUploadedFile = (file) => {
    setUploadedFile(file);
  };

  const handleImportData = () => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target.result;
      const fileType = uploadedFile.name.split('.').pop().toLowerCase();
      let parsedData;
      let headers;

      if (fileType === 'csv') {
        const csvData = parse(fileContent, { header: true });
        parsedData = csvData.data;
        headers = csvData.meta.fields;
      } else {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });

        headers = worksheet[0];
        parsedData = worksheet.slice(1).map((row) =>
          row.reduce((acc, value, index) => {
            acc[headers[index]] = value;
            return acc;
          }, {})
        );
      }

      const cleanedData = getCleanedData(parsedData)
      let parsedRows = [];
      if (fileType === 'csv') {
        parsedRows = cleanedData.map((row) => ({
          id: generateUniqueId(),
          ...row,
          standardMinuteValue: row.standardMinuteValue ? formatNum(row.standardMinuteValue) : 0,
          basicMinutes: row.basicMinutes ? formatNum(row.basicMinutes) : 0,
          lastUpdated: moment(new Date()).format('LLL'),
          startDate: dayjs(row.startDate, "DD-MM-YYYY"),
          endDate: dayjs(row.endDate, "DD-MM-YYYY"),
          inputType: 'input'
        }));
      } else {
        parsedRows = cleanedData.map((row) => ({
          id: generateUniqueId(),
          ...row,
          startDate: row.startDate ? excelDateToJSDate(row.startDate) : null,
          endDate: row.endDate ? excelDateToJSDate(row.endDate) : null,
          standardMinuteValue: row.standardMinuteValue ? formatNum(row.standardMinuteValue) : 0,
          basicMinutes: row.basicMinutes ? formatNum(row.basicMinutes) : 0,
          lastUpdated: moment(new Date()).format('LLL'),
          inputType: 'input'
        }));
      }
      setFilteredRows(parsedRows);
      setOriginalData(parsedRows);
      let workgroupData = workgroups;
      workgroupData[workgroupIndex] = {
        ...workgroups[workgroupIndex],
        tasksList: parsedRows,
      }
      setWorkgroups([...workgroupData])
    };
    const fileType = uploadedFile.name.split('.').pop().toLowerCase();
    if (fileType === 'csv') {
      reader.readAsText(uploadedFile);
    } else {
      reader.readAsArrayBuffer(uploadedFile);
    }

    handleCloseModal();
  };
  const actions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleClose },
    {
      title: 'Delete standard',
      variant: 'contained',
      onClick: handleDelete,
      color: 'danger',
    },
  ];

  const actionButtons = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseModal },
    {
      title: 'Import Data',
      variant: 'contained',
      onClick: handleImportData,
      isDisabled: uploadedFile ? false : true,
    },
  ];


  return (
    <div className='model_wrapper task_management'>
      {/* <div className="step-header">
        <CustomTypography variant="headerh3" text="SMVs" />
      </div> */}
      <EditableTable
        columns={columns}
        rows={filteredRows}
        data={originalData}
        tableOf={['time-standard']}
        showStatusTabs={false}
        updateFilteredRows={updateFilteredRows}
        headerButtons={headerButtons}
        budgetEndDate={budgetEndDate}
        budgetStartDate={budgetStartDate}
        // searchFilterOpt={searchFilterOpt}
        // resetAllFilters={resetAllFilters}
        // setResetAllFilters={setResetAllFilters}
        // filterFE={true}
      />
      <ActionModal
        open={isUploadModal}
        close={handleCloseModal}
        component={
          <UploadFiles
            getUploadedFile={getUploadedFile}
            templateSrc={timeStandardTemplate}
            additionalNote={[
              '1. Basic Minutes, Relaxation Allowance, Contingency Allowance and Standard MinuteValue should be strictly a number.',
              '2. Start Date and End Date should be in a format of "DD/MM/YYYY"'
            ]}
          />
        }
        actionButtons={actionButtons}
        title="Upload Files"
        className="upload-model-wrapper"
      />
      <CustomDialogBox
        open={isAlert}
        onClose={handleClose}
        title="Delete time standard"
        description="Are you sure you want to delete this time standard?"
        actions={actions}
      />
    </div>
  );
};

export default TimeStandards;