import SelectDropdown from '../../../../components/SelectDropdown';
import typography from '../../../../theme/typography';
import CustomTypography from '../../../../components/CustomTypography';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomCheckbox from '../../../../components/CustomCheckbox';
import TextInput from '../../../../components/TextInput';

const allTypes = [
  { label: 'Workgroup', value: 'WORKLOAD' },
  { label: 'Free', value: 'FREE' },
];

const GroupModel = ({ newGroup, workloads }) => {
  const [newGroupState, setNewGroupState] = newGroup;
  const [workloadOptions, setWorkloadOptions] = useState([]);
  const [workLoadTasks, setWorkLoadTasks] = useState();

  const handleWorkLoadChange = (e) => {
    const grpName = workloadOptions.find(
      (item) => item.value === e.target.value
    ).label;
    setNewGroupState((prev) => ({
      ...prev,
      groupName: grpName,
      workload: e.target.value,
      tasks: []
    }));
  };

  const handleChangeGroupType = (e) => {
    setNewGroupState({ groupName: '', groupType: e.target.value });
  };

  const handleChangeGroupTasks = (e) => {
    if (e.target.checked) {
      setNewGroupState((prev) => ({
        ...prev,
        tasks: prev?.tasks ? [...prev.tasks, e.target.name] : [e.target.name],
      }));
    } else {
      const filteredTasks = newGroupState?.tasks?.filter(
        (item) => item !== e.target.name
      );
      setNewGroupState((prev) => ({ ...prev, tasks: [...filteredTasks] }));
    }
  };

  useEffect(() => {
    if (newGroupState.groupType === 'WORKLOAD' && workloads?.length > 0) {
      const options = workloads?.map((item) => ({
        label: item?.name,
        value: item?._id,
      }));
      setWorkloadOptions([...options]);
    }
  }, [newGroupState.groupType]);

  useEffect(() => {
    if (newGroupState?.workload) {
      const selectedWorkload = workloads.find(
        (item) => item._id === newGroupState?.workload
      );
      setWorkLoadTasks(selectedWorkload?.tasks);
    }
  }, [newGroupState?.workload]);

  return (
    <div className="user-container">
      <div className="user-info-wrapper w-100">
        <div className="user-info user-modal">
          <Box className="group-type-field">
            <CustomTypography text="Group Type" style={typography.subtitle2} />
            <SelectDropdown
              label={'Group Type'}
              width="20%"
              classNameForFormControl="custom-min-width"
              items={allTypes}
              handleChange={(e) => handleChangeGroupType(e)}
              name="group-type"
              value={newGroupState.groupType}
            />
          </Box>
          {newGroupState.groupType === 'WORKLOAD' && (
            <Box className="group-type-field">
              <CustomTypography text="Group Name" style={typography.subtitle2} />
              <SelectDropdown
                label={'Group Name'}
                width="20%"
                classNameForFormControl="custom-min-width"
                items={workloadOptions?.length > 0 ? workloadOptions : [{label: 'No data found', value: 'no-data'}]}
                handleChange={(e) => handleWorkLoadChange(e)}
                name="workloads"
                value={newGroupState.workload}
              />
            </Box>
          )}
          {newGroupState.groupType === 'FREE' ? (
            <TextInput
              label="Group name"
              name="groupName"
              value={newGroupState.groupName}
              //   helperText={errors.companyName}
              onInputChange={(e) => {
                setNewGroupState((prev) => ({
                  ...prev,
                  groupName: e.target.value,
                }));
              }}
              sizeSmall
              disabled={
                newGroupState.groupType === 'WORKLOAD' ||
                !newGroupState.groupType
                  ? true
                  : false
              }
            />
          ) : (
            ''
          )}
        </div>
        {newGroupState.groupType === 'WORKLOAD' && newGroupState.workload && (
          <>
            <CustomTypography text="Tasks" style={typography.subtitle2} />
            <div className="task-checkboxes">
              {workLoadTasks?.map((item) => (
                <CustomCheckbox
                  key={item._id}
                  label={item.name}
                  name={item.name}
                  checked={newGroupState?.tasks?.includes(item.name)}
                  handleChange={(e) => handleChangeGroupTasks(e)}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GroupModel;
