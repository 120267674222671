import React from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

const ReorderColumns = ({ reorderedColumnsState }) => {
  const [reorderedColumns, setReorderedColumns] = reorderedColumnsState;

  const onDrop = ({ removedIndex, addedIndex }) => {
    if (removedIndex !== null && addedIndex !== null) {
      const newItems = [...reorderedColumns];
      const [movedItem] = newItems.splice(removedIndex, 1);
      newItems.splice(addedIndex, 0, movedItem);
      setReorderedColumns(newItems);
    }
  };

  return (
    <div className='reorder-list'>
      <Container onDrop={onDrop} dragHandleSelector=".drag-handle" getChildPayload={(index) => reorderedColumns[index]}>
        {reorderedColumns.map((item) => (
          <Draggable key={item.id}>
            <div className='item drag-handle'><DragIndicatorOutlinedIcon/> {item.label}</div>
          </Draggable>
        ))}
      </Container>
    </div>
  );
};

export default ReorderColumns;
