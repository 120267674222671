import React from 'react';
import TextInput from '../../../../components/TextInput';

const AddWorkgroup = ({ newWorkgroup, setNewWorkgroup, errors }) => {

  const handleChange = (name, value) => {
    setNewWorkgroup((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // const handleAutocomplete = (event, newValue) => {
  //   setNewWorkgroup((prevValues) => ({
  //     ...prevValues,
  //     tasks: newValue,
  //     tasksList: newValue.map((v) => v.label),
  //   }));
  // };

  return (
    <div className="pd-24 pb-120">
      <TextInput
        label="Name"
        name="workload"
        value={newWorkgroup.workload}
        onInputChange={(e) => handleChange(e.target.name, e.target.value)}
        mb="15px"
        helperText={errors.workload}
      />
      {/* <CustomAutocomplete
        name="tasks"
        label="Tasks"
        value={newWorkgroup.tasks}
        options={allTasks}
        onChange={handleAutocomplete}
        width="100%"
        isMultiple={true}
        helperText={errors.tasks}
      /> */}
    </div>
  );
};

export default AddWorkgroup;
