import React, { useEffect, useState } from 'react';
import CustomTypography from '../../../../components/CustomTypography';
import EditableTable from '../../../../components/DataGrid/EditableTable';
import { countries } from '../../../../utils/constants';
import refresh from '../../../../assets/images/common/refresh.svg';
import UploadFiles from '../../../../components/UploadFiles';
import ActionModal from '../../../../components/ActionModal';
import ReorderRoundedIcon from '@mui/icons-material/ReorderRounded';
import * as XLSX from 'xlsx';
import { get } from 'lodash';
import { parse } from 'papaparse';
import { useDispatch } from 'react-redux';
import { getHierarchy } from '../../../../redux/slice/model';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../../../utils/NotificationProvider';
import characteristicsTemp from '../../../../assets/templates/company-characteristics-template.csv';
import ReorderColumns from './ReorderColumns';

const CompanyCharacteristics = ({
  formData,
  setFormData,
  modelId,
  loading,
}) => {
  const [filteredRows, setFilteredRows] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchFilterOpt, setSearchFilterOpt] = useState([]);
  const [isUploadModal, setUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [prevData, setPrevData] = useState({ rows: [], columns: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenReorderModel, setIsOpenReorderModel] = useState(false);
  const [reorderedColumns, setReorderedColumns] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showNotification = useNotification();

  const handleCloseModal = () => {
    setUploadModal(false);
    setUploadedFile(null);
    setIsOpenReorderModel(false);
  };

  useEffect(() => {
    const searchOptions = updatedColumns.map((item) => {
      let searchObj = {
        name: item.label?.toLowerCase(),
        type: item.filterType,
      };
      if (item.filterType === 'date-picker') {
        searchObj.keyName = item.id;
      } else {
        const uniqueOptionsSet = new Set(
          originalData.map((d) => (d[item.id] ? d[item.id] : ''))
        );

        const uniqueOptions = [...uniqueOptionsSet].map((value) => ({
          label: value,
          value: value,
        }));

        searchObj.options = uniqueOptions;
      }

      return searchObj;
    });
    const allSearchData = searchOptions ? searchOptions.slice(0, -1) : [];
    setSearchFilterOpt([...allSearchData]);
  }, [originalData, updatedColumns]);

  const handleOpenReorderModel = () => {
    setIsOpenReorderModel(true);
    let cols = updatedColumns.filter(
      (item) =>
        item.id !== 'col0' &&
        item.id !== 'col1' &&
        item.id !== 'newCharacteristics'
    );
    setReorderedColumns(cols);
  };

  const headerButtons = [
    {
      text: 'Reorder',
      variant: 'contained',
      color: 'success',
      width: '114px !important',
      startIcon: <ReorderRoundedIcon />,
      onClick: handleOpenReorderModel,
      isDisabled: updatedColumns.length < 5,
    },
    {
      text: 'Import Characteristics',
      variant: 'outlined',
      color: 'success',
      width: '114px !important',
      startIcon: <img src={refresh} alt="refresh" />,
      onClick: () => setUploadModal(true),
    },
  ];

  useEffect(() => {
    fetchHierarchy();
  }, []);

  const fetchHierarchy = () => {
    setIsLoading(true);
    dispatch(
      getHierarchy({
        url: `/hierarchies/get/${modelId}`,
        method: 'GET',
        navigate,
        success: (res) => {
          let updatedCols = [];
          let storeCharacteristics = [];
          if (get(res, 'data.stores') && get(res, 'data.stores').length > 0) {
            const stores = res.data.stores;
            storeCharacteristics = stores.map(
              ({ no, name, _id, characteristicData }) => ({
                id: _id,
                col0: no?.toString(),
                col1: name,
                newCharacteristics: 'e.g.1',
                inputType: 'input',
                ...characteristicData,
              })
            );
          }
          if (
            get(res, 'data.characteristicColumns') &&
            get(res, 'data.characteristicColumns').length > 0
          ) {
            updatedCols = get(res, 'data.characteristicColumns').map((item) => {
              if (item.id === 'col0' || item.id === 'col1') {
                return item;
              } else {
                return {
                  ...item,
                  showMenuIcon: true,
                };
              }
            });
          } else {
            updatedCols = columns;
          }
          setFilteredRows([...storeCharacteristics]);
          setOriginalData([...storeCharacteristics]);
          setUpdatedColumns(updatedCols);
          setPrevData({
            rows: storeCharacteristics
              ? JSON.parse(JSON.stringify(storeCharacteristics))
              : [],
            columns: updatedCols ? JSON.parse(JSON.stringify(updatedCols)) : [],
          });
          setIsLoading(false);
        },
        fail: (err) => {
          console.log('err hierarchy', err);
          setIsLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    setFormData({
      ...formData,
      companyCharacteristics: {
        stores: filteredRows,
        characteristicColumns: updatedColumns,
        prevData: prevData,
      },
    });
  }, [filteredRows, updatedColumns, prevData]);

  const updateFilteredRows = (rows) => {
    let data;
    if (rows.data) {
      data = rows.data;
      if (rows.filterFE) {
        setFilteredRows(data);
      } else {
        setOriginalData(rows.data);
      }
    } else if ('searchQuery' in rows) {
      const trimmedQuery = rows.searchQuery.trim();
      if (trimmedQuery === '') {
        data = originalData;
      } else {
        const filteredQuery = filteredRows.filter((row) =>
          Object.values(row).some(
            (value) =>
              typeof value === 'string' &&
              value.toLowerCase().includes(trimmedQuery.toLowerCase())
          )
        );
        data = filteredQuery;
      }
    } else {
      data = filteredRows;
      setOriginalData(originalData);
    }
    setFilteredRows(data);
  };

  const getUploadedFile = (file) => {
    setUploadedFile(file);
  };

  const getAllColumns = (newColumn) => {
    const lastColumnIndex = updatedColumns.length - 1;
    const updatedColumnsCopy = [
      ...updatedColumns.slice(0, lastColumnIndex),
      newColumn,
      ...updatedColumns.slice(lastColumnIndex),
    ];
    setUpdatedColumns(updatedColumnsCopy);
    const actualRows = originalData.map((item) => {
      return {
        ...item,
        [newColumn.id]: '',
      };
    });
    setOriginalData([...actualRows]);
    const filterRows = filteredRows.map((item) => {
      return {
        ...item,
        [newColumn.id]: '',
      };
    });
    setFilteredRows([...filterRows]);
  };

  const updateColumnLabel = (allColumns) => {
    setUpdatedColumns(allColumns);
  };

  const handleImportData = () => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const fileContent = e.target.result;
      const fileType = uploadedFile.name.split('.').pop().toLowerCase();

      let parsedData;
      let headers;

      if (fileType === 'csv') {
        const csvData = parse(fileContent, { header: true });
        parsedData = csvData.data;
        headers = csvData.meta.fields;
      } else {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });

        headers = worksheet[0];
        parsedData = worksheet.slice(1).map((row) =>
          row.reduce((acc, value, index) => {
            acc[headers[index]] = value;
            return acc;
          }, {})
        );
      }
      if (headers[0] !== 'no' || headers[1] !== 'name') {
        showNotification(
          'error',
          'Please ensure the imported file matches the template format.'
        );
        return;
      }
      const existingColumnIds = updatedColumns.map((column) =>
        column.label?.toLowerCase().trim()
      );
      const newHeaders = headers.filter(
        (header) => !existingColumnIds.includes(header.toLowerCase().trim())
      );
      let updatedColumnsCopy;
      if (newHeaders.length > 0) {
        const newColumnsObjects = newHeaders.map((header, index) => ({
          id: `col${updatedColumns.length - 1 + index}`,
          numeric: false,
          disablePadding: false,
          label: header.toUpperCase().replace(/_/g, ' '),
          sortable: true,
          searchFilter: true,
          filterType: 'checkbox',
          width: '100px',
          small: true,
          type: 'inputOnClick',
          showMenuIcon: true,
        }));
        const lastColumnIndex = updatedColumns.length - 1;
        updatedColumnsCopy = [
          ...updatedColumns.slice(0, lastColumnIndex),
          ...newColumnsObjects,
          ...updatedColumns.slice(lastColumnIndex),
        ];
        setUpdatedColumns(updatedColumnsCopy);
      }

      // let updatedRows = filteredRows.map((filteredRow) => {
      //   const matchedRow = parsedData.find(
      //     (parsedRow) =>
      //       Number(parsedRow.no) === Number(filteredRow.col0) &&
      //       parsedRow.name === filteredRow.col1
      //   );
      //   return matchedRow ? { ...filteredRow, ...matchedRow } : filteredRow;
      // });
      /* eslint-disable no-prototype-builtins */
      let updatedRows = parsedData.map((row, i) => {
        let newRow = { ...row, id: filteredRows[i]?.id }; 
        updatedColumnsCopy.forEach((col) => {
          let matchingKey = Object.keys(row).find(
            (key) =>
              key?.trim().toLowerCase() === col?.label?.trim().toLowerCase()
          );
          if (matchingKey) {
            newRow[col.id] = newRow[matchingKey];
            delete newRow[matchingKey]; 
          }
        });
        return newRow;
      });
      setFilteredRows(updatedRows);
      setOriginalData(updatedRows);
    };

    const fileType = uploadedFile.name.split('.').pop().toLowerCase();
    if (fileType === 'csv') {
      reader.readAsText(uploadedFile);
    } else {
      reader.readAsArrayBuffer(uploadedFile);
    }

    handleCloseModal();
  };

  const handleReorderColumns = () => {
    const lastColumnIndex = updatedColumns.length - 1;
    const updatedColumnsCopy = [
      ...updatedColumns.slice(0, 2),
      ...reorderedColumns,
      ...updatedColumns.slice(lastColumnIndex),
    ];
    setUpdatedColumns(updatedColumnsCopy);
    handleCloseModal()
  };

  const columns = [
    {
      id: 'col0',
      numeric: false,
      disablePadding: false,
      label: 'NO',
      filterType: 'checkbox',
      sortable: true,
      searchFilter: true,
      valueType: 'array',
      width: '20px',
      showMenuIcon: false,
    },
    {
      id: 'col1',
      numeric: false,
      disablePadding: false,
      label: 'NAME',
      sortable: true,
      filterType: 'checkbox',
      width: '80px',
      options: countries,
      showMenuIcon: false,
    },
    {
      id: 'newCharacteristics',
      numeric: false,
      disablePadding: false,
      placeholder: 'Type and press enter to add New characteristics',
      searchFilter: true,
      filterType: 'checkbox',
      headerType: 'input',
      width: '200px',
      type: 'inputOnClick',
    },
  ];

  const actionButtons = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseModal },
    {
      title: 'Import Data',
      variant: 'contained',
      onClick: handleImportData,
      isDisabled: uploadedFile ? false : true,
    },
  ];
  const reorderModelActionButtons = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseModal },
    {
      title: 'Save',
      variant: 'contained',
      onClick: handleReorderColumns,
    },
  ];

  const handleDeleteCol = (colId) => {
    let cols = updatedColumns.filter((item) => item.id !== colId);
    let rows = filteredRows.map(({ [colId]: _, ...rest }) => rest); // eslint-disable-line no-unused-vars
    setUpdatedColumns([...cols]);
    setFilteredRows([...rows]);
  };

  const handleRenameColClick = (colId) => {
    let cols = updatedColumns.map((item) => {
      if (item.id === colId) {
        return {
          ...item,
          headerType: 'input',
          sortable: false,
        };
      } else {
        return item;
      }
    });
    setUpdatedColumns([...cols]);
  };
  const handleRenameColumn = () => {
    let cols = updatedColumns.map(({ headerType, ...item }) => {
      if (item.id === 'newCharacteristics') {
        return {
          ...item,
          headerType,
        };
      } else {
        return {
          ...item,
          sortable: true,
          label: item.label.toUpperCase()?.trim(),
        };
      }
    });
    setUpdatedColumns([...cols]);
  };

  return (
    <div className="model_wrapper">
      <div className="step-header">
        <CustomTypography variant="headerh3" text="Characteristics" />
      </div>
      <EditableTable
        columns={updatedColumns}
        rows={filteredRows}
        data={originalData}
        tableOf={['characteristics']}
        showStatusTabs={false}
        updateFilteredRows={updateFilteredRows}
        defaultOrderBy="calories"
        defaultOrder="asc"
        headerButtons={headerButtons}
        getAllColumns={getAllColumns}
        updateColumnLabel={updateColumnLabel}
        searchFilterOpt={searchFilterOpt}
        filterFE={true}
        loading={loading || isLoading}
        handleDeleteCol={handleDeleteCol}
        handleRenameColClick={handleRenameColClick}
        handleRenameColumn={handleRenameColumn}
      />
      <ActionModal
        open={isUploadModal}
        close={handleCloseModal}
        component={
          <UploadFiles
            getUploadedFile={getUploadedFile}
            templateSrc={characteristicsTemp}
            additionalNote={[
              "1. Ensure that the 'no' and 'name' fields match those defined in the company hierarchy for accurate data import.",
              '2. Consider adding additional columns to include company characteristics.',
            ]}
          />
        }
        actionButtons={actionButtons}
        title="Upload Files"
        className="upload-model-wrapper"
      />
      <ActionModal
        open={isOpenReorderModel}
        close={handleCloseModal}
        component={
          <ReorderColumns
            reorderedColumnsState={[reorderedColumns, setReorderedColumns]}
          />
        }
        actionButtons={reorderModelActionButtons}
        title="Reorder Columns"
      />
    </div>
  );
};

export default CompanyCharacteristics;
