import React, { useEffect, useState } from 'react';
import CustomTypography from '../../../../components/CustomTypography';
import ThemeButton from '../../../../components/ThemeButton';
import AddWorkgroup from './AddWorkgroup';
import ActionModal from '../../../../components/ActionModal';
import './styles.scss';
import { capitalize } from '@mui/material';
import edit from '../../../../assets/images/common/edit.svg';
import trash from '../../../../assets/images/common/trash.svg';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import CustomDialogBox from '../../../../components/CustomDialogBox';
import { required, validateField } from '../../../../utils/Validator';
import { generateUniqueId, validateInput } from '../../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getWorkgroups } from '../../../../redux/slice/model';
import { cloneDeep, get } from 'lodash';
import typography from '../../../../theme/typography';
import Loading from '../../../../utils/loading';
import TimeStandards from '../TimeStandards';

const Workgroup = ({
  modelId,
  formData,
  setFormData,
  completedSteps,
  loading,
  validationRules,
  setValidationRules,
  modelData,
}) => {
  const [isWorkgroupModal, setIsWorkgroupModal] = useState(false);
  const [isDeleteAlert, setIsDeleteAlert] = useState(false);
  const [editWorkgroup, setEditWorkgroup] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [workgroups, setWorkgroups] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [errors, setErrors] = useState({});
  // const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newWorkgroup, setNewWorkgroup] = useState({
    workload: '',
    tasks: [],
    tasksList: [],
  });
  const isStepCompleted = get(completedSteps, 'workgroup', false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isStepCompleted && workgroups.length === 0) {
      fetchWorkgroups();
    }
  }, [modelId]);

  const modalRules = [
    required('Input is required'),
    validateInput('Input is required'),
  ];

  const duplicateWorkgroupRule =
    (message, isEditWorkgroup = false, currentWorkgroupId = null) =>
    (value) => {
      const workgroupList = workgroups
        .filter((item) => !isEditWorkgroup || item.id !== currentWorkgroupId)
        .map((r) => r.workload);
      if (
        workgroupList.some((wg) => value?.toLowerCase() === wg?.toLowerCase())
      ) {
        return message;
      }
    };

  const fetchWorkgroups = () => {
    setIsLoading(true);
    dispatch(
      getWorkgroups({
        url: `/workgroups/${modelId}`,
        method: 'GET',
        navigate,
        success: (res) => {
          if (
            get(res, 'data.workloads') &&
            get(res, 'data.workloads').length > 0
          ) {
            const rows = get(res, 'data.workloads', []).map((item) => {
              const { _id, name, tasks } = item;
              return {
                workload: name,
                id: _id,
                shrink: true,
                tasksList: tasks.map((task) => ({
                  ...task,
                  startDate: new Date(task.startDate),
                  endDate: new Date(task.endDate),
                })),
              };
            });
            setWorkgroups([...rows]);
            setPrevData(cloneDeep(rows));
          }
          setIsLoading(false);
        },
        fail: (err) => {
          console.log('workgroups error', err);
          setIsLoading(false);
        },
      })
    );
  };

  const handleCloseWorkloadModal = () => {
    setIsWorkgroupModal(false);
    setNewWorkgroup({
      workload: '',
      tasks: [],
    });
    setEditWorkgroup(false);
    setEditIndex(null);
    setErrors({});
  };

  const handleSaveWorkload = () => {
    let workloadRules = {};
    const errorMsg = 'Workgroup with same name already exists';
    if (editWorkgroup) {
      workloadRules.workload = [
        ...modalRules,
        duplicateWorkgroupRule(errorMsg, true, newWorkgroup.id),
      ];
    } else {
      workloadRules.workload = [
        ...modalRules,
        duplicateWorkgroupRule(errorMsg),
      ];
    }
    if (!validateField(newWorkgroup, workloadRules, setErrors)) return;
    if (editWorkgroup) {
      setWorkgroups((prevWorkgroups) =>
        prevWorkgroups.map((workgroup, index) =>
          index === editIndex ? newWorkgroup : workgroup
        )
      );
    } else {
      setWorkgroups((prevWorkgroups) => [
        ...prevWorkgroups,
        { ...newWorkgroup, id: generateUniqueId() },
      ]);
    }
    handleCloseWorkloadModal();
    setEditWorkgroup(false);
    setEditIndex(null);
    setErrors({});
  };

  const handleClose = () => {
    setIsDeleteAlert(false);
    setEditIndex(null);
  };

  const onDelete = () => {
    setWorkgroups((prevWorkgroups) =>
      prevWorkgroups.filter((_, index) => index !== editIndex)
    );
    handleClose();
  };

  // const handleSort = (key) => {
  //   let direction = 'asc';
  //   if (sortConfig.key === key && sortConfig.direction === 'asc') {
  //     direction = 'desc';
  //   }
  //   setSortConfig({ key, direction });
  //   setWorkgroups((prevWorkgroups) =>
  //     [...prevWorkgroups].sort((a, b) => {
  //       if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
  //       if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
  //       return 0;
  //     })
  //   );
  // };

  const workloadActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseWorkloadModal },
    {
      title: 'Save',
      variant: 'contained',
      onClick: handleSaveWorkload,
    },
  ];

  const actions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleClose },
    {
      title: 'Delete workgroup',
      variant: 'contained',
      onClick: onDelete,
      color: 'danger',
    },
  ];

  const handleUpdateWorkload = (i) => {
    setEditIndex(i);
    setEditWorkgroup(true);
    setIsWorkgroupModal(true);
    let newObj = {
      workload: workgroups[i].workload,
      tasks: workgroups[i].tasks,
      tasksList: workgroups[i].tasksList,
    };
    if (workgroups[i].id) {
      newObj.id = workgroups[i].id;
    }
    setNewWorkgroup(newObj);
  };

  const handleFold = (index) => {
    let data = workgroups;
    data[index].shrink = !data[index].shrink;
    setWorkgroups([...data]);
  }

  const handleDelete = (i) => {
    setEditIndex(i);
    setIsDeleteAlert(true);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      workgroupManagement: {
        workgroups: workgroups,
        prevData: prevData,
      },
    });
  }, [workgroups]);

  return (
    <>
      {loading || isLoading ? (
        <Loading />
      ) : (
        <>
          <div
            className="step-header flex-container"
            style={{ paddingBottom: '20px' }}
          >
            <CustomTypography variant="headerh3" text="Workgroup & SMVs" />
            <ThemeButton
              text="Add Workgroup"
              variant="outlined"
              onClick={() => setIsWorkgroupModal(true)}
            />
          </div>
          <div className="workgroup-container">
            {/* <div className="workgroup-header d-flex">
          <div 
           className='column-label' 
           style={{width: '25%'}} 
           onClick={() => handleSort('workload')} 
           onMouseOver={() => setHoveredColumn('workload')} 
           onMouseOut={() => setHoveredColumn(null)}>
            <CustomTypography text="WORKGROUP" style={{ ...typography.overline }} />
            {(hoveredColumn === 'workload' || sortConfig.key === 'workload') && (
              sortConfig.direction === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
            )}
          </div>
          <div 
           className='column-label' 
           style={{width: '75%'}} 
           onClick={() => handleSort('tasksList')} 
           onMouseOver={() => setHoveredColumn('tasksList')} 
           onMouseOut={() => setHoveredColumn(null)}>
            <CustomTypography text="TASKS" style={{ ...typography.overline }} />
            {(hoveredColumn === 'tasksList' || sortConfig.key === 'tasksList') && (
              sortConfig.direction === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
            )}
          </div>
        </div> */}
            <div className="workgroup-list-container">
              {workgroups && workgroups.length > 0 ? (
                workgroups.map((item, i) => {
                  return (
                    <div
                      className={`workgroup-list ${hoveredColumn === i ? 'active' : ''} ${item.shrink ? 'fold' : ''}`}
                      key={i}
                      onMouseOver={() => setHoveredColumn(i)}
                      onMouseOut={() => setHoveredColumn(null)}
                    >
                      <div className="title">
                        <CustomTypography
                          text={<>{item?.shrink ? <KeyboardArrowDownRoundedIcon/> : <KeyboardArrowUpRoundedIcon/>}{capitalize(item.workload)}</>}
                          style={{ width: '25%', ...typography.subtitle2 }}
                          handleCLick={()=>handleFold(i)}
                        />
                        <div className='btn-wrapper'>
                          <button
                            type="button"
                            className="icon-btn btn-secondary"
                            onClick={() => handleUpdateWorkload(i)}
                          >
                            <img src={edit} alt="edit" />
                          </button>
                          <button
                            type="button"
                            className="icon-btn btn-secondary"
                            onClick={() => handleDelete(i)}
                          >
                            <img src={trash} alt="trash" />
                          </button>
                        </div>
                      </div>
                      <TimeStandards
                        formData={formData}
                        setFormData={setFormData}
                        modelId={modelId}
                        completedSteps={completedSteps}
                        loading={loading}
                        validationRules={validationRules}
                        setValidationRules={setValidationRules}
                        modelData={modelData}
                        workgroupState={[workgroups, setWorkgroups]}
                        workgroupIndex={i}
                      />
                    </div>
                  );
                })
              ) : (
                <CustomTypography
                  text={'No groups found'}
                  style={{
                    marginTop: '4px',
                    paddingLeft: '16px',
                    ...typography.subtitle2,
                  }}
                />
              )}
            </div>
          </div>
          <ActionModal
            open={isWorkgroupModal}
            close={handleCloseWorkloadModal}
            component={
              <AddWorkgroup
                newWorkgroup={newWorkgroup}
                setNewWorkgroup={setNewWorkgroup}
                errors={errors}
              />
            }
            actionButtons={workloadActionBtns}
            title={`${editWorkgroup ? 'Edit' : 'Add new'} workgroup`}
            className="upload-model"
          />
          <CustomDialogBox
            open={isDeleteAlert}
            onClose={handleClose}
            title="Delete Workgroup"
            description="Are you sure you want to delete this workgroup?"
            actions={actions}
          />
        </>
      )}
    </>
  );
};

export default Workgroup;
