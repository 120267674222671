import { IconButton } from '@mui/material';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import PropTypes from 'prop-types';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { assign, set } from 'lodash';
import TextInput from '../../../../components/TextInput';
import CustomTypography from '../../../../components/CustomTypography';
import typography from '../../../../theme/typography';
import RowComponent from './RowComponent';
import { useNotification } from '../../../../utils/NotificationProvider';
import RoleSummary from './RoleSummary';

const GroupCard = ({
  data,
  filteredRecords,
  removeGroup,
  modelData,
  handleRemoveRow,
  workHourSummaryState
}) => {
  const showNotification = useNotification();
  const [group, setGroup] = data;
  const [workHourSummary, setWorkHourSummary] = workHourSummaryState;

  const handleNameChange = (e, i, rowindex) => {
    const groupData = group;
    if (rowindex !== undefined) {
      if (groupData[i].type === 'WORKLOAD') {
        let name = '';
        group[i].taskOptions.forEach((task) => {
          if (task.value === e.target.value) {
            name = task.label;
          }
        });
        if (
          group[i].rows.some(
            (obj) => obj.name.toLowerCase() === name.toLowerCase()
          )
        ) {
          showNotification('error', `The row with same name already exist`);
          return;
        }
        set(groupData[i], `rows[${rowindex}]`, {
          ...groupData[i].rows[rowindex],
          name: name,
          itemId: e.target.value,
        });
      } else {
        set(groupData[i], `rows[${rowindex}].name`, e.target.value);
      }
    } else {
      if (groupData[i].type === 'WORKLOAD') {
        assign(groupData[i], {
          name: e.target.name,
          itemId: e.target.value,
        });
        groupData[i].taskOptions = group[i].taskOptions.map((task) => {
          return {
            ...task,
            isDisabled: group[i].rows.some((item) => item.name === task.label)
          };
        });
      } else {
        groupData[i].name = e.target.value;
      }
    }
    setGroup([...groupData]);
  };
  const foldGroup = (i) => {
    let groupData = group;
    groupData[i].fold = !groupData[i].fold;
    setGroup([...group]);
  };

  return (
    <div className="group-card-wrapper">
      { filteredRecords?.length > 0 ?
      filteredRecords.map((item, index) => (
        <div
          className={`group_card ${item.error ? 'error' : ''} ${item?.fold ? 'fold' : ''}`}
          key={item._id}
        >
          <div className="flex-container">
            <div className="group-head-items">
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => foldGroup(index)}
              >
                {group[index]?.fold ? (
                  <AddRoundedIcon />
                ) : (
                  <RemoveRoundedIcon />
                )}
              </IconButton>
              {(item.type === 'WORKLOAD' || item?.fold) ? (
                <CustomTypography variant="headerh4" text={item.name} />
              ) : (
                <TextInput
                  placeholder="Group Name"
                  className="text-input-height field"
                  onInputChange={(e) => handleNameChange(e, index)}
                  value={item.name}
                />
              )}
              {/* {item.type !== 'WORKLOAD' && (
                <div className="group-type-field">
                  <CustomTypography
                    text="Group Type"
                    style={typography.subtitle2}
                  />
                  <SelectDropdown
                    label={'Group Type'}
                    width="20%"
                    classNameForFormControl="custom-min-width"
                    items={allTypes}
                    handleChange={(e) => handleChangeGroupType(e, index)}
                    name="group-type"
                    disabled={true}
                    value={item.type}
                  />
                </div>
              )} */}
            </div>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => removeGroup(item)}
            >
              <DeleteOutlineOutlinedIcon color="danger" />
            </IconButton>
          </div>
          <div className="group-rows-wrapper">
            {item.rows.length > 0 &&
              item.rows.map((row, rowindex) => (
                <RowComponent
                  key={row._id}
                  data={[group, setGroup]}
                  handleNameChange={handleNameChange}
                  row={row}
                  rowindex={rowindex}
                  item={item}
                  index={index}
                  modelData={modelData}
                  handleRemoveRow={handleRemoveRow}
                />
              ))}
          </div>
          {item.error && (
            <CustomTypography
              text={item.error}
              style={{
                ...typography.caption,
                color: '#d12929',
                marginTop: '2px',
              }}
            />
          )}
        </div>
      ))
      : <CustomTypography text="Click on above button to add groups" style={{...typography.subtitle3}} />
      }
      <div className={`group_card ${workHourSummary?.fold ? 'fold' : ''}`}>
        <div className="flex-container">
          <div className="group-head-items">
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => setWorkHourSummary(prev => ({...prev, fold: !prev.fold}))}
            >
              {workHourSummary.fold ? (
                <AddRoundedIcon />
              ) : (
                <RemoveRoundedIcon />
              )}
            </IconButton>
            <CustomTypography variant="headerh4" text='Work Hour Summary' />
          </div>
        </div>
        <div className="summary">
        {workHourSummary.roles.length > 0 ?
          <>
              <div className='head'>
                <CustomTypography text="Role Name" style={{...typography.subtitle2}} />
                <CustomTypography text="Hours" style={{...typography.subtitle2}} />
              </div>
              <div className='roles'>
                {workHourSummary.roles.map((role, i) => (
                  <RoleSummary
                    workHourSummaryState={[workHourSummary, setWorkHourSummary]}
                    roleIndex={i}
                    role={role}
                    key={role._id}
                  />
                ))}
              </div>
          </>
          : <div><CustomTypography text="Roles not found" style={{...typography.subtitle3}} /></div>}
        </div>
      </div>
    </div>
  );
};

GroupCard.propTypes = {
  data: PropTypes.array,
  filteredRecords: PropTypes.array,
  removeGroup: PropTypes.func,
  modelData: PropTypes.object,
  handleRemoveRow: PropTypes.func,
  workHourSummaryState: PropTypes.array,
};

export default GroupCard;
