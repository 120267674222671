import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import ThemeButton from '../ThemeButton';
import SelectDropdown from '../SelectDropdown';
import typography from '../../theme/typography';
import CustomTypography from '../CustomTypography';
import SearchFilter from '../SearchFilter';
import CustomTabs from '../CustomTabs';

const TableHeader = (props) => {
  const {
    columns,
    rows,
    updateFilteredRows,
    headerButtons,
    data,
    showStatusTabs,
    // isSearchRequired,
    titleOFthePage,
    showTableHeader,
    dropdownItems,
    selectedDropDownValue,
    onChange,
    searchFilterOpt,
    tableOf,
    filterFE,
    resetAllFilters,
    setResetAllFilters
  } = props;

  const [dataStatus, setDataStatus] = useState('2');
  const [resetFilterData, setResetFilterData] = useState(false);
  const [dataModes, setDataModes] = useState([
    { value: '1', label: 'All' },
    { value: '2', label: 'Active' },
    { value: '3', label: 'Archived' },
  ]);

  useEffect(() => {
    if (tableOf && tableOf.length > 0 && tableOf[0] === 'model') {
      setDataModes([
        { value: '1', label: 'All' },
        { value: '2', label: 'Active' },
        { value: '3', label: 'Archived' },
        { value: '4', label: 'Draft' },
      ]);
    }
  }, [tableOf]);

  useEffect(() => {
    setResetFilterData(true);
  }, [dataStatus]);

  const getStatusFilter = (status) => {
    switch (status) {
      case '1':
        if (tableOf && tableOf.length > 0 && tableOf[0] === 'model') {
          return ['active', 'archive', 'draft'];
        } else {
          return ['active', 'archive'];
        }
      case '2':
        return ['active'];
      case '3':
        return ['archive'];
      case '4':
        return ['draft'];
      default:
        if (tableOf && tableOf.length > 0 && tableOf[0] === 'model') {
          return ['active', 'archive', 'draft'];
        } else {
          return ['active', 'archive'];
        }
    }
  };

  const handleChange = (name, value) => {
    setDataStatus(value);
    updateFilteredRows({ statusFilter: getStatusFilter(value), page: 1, limit: 10 });
  };

  useEffect(()=> {
    updateFilteredRows({ statusFilter: getStatusFilter(dataStatus), page: 1, limit: 10 });
  }, [])

  return (
    <>
      {showTableHeader && (
        <div className="table-header-wrapper">
          {titleOFthePage && (
            <div>
              <CustomTypography
                style={typography.headerh3Grey}
                text={titleOFthePage}
              />
            </div>
          )}
          {(dropdownItems && tableOf?.[0] !== "time-standard") && (
            <div>
              <SelectDropdown
                items={dropdownItems}
                value={selectedDropDownValue}
                onChange={onChange}
              />
            </div>
          )}
          {
            tableOf?.[0] !== "time-standard" &&
            <div>
              <SearchFilter
                columns={columns}
                rows={rows}
                updateFilteredRows={updateFilteredRows}
                data={data}
                searchFilterOpt={searchFilterOpt}
                resetFilterData={resetFilterData}
                setResetFilterData={setResetFilterData}
                resetAllFilters={resetAllFilters}
                setResetAllFilters={setResetAllFilters}
                filterFE={filterFE}
              />
            </div>
          }
          {showStatusTabs && (
            <div>
              <CustomTabs
                tabs={dataModes}
                onChange={(name, value) => handleChange(name, value)}
                value={dataStatus}
                name="dataMode"
              />
            </div>
          )}

          <div className="headerButtons">
            {headerButtons.map((item, index) => {
              return (
                <ThemeButton
                  key={index}
                  variant={item.variant}
                  width={item.width}
                  style={{ height: '30px' }}
                  text={item.text}
                  startIcon={item.startIcon}
                  onClick={item.onClick}
                  isDisabled={item.isDisabled}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

TableHeader.propTypes = {
  headerButtons: PropTypes.array,
  data: PropTypes.array,
  columns: PropTypes.array,
  rows: PropTypes.array,
  updateFilteredRows: PropTypes.func,
  isSearchRequired: PropTypes.bool,
  titleOFthePage: PropTypes.string,
  showTableHeader: PropTypes.bool,
  dropdownItems: PropTypes.array,
  selectedDropDownValue: PropTypes.string,
  onChange: PropTypes.func,
  dataMode: PropTypes.array,
};

TableHeader.defaultProps = {
  headerButtons: [],
  columns: [],
  rows: [],
  updateFilteredRows: () => {},
  isSearchRequired: true,
};

export default TableHeader;
